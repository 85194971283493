import React, { useState, useEffect } from "react";
import { Box, Typography, Link, CardContent, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import { styled } from "@mui/system";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { updateUserAttributes, fetchUserAttributes } from "@aws-amplify/auth";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const BorderlessCard = styled(Card)({
    border: "none",
    boxShadow: "none",
});

interface Paper {
    id: number;
    publisher: string;
    year: number;
    url: string;
    favicon: string;
    title: string;
    author: string[];
    abstract: string;
}

interface SearchResultBoxProps {
    queryResults: Paper[];
    selectedPublishers: string[];
    selectedYears: string[];
    query: string;
    keyword: string;
    isLoggedIn: boolean;
}

interface AuthorTypographyProps {
    paper: Paper;
}

const AuthorTypography: React.FC<AuthorTypographyProps> = ({ paper }) => {
    const maxAuthors = 10;
    const isTruncated = paper.author.length > maxAuthors;
    const displayAuthors = isTruncated ? paper.author.slice(0, maxAuthors) : paper.author;
    const authorURL = "https://scholar.google.co.jp/citations?hl=en&view_op=search_authors&mauthors=";

    return (
        <Typography sx={{ mb: 0.5 }} color="text.secondary">
            {displayAuthors.map((author, index) => (
                <span key={index}>
                    <Link
                        href={`${authorURL}${encodeURIComponent(author.trim())}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="inherit"
                    >
                        {author}
                    </Link>
                    {index < displayAuthors.length - 1 && ", "}
                </span>
            ))}
            {isTruncated && "..."} ({paper.year})
        </Typography>
    );
};

const SearchResultBox: React.FC<SearchResultBoxProps> = ({
    queryResults,
    selectedPublishers,
    selectedYears,
    query,
    keyword,
    isLoggedIn,
}) => {
    const [favorites, setFavorites] = useState<number[]>([]);
    const [bookmarks, setBookmarks] = useState<number[]>([]);
    const [checkedPapers, setCheckedPapers] = useState<number[]>([]);

    useEffect(() => {
        fetchUserPreferences();
    }, []);
    // すべてのユーザー設定を取得する関数
    const fetchUserPreferences = async () => {
        try {
            const attributes = await fetchUserAttributes();
            const favoritePapers = attributes["custom:favorite_papers"];
            const bookmarkedPapers = attributes["custom:bookmark_papers"];
            const checkedPapers = attributes["custom:checked_papers"];

            setFavorites(favoritePapers ? favoritePapers.split(",").map(Number) : []);
            setBookmarks(bookmarkedPapers ? bookmarkedPapers.split(",").map(Number) : []);
            setCheckedPapers(checkedPapers ? checkedPapers.split(",").map(Number) : []);
        } catch (error) {
            console.error("Error fetching user preferences:", error);
        }
    };

    // 汎用的な状態更新関数
    const togglePaperState = async (
        paperID: number,
        currentState: number[],
        setState: React.Dispatch<React.SetStateAction<number[]>>,
        attributeName: string
    ) => {
        const updatedState = currentState.includes(paperID)
            ? currentState.filter((id) => id !== paperID)
            : [...currentState, paperID];

        setState(updatedState);

        try {
            const userAttributes = {
                [attributeName]: updatedState.join(","),
            };
            await updateUserAttributes({ userAttributes });
        } catch (error) {
            console.error(`Error updating ${attributeName}:`, error);
            setState(currentState);
        }
    };

    // 各アクションのトグル関数
    const toggleFavorite = (paperID: number) =>
        togglePaperState(paperID, favorites, setFavorites, "custom:favorite_papers");

    const toggleBookmark = (paperID: number) =>
        togglePaperState(paperID, bookmarks, setBookmarks, "custom:bookmark_papers");

    const toggleChecked = (paperID: number) =>
        togglePaperState(paperID, checkedPapers, setCheckedPapers, "custom:checked_papers");

    return (
        <Box>
            <div>
                {queryResults.filter(
                    (paper) =>
                        selectedPublishers.includes(paper.publisher) &&
                        selectedYears.includes(String(Math.floor(paper.year / 10) * 10))
                ).length === 0 ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "50vh",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                            }}
                        >
                            <Typography variant="h6" color="text.secondary" style={{ marginBottom: "30px" }}>
                                Your search - {query} and {keyword} - did not match any papers.
                            </Typography>
                        </div>
                    </div>
                ) : null}
            </div>
            {queryResults
                .filter(
                    (paper) =>
                        selectedPublishers.includes(paper.publisher) &&
                        selectedYears.includes(String(Math.floor(paper.year / 10) * 10))
                )
                .map((paper, index) => (
                    <BorderlessCard sx={{ minWidth: 275 }} key={index}>
                        <CardContent>
                            <Link href={paper.url} underline="none" target="_blank" rel="noopener noreferrer">
                                <Typography
                                    sx={{ fontSize: 14, display: "flex", alignItems: "center" }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    <img
                                        src={paper.favicon}
                                        alt="favicon"
                                        style={{ height: "20px", marginRight: "5px" }}
                                    />
                                    {paper.publisher}
                                </Typography>
                            </Link>
                            <Link href={paper.url} underline="hover" target="_blank" rel="noopener noreferrer">
                                <Typography variant="h5">{paper.title}</Typography>
                            </Link>
                            <AuthorTypography paper={paper} />
                            <Typography variant="body2">
                                {paper.abstract.length > 200
                                    ? `${paper.abstract.substring(0, 250)}...`
                                    : paper.abstract}
                            </Typography>
                            {isLoggedIn && (
                                <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                                    <IconButton onClick={() => toggleFavorite(paper.id)}>
                                        {favorites.includes(paper.id) ? (
                                            <StarIcon color="warning" />
                                        ) : (
                                            <StarBorderIcon />
                                        )}
                                    </IconButton>
                                    <IconButton onClick={() => toggleBookmark(paper.id)}>
                                        {bookmarks.includes(paper.id) ? (
                                            <BookmarkIcon color="primary" />
                                        ) : (
                                            <BookmarkBorderIcon />
                                        )}
                                    </IconButton>
                                    <IconButton onClick={() => toggleChecked(paper.id)}>
                                        {checkedPapers.includes(paper.id) ? (
                                            <CheckCircleIcon color="success" />
                                        ) : (
                                            <CheckCircleOutlineIcon />
                                        )}
                                    </IconButton>
                                </Box>
                            )}
                        </CardContent>
                    </BorderlessCard>
                ))}
        </Box>
    );
};

export default SearchResultBox;
