import regularPaperManager from "../usecase/regularPaperManager";
import regularPaper from "../../dataclass/regularPaper";

async function SearchResultPresenter(
    query: string,
    limit: number | null = null,
    offset: number | null = null
): Promise<regularPaper[]> {
    const manager = new regularPaperManager();
    return await manager.getSearchResult(query, limit, offset);
}

export default SearchResultPresenter;
