import { useState, useEffect } from "react";
import regularPaper from "../../../dataclass/regularPaper";

const useSortResults = (originalResults: regularPaper[]) => {
    const [sortOrder, setSortOrder] = useState<"popularity" | "asc" | "desc" | "default">("default");

    // ソートを行う関数
    const sortResults = (order: "popularity" | "asc" | "desc" | "default") => {
        let sortedResults;
        switch (order) {
            case "asc":
                sortedResults = [...originalResults].sort((a, b) => a.year - b.year);
                break;
            case "desc":
                sortedResults = [...originalResults].sort((a, b) => b.year - a.year);
                break;
            case "popularity":
                sortedResults = [...originalResults].sort((a, b) => b.cited - a.cited);
                break;
            default:
                sortedResults = [...originalResults]; // 今のところ、元の順序を保持
                break;
        }
        return sortedResults;
    };

    // useEffect内でsortResultsを呼び出す
    useEffect(() => {
        sortResults(sortOrder);
        // eslint-disable-next-line
    }, [sortOrder]);

    return { sortOrder, setSortOrder, sortResults };
};

export default useSortResults;
