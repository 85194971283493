import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

interface JournalDetailsBoxProps {
    Journal: any; // 適切な型に置き換えてください
    JournalData: any; // 適切な型に置き換えてください
}

const JournalProfile: React.FC<JournalDetailsBoxProps> = ({ Journal, JournalData }) => {
    return (
        <Box sx={{ display: "block" }}>
            <Box style={{ margin: "15px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <a href={Journal.official_site} target="_blank" rel="noopener noreferrer">
                    <img src={Journal.image} alt={Journal.name} style={{ height: "150px", width: "auto" }} />
                </a>
            </Box>
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography level="title-lg">{Journal.name}</Typography>
            </Box>
            {JournalData && (
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography level="body-sm" sx={{ maxWidth: "48ch" }}>
                        {JournalData.journal.desc}
                    </Typography>
                </Box>
            )}
            {JournalData && (
                <Box>
                    <Sheet
                        sx={{
                            bgcolor: "background.level1",
                            borderRadius: "sm",
                            p: 1.5,
                            my: 1.5,
                            display: "flex",
                            gap: 2,
                            "& > div": { flex: 1 },
                            marginTop: "30px",
                        }}
                    >
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Impact Factor
                            </Typography>
                            <Typography fontWeight="lg">{Journal.impact_factor}</Typography>
                        </div>
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Citescore
                            </Typography>
                            <Typography fontWeight="lg">{Journal.Citescore}</Typography>
                        </div>
                    </Sheet>
                </Box>
            )}
        </Box>
    );
};

export default JournalProfile;
