import MonthlyJournalReportClass from "../../dataclass/MonthlyJournalReportClass";

export async function fetchMonthlyJournalReportData(journal_id: Number): Promise<MonthlyJournalReportClass[]> {
    // let response = [
    //     {
    //         id: 1,
    //         title: "レポート1",
    //         description:
    //             "The summaries provided cover a wide range of critical topics in fusion research, showcasing the ongoing efforts to address some of the key challenges in realizing fusion energy.The successful reduction of divertor heat flux while maintaining ELM suppression using RMPs is a significant development, as it addresses a crucial issue in fusion reactor design. This work demonstrates practical solutions to managing the extreme heat loads on divertor components, which is essential for the viability of future fusion reactors.The investigation of turbulence spreading and the non-diffusive nature of transport processes in edge plasmas is also highly relevant. Understanding the fundamental mechanisms behind these phenomena is crucial for improving plasma confinement and stability, which are essential for achieving high-performance fusion.The studies on fast-ion confinement, impurity transport, and runaway electron mitigation further highlight the complexity of plasma physics in fusion devices. The insights gained from these investigations will help optimize the design and operation of fusion reactors, ensuring safe and efficient performance.The development of high-power particle accelerators, such as the IFMIF injector, is also a crucial aspect of fusion research, as it supports the advancement of fusion materials testing and the overall progress towards fusion energy.Overall, the summaries showcase the multifaceted nature of fusion research, where scientists and engineers are tackling a wide range of challenges to pave the way for the realization of fusion energy as a viable and sustainable energy source.",
    //         publish_year: 2024,
    //         publish_month: 7,
    //         journal_id: 1,
    //         language: "EN",
    //         total_papers: 2,
    //         url: "https://iopscience.iop.org/issue/0029-5515/64/6",
    //     },
    //     {
    //         id: 2,
    //         title: "核融合研究の最新動向 - 多彩な実験成果と課題",
    //         description:
    //             "これらの要約は、核融合研究の最新の進展と課題を幅広く網羅しています。ELMの抑制による壁への熱負荷低減、乱流伝播メカニズムの解明、高速イオンの閉じ込め特性の評価、不純物輸送の分析など、プラズマ物理の基礎から応用まで、多岐にわたる重要な研究成果が報告されています。一方で、ITER運転時のELM制御、高βプラズマの安定化、波粒子相互作用の影響、崩壊予測など、核融合炉実現に向けた課題も明らかになっています。これらの研究は、核融合エネルギーの実用化に向けた重要な一歩となるものと期待されます。",
    //         publish_year: 2024,
    //         publish_month: 8,
    //         journal_id: 2,
    //         language: "JA",
    //         total_papers: 44,
    //         url: "https://iopscience.iop.org/issue/0029-5515/64/6",
    //     },
    // ];

    const fetchResults = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/get_monthly_journal_report?journal_id=${journal_id}`,
            {
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    origin: window.location.origin,
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        const sanitizedText = text.replace(/NaN/g, "null");
        const data = JSON.parse(sanitizedText);
        return data;
    };

    const response = await fetchResults().catch((e) => {
        console.error("Error:", e);
        return [];
    });

    return response.map((item: MonthlyJournalReportClass) => {
        return new MonthlyJournalReportClass(
            item.id,
            item.title,
            item.description,
            item.publish_year,
            item.publish_month,
            item.journal_id,
            item.language,
            item.total_papers,
            item.url
        );
    });
}

export default fetchMonthlyJournalReportData;
