import MyDevice from "../gateways/MyDevice";
import FetchDeviceData from "../gateways/FetchDeviceData";
import DeviceClass from "../../dataclass/DeviceClass";
import processDeviceData from "../entity/processDeviceData";

class DeviceManeger {
    getDevice(): DeviceClass[] {
        // DBに保存されている装置情報を返します
        return MyDevice();
    }

    async getDeviceDetailsInfo(fileName: string) {
        // 装置詳細画面に表示する情報を返します
        const device_data = await FetchDeviceData(fileName);
        const evolution_of_document_count_data = processDeviceData(device_data);

        return {
            device_data: device_data,
            evolution_of_document_count_data: evolution_of_document_count_data,
        };
    }
}

export default DeviceManeger;
