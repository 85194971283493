/**
 * 指定されたジャーナル名を対応する略称に変換します。
 * ジャーナル名がマッピングに存在する場合はその略称を返し、存在しない場合はジャーナル名自体をそのまま返します。
 *
 * @param {string} fileName - 変換するジャーナル名
 * @return {string} 略称または元のジャーナル名
 */
function mapJournalNameToAbbreviation(fileName: string): string {
    const nameMapping: { [key: string]: string } = {
        "Nuclear Fusion": "NF",
        "Fusion Engineering and Design": "FED",
        "Plasma Physics and Controlled Fusion": "PPCF",
        "PHYSICAL REVIEW LETTERS": "PRL",
        "Review of Scientific Instruments": "RSI",
        "Physics of Plasmas": "POP",
    };

    return nameMapping[fileName] || fileName;
}

export default mapJournalNameToAbbreviation;
