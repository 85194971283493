import React from "react";
import HeaderBar from "../organisms/HeaderBar";
import JournalDetailsBOX from "../organisms/JournalDetailsBOX";

const JournalDetailsTemplate: React.FC = () => {
    return (
        <div>
            <div>
                <HeaderBar />
            </div>
            <div>
                <JournalDetailsBOX />
            </div>
        </div>
    );
};

export default JournalDetailsTemplate;
