import React from "react";
import ProfileBox from "../organisms/ProfileBox";
import HeaderBar from "../organisms/HeaderBar";
import Footer from "../organisms/Footer";
import { MainPageProps } from "../organisms/types";

const MySettingsTemplate: React.FC<MainPageProps> = ({ signOut, user }) => {
    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <HeaderBar />
            <main className="flex-grow container mx-auto px-4 py-8 flex items-center justify-center">
                <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-2xl">
                    <ProfileBox user={user} signOut={signOut} />
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default MySettingsTemplate;
