import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import { ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import { Chart as ChartJS, BarElement, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { CategoryScale, LinearScale, PointElement, LineElement, Filler } from "chart.js";

// 検索結果ページだけ動的に読み込むとおかしくなる？
import SearchPage from "./frontend/pages/SearchResultPage";
import AnalyzeResultPage from "./frontend/pages/AnalyzeResultPage";
import MyDashborad from "./frontend/pages/MyDashboradPage";
import LLMSummarizedPaperPage from "./frontend/pages/LLMSummarizedPaperPage";
import DeviceDetailsPage from "./frontend/pages/DeviceDetailsPage";
import JournalDetailsPage from "./frontend/pages/JournalDetailsPage";
import MySettingsPage from "./frontend/pages/MySettingsPage";

const MainPage = lazy(() => import("./frontend/pages/MainPage"));
const LPPage = lazy(() => import("./frontend/pages/LPPage"));
const SelectedJournalsPage = lazy(() => import("./frontend/pages/SelectedJournalsPage"));
const PrimaryDevicesPage = lazy(() => import("./frontend/pages/PrimaryDevicesPage"));
const TechnicalTermPage = lazy(() => import("./frontend/pages/TechnicalTermPage"));
const TexEditorPage = lazy(() => import("./frontend/pages/TexEditorPage"));
const LegalPage = lazy(() => import("./frontend/pages/LegalPage"));
const TestPage = lazy(() => import("./frontend/pages/TestPage"));
const TechnicalTermDetailPage = lazy(() => import("./frontend/pages/TechnicalTermDetailPage"));

ChartJS.register(
    ArcElement,
    BarElement,
    Tooltip,
    Title,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler
);

Amplify.configure(awsconfig);

ReactGA.initialize("G-TRRCTS1TGW");

function usePageViews() {
    let location = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname + location.search,
        });
    }, [location.pathname, location.search]);
}

function PageViewTracker() {
    usePageViews();
    return null;
}

function App() {
    return (
        <ThemeProvider>
            <Router>
                <PageViewTracker />
                <Suspense fallback={<div>Loading...</div>}></Suspense>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/about" element={<LPPage />} />
                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/analyze" element={<AnalyzeResultPage />} />
                    <Route path="/selected-journals" element={<SelectedJournalsPage />} />
                    <Route path="/primary-devices" element={<PrimaryDevicesPage />} />
                    <Route path="/glossary" element={<TechnicalTermPage />} />
                    <Route path="/glossary/:name" element={<TechnicalTermDetailPage />} />
                    <Route path="/tex-equation" element={<TexEditorPage />} />
                    <Route path="/device/:id" element={<DeviceDetailsPage />} />
                    <Route path="/journal/:id" element={<JournalDetailsPage />} />
                    <Route path="/journal_ai_summary/:id" element={<LLMSummarizedPaperPage />} />
                    <Route path="/mydashboard" element={<MyDashborad />} />
                    <Route path="/my_settings" element={<MySettingsPage />} />
                    <Route path="/legal" element={<LegalPage />} />
                    <Route path="/test_page" element={<TestPage />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

reportWebVitals();
