import React from "react";
import Typography from "@mui/material/Typography";

// `NoResults`コンポーネントは、ユーザーの検索クエリが何も一致しなかった場合に表示されます。
// `query`はユーザーが入力した検索クエリを表します。
interface NoResultsProps {
    query: string;
}

const NoResults: React.FC<NoResultsProps> = ({ query }) => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
        }}
    >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography variant="h6" color="text.secondary" style={{ marginBottom: "30px" }}>
                Your search - {query} - did not match any papers.
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography variant="body1" color="text.secondary">
                    Suggestions:
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1" color="text.secondary">
                            - Make sure all words are spelled correctly.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" color="text.secondary">
                            - Try different keywords.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" color="text.secondary">
                            - Try more general keywords.
                        </Typography>
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

export default NoResults;
