import FetchMonthlyJournalReportData from "../gateways/FetchMonthlyJournalReportData";
import MonthlyJournalReportClass from "../../dataclass/MonthlyJournalReportClass";

class MonthlyJournalReportManeger {
    async getMonthlyJournalReportInfo(journalId: Number): Promise<MonthlyJournalReportClass[]> {
        // 装置詳細画面に表示する情報を返します
        const monthly_journal_report = await FetchMonthlyJournalReportData(journalId);
        return monthly_journal_report;
    }
}

export default MonthlyJournalReportManeger;
