import dashboardManger from "../usecase/dashboardManger";
import regularPaper from "../../dataclass/regularPaper";

/**
 * ダッシュボードの通常の論文を表示するためのプレゼンター関数。
 * @returns {Promise<TechnicalTermClass[]>} 検索結果のTechnicalTermClassオブジェクトの配列を含むPromise。
 */
function dashBoardRegularPapersPresenter(): Promise<regularPaper[]> {
    const manager = new dashboardManger();
    return manager.getRegularPapersWithTrackedKeywords();
}

export default dashBoardRegularPapersPresenter;
