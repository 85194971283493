import MySerchResult from "../gateways/MySerchResult";
import regularPaper from "../../dataclass/regularPaper";

class regularPaperManager {
    async getSearchResult(
        query: string,
        limit: number | null = null,
        offset: number | null = null
    ): Promise<regularPaper[]> {
        return await MySerchResult(query, limit, offset);
    }
}

export default regularPaperManager;
