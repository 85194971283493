import JournalDetailsTemplate from "../templates/JournalDetailsTemplate";
import React from "react";

const JournalDetailsPage: React.FC = () => {
    return (
        <>
            <JournalDetailsTemplate />
        </>
    );
};

export default JournalDetailsPage;
