import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import DevicePresenter from "../../backend/presenters/DevicePresenter";
import DeviceDetailsBOXPresenter from "../../backend/presenters/DeviceDetailsBOXPresenter";
import DevicePaperRatioDoughnut from "../molecules/DevicePaperRatioDoughnut";
import DeviceProfile from "../molecules/DeviceProfile";
import DeviceKeywords from "../molecules/DeviceKeywords";
import DeviceRecentPapers from "../molecules/DeviceRecentPapers";
import DevicePaperCountEvolution from "../molecules/DevicePaperCountEvolution";

interface DeviceClass {
    name: string;
    desc: string;
    image: string;
    official_site: string;
}

interface Paper {
    title: string;
    author: string;
    abstract: string;
    publisher: string;
    url: string;
    favicon: string;
    year: number;
    cited: number;
    distance: number;
    order: number;
}

interface DeviceData {
    device: DeviceClass;
    recent_paper: Paper[];
    popular_paper: Paper[];
    recent_topics: string[];
    common_topics: string[];
    paper_counts_on_all_journal_all_year: Record<string, number>;
    paper_counts_on_all_journal_per_year: Record<string, Record<string, number>>;
}

const DeviceDetailsBOX: React.FC = () => {
    // useNavigateは画面遷移を行うためのReact Routerのフック
    const navigate = useNavigate();

    // useThemeは現在のテーマを取得するためのMaterial-UIのフック
    const theme = useTheme();

    // useMediaQueryはメディアクエリを評価するためのMaterial-UIのフック
    // ここでは、画面幅が'md'以下であるかどうかを判断しています
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    // 変数定義
    const [DeviceData, setDeviceData] = useState<DeviceData | null>(null);
    const [EvolutionOfDocumentCountData, setEvolutionOfDocumentCountData] = useState<any | null>({
        labels: [],
        datasets: [],
    });

    // idがundefinedの場合、deviceIdを0に設定
    const { id } = useParams<{ id: string }>();
    const deviceId = id ? parseInt(id, 10) : 0;
    const devices = new DevicePresenter().getDevices();
    const Device = devices.find((device) => device.id === deviceId);

    useEffect(() => {
        const fetchData = async () => {
            if (!Device) {
                navigate("/");
            } else {
                let fileName = Device.name;
                const deviceDetails = await DeviceDetailsBOXPresenter(fileName);
                setDeviceData(deviceDetails.device_data);
                setEvolutionOfDocumentCountData(deviceDetails.evolution_of_document_count_data);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [Device?.name]);

    return (
        <>
            <Container maxWidth="lg">
                <Box
                    sx={{
                        margin: "auto",
                        overflow: "auto",
                        backgroundColor: "white",
                        position: "relative",
                        borderRadius: "10px",
                        display: "flex", // Flexboxを使用
                        flexDirection: isMobile ? "column" : "row", // スマホサイズでは縦方向に要素を配置
                    }}
                >
                    {/* ページ左 */}
                    <Box sx={{ flex: 1 }}>
                        <Card style={{ margin: "3px" }}>
                            <Box sx={{ margin: "30px" }}>
                                <Box>
                                    <DeviceProfile Device={Device} DeviceData={DeviceData} />
                                </Box>
                                <Box>
                                    <DeviceKeywords DeviceData={DeviceData} />
                                </Box>
                                <Box>
                                    <DeviceRecentPapers DeviceData={DeviceData} />
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                    {/* ページ右 */}
                    <Box sx={{ flex: 1 }}>
                        <Card style={{ margin: "3px" }}>
                            <Box sx={{ margin: "30px" }}>
                                <DevicePaperRatioDoughnut DeviceData={DeviceData} />
                            </Box>
                        </Card>
                        <Card style={{ margin: "3px" }}>
                            <Box sx={{ margin: "30px" }}>
                                <DevicePaperCountEvolution
                                    evolution_of_document_count_data={EvolutionOfDocumentCountData}
                                />
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default DeviceDetailsBOX;
