import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Box } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/joy/Typography";

interface PaperRatioDoughnutProps {
  JournalData: any;
}

const JournalPaperRatioDoughnut: React.FC<PaperRatioDoughnutProps> = ({
  JournalData,
}) => {
  // 有名な色のリスト
  const colors = [
    "rgb(54, 162, 235)", // Blue
    "rgb(255, 205, 86)", // Yellow
    "rgb(75, 192, 192)", // Green
    "rgb(153, 102, 255)", // Purple
    "rgb(255, 159, 64)", // Orange
    "rgb(255, 99, 255)", // Pink
    "rgb(255, 255, 99)", // Light Yellow
    "rgb(99, 255, 132)", // Light Green
    "rgb(132, 99, 255)", // Light Purple
    "rgb(255, 0, 0)", // Red
    "rgb(0, 255, 0)", // Lime
    "rgb(0, 0, 255)", // Dark Blue
    "rgb(0, 255, 255)", // Cyan
    "rgb(255, 0, 255)", // Magenta
    "rgb(192, 192, 192)", // Silver
    "rgb(128, 128, 128)", // Gray
    "rgb(128, 0, 0)", // Maroon
    "rgb(128, 128, 0)", // Olive
    "rgb(0, 128, 0)", // Dark Green
  ];

  const ratioText = {
    id: "ratio-text",
    beforeDraw(chart: any) {
      const {
        ctx,
        chartArea: { top, width, height },
      } = chart;
      ctx.save();
      //チャート描画部分の中央を指定
      ctx.fillRect(width / 2, top + height / 2, 0, 0);
      //フォントのスタイル指定
      ctx.font = "bold 20px Roboto";
      ctx.fillStyle = "#333333";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      //80%という文字列をドーナツチャートの中央部に描画します
      ctx.fillText("", width / 2, top + height / 2);
    },
  };

  // チャート描画のオプション
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top" as const,
        reverse: true,
        labels: {
          font: {
            size: 10,
          },
        },
      },
    },
  };

  return (
    <Box>
      <Typography
        level="title-lg"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 0,
          padding: 0,
        }}
      >
        Paper Ratio
      </Typography>
      <CardContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 0,
          padding: 0,
        }}
      >
        <div style={{ height: "250px", width: "100%" }}>
          {JournalData && JournalData.paper_counts_by_device_all_year && (
            <Doughnut
              data={{
                labels: Object.keys(
                  JournalData.paper_counts_by_device_all_year
                ),
                datasets: [
                  {
                    data: Object.entries(
                      JournalData.paper_counts_by_device_all_year
                    ).map((entry) => entry[1]),
                    backgroundColor: colors,
                  },
                ],
              }}
              options={options}
              plugins={[ratioText]}
            />
          )}
        </div>
      </CardContent>
    </Box>
  );
};

export default JournalPaperRatioDoughnut;
