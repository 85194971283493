class LLMSummarizedPaperClass {
    id: number;
    title: string;
    summary: string;
    target: string;
    hashtag: string;
    language: string;
    journal_monthly_report_id: number;
    paper_url: string;

    constructor(
        id: number,
        title: string,
        summary: string,
        target: string,
        hashtag: string,
        language: string,
        journal_monthly_report_id: number,
        paper_url: string
    ) {
        this.id = id;
        this.title = title;
        this.summary = summary;
        this.target = target;
        this.hashtag = hashtag;
        this.language = language;
        this.journal_monthly_report_id = journal_monthly_report_id;
        this.paper_url = paper_url;
    }
}

export default LLMSummarizedPaperClass;
