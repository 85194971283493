import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Search, Edit } from "lucide-react";
import { Link } from "react-router-dom";

const EmptyPapersMessage = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: 4,
                backgroundColor: "background.paper",
                borderRadius: 2,
                boxShadow: 3,
                textAlign: "center",
            }}
        >
            <Search size={48} color="#9e9e9e" />
            <Typography variant="h6" sx={{ mt: 2, mb: 1, color: "text.primary" }}>
                No papers registered under Tracked keywords
            </Typography>
            <Typography variant="body1" sx={{ mb: 3, color: "text.secondary" }}>
                To find new papers, try editing your Tracked keywords.
            </Typography>
            <Button
                component={Link}
                to="/my_settings"
                variant="contained"
                startIcon={<Edit />}
                sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                        backgroundColor: "primary.dark",
                    },
                }}
            >
                Edit Tracked keywords
            </Button>
        </Box>
    );
};

export default EmptyPapersMessage;
