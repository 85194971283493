import SearchResultTemplate from "../templates/SearchResultTemplate";
import React from "react";

const SearchPage: React.FC = () => {
    return (
        <>
            <SearchResultTemplate />
        </>
    );
};

export default SearchPage;
