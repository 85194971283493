export class CognitoUser {
    sub: string;
    name: string;
    bio: string;
    occupation: string;
    trackedKeywords: string[];
    favoritePapers: string[];
    bookmarkPapers: string[];
    checkedPapers: string[];

    constructor(attributes: Record<string, any>) {
        this.sub = attributes.sub || "";
        this.name = attributes.name || "";
        this.bio = attributes.bio || "";
        this.occupation = attributes.occupation || "";
        this.trackedKeywords = attributes.trackedKeywords || [];
        this.favoritePapers = attributes.favoritePapers || [];
        this.bookmarkPapers = attributes.bookmarkPapers || [];
        this.checkedPapers = attributes.checkedPapers || [];
    }
}
