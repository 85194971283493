import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/joy/Typography";

interface DeviceDetailsBoxProps {
    Device: any;
    DeviceData: any;
}

const DeviceProfile: React.FC<DeviceDetailsBoxProps> = ({ Device, DeviceData }) => {
    return (
        <Box sx={{ display: "block" }}>
            <Box style={{ margin: "15px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <a href={Device.official_site} target="_blank" rel="noopener noreferrer">
                    <img src={Device.image} alt={Device.name} style={{ height: "150px", width: "auto" }} />
                </a>
            </Box>
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography level="title-lg">{Device.name}</Typography>
            </Box>
            {DeviceData && (
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography level="body-sm" sx={{ maxWidth: "48ch" }}>
                        {DeviceData.device.desc}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default DeviceProfile;
