// MainPageTemplate.tsx
import React from "react";
import AnalyzeResultsBox from "../organisms/AnalyzeResultBox";
import SearchResultsHeader from "../organisms/SearchResultHeader";

const MainPage: React.FC = () => {
    return (
        <>
            <div className="w-full">
                <SearchResultsHeader />
            </div>
            <div>
                <AnalyzeResultsBox />
            </div>
        </>
    );
};

export default MainPage;
