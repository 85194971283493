import LLMSummarizedPaperClass from "../../dataclass/LLMSummarizedPaperClass";

export async function fetchLLMSummarizedPaperData(journal_id: Number): Promise<LLMSummarizedPaperClass[]> {
    // let response = [
    //     {
    //         id: 1,
    //         title: "title1",
    //         summary:
    //             "この論文は、共鳴磁気摂動(RMP)によって引き起こされる ELM(エッジ局在モード)の抑制が、プラズマの壁への熱負荷を大幅に低減できることを示しています。RMPの適用は、ダイバータなどの壁への熱負荷パターンを大きく変化させますが、部分的に電離した不純物やデューテリウムによる輻射冷却によって、壁への熱負荷をさらに低減できることを明らかにしています。",
    //         target: "この論文は、核融合プラズマの性能向上と壁への熱負荷低減に興味のある研究者や学生に有益です。特に、RMPによるELM抑制と輻射冷却の組み合わせが、ITER級の核融合炉に有望な解決策を提示しています。",
    //         hashtag: "#RMP #ELM_suppression #radiative_cooling #divertor_heat_load #fusion_plasma_performance",
    //         language: "JA",
    //         paper_id: 1,
    //         journal_monthly_report_id: 1,
    //         paper_url: "https://iopscience.iop.org/article/10.1088/0029-5515/64/6/064001",
    //     },
    //     {
    //         id: 2,
    //         title: "title2",
    //         summary: "要約2afdsadf",
    //         target: "ターゲット2",
    //         hashtag: "#tag2",
    //         language: "EN",
    //         paper_id: 2,
    //         journal_monthly_report_id: 1,
    //         paper_url: "https://iopscience.iop.org/article/10.1088/0029-5515/64/6/064001",
    //     },
    //     {
    //         id: 3,
    //         title: "title3",
    //         summary: "要約3",
    //         target: "ターゲット3",
    //         hashtag: "#tag3",
    //         language: "JA",
    //         paper_id: 3,
    //         journal_monthly_report_id: 2,
    //         paper_url: "https://iopscience.iop.org/article/10.1088/0029-5515/64/6/064001",
    //     },
    //     {
    //         id: 4,
    //         title: "title4",
    //         summary: "要約4",
    //         target: "ターゲット4",
    //         hashtag: "#tag4",
    //         language: "JA",
    //         paper_id: 4,
    //         journal_monthly_report_id: 2,
    //         paper_url: "https://iopscience.iop.org/article/10.1088/0029-5515/64/6/064001",
    //     },
    // ];

    const fetchResults = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/get_llm_summarized_paper?journal_id=${journal_id}`,
            {
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    origin: window.location.origin,
                },
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        const sanitizedText = text.replace(/NaN/g, "null");
        const data = JSON.parse(sanitizedText);
        return data;
    };

    const response = await fetchResults().catch((e) => {
        console.error("Error:", e);
        return [];
    });

    return response.map((item: LLMSummarizedPaperClass) => {
        return new LLMSummarizedPaperClass(
            item.id,
            item.title,
            item.summary,
            item.target,
            item.hashtag,
            item.language,
            item.journal_monthly_report_id,
            item.paper_url
        );
    });
}

export default fetchLLMSummarizedPaperData;
