class JournalClass {
    id: number;
    name: string;
    desc: string;
    image: string;
    impact_factor: number;
    Citescore: number;
    official_site: string;

    constructor(
        id: number,
        name: string,
        desc: string,
        image: string,
        impact_factor: number,
        Citescore: number,
        official_site: string
    ) {
        this.id = id;
        this.name = name;
        this.desc = desc;
        this.image = image;
        this.impact_factor = impact_factor;
        this.Citescore = Citescore;
        this.official_site = official_site;
    }
}

export default JournalClass;
