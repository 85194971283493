import React, { useState, useEffect } from "react";
import { CardContent } from "@mui/material";
import { withAuthenticator } from "@aws-amplify/ui-react";
import {
    Container,
    Typography,
    TextField,
    Box,
    IconButton,
    Fade,
    useTheme,
    CircularProgress,
    Checkbox,
    InputAdornment,
} from "@mui/material";
import { Edit as EditIcon, Search as SearchIcon } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { useProfile } from "./useProfile";
import { MainPageProps } from "./types";
import { KEYWORDS } from "./keywords";
import { FixedSizeList } from "react-window";
import { Chip, Paper, ListItemText, ListItem, ListItemIcon } from "@mui/material";
import { LocalOffer as TagIcon } from "@mui/icons-material";
import { updateUserAttributes } from "@aws-amplify/auth";

export const ASPECT_RATIO = 1 / 1;
export const CROP_WIDTH = 400;

const MyDashboard: React.FC<MainPageProps> = ({ signOut, user }) => {
    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const {
        subid,
        bio,
        newBio,
        isLoading,
        isEditing,
        imageUrl,
        setNewBio,
        setIsEditing,
        updateBio,
        trackedKeywords,
        setTrackedKeywords,
        occupation,
        setOccupation,
        newOccupation,
        setNewOccupation,
        lang,
        setLang,
    } = useProfile(user);

    const [checkedKeywords, setCheckedKeywords] = useState<string[]>([]);
    const [keywordFilter, setKeywordFilter] = useState("");
    const [isKeywordEditing, setIsKeywordEditing] = useState(false);

    useEffect(() => {
        if (trackedKeywords) {
            setCheckedKeywords(trackedKeywords.split(",").map((keyword) => keyword.trim()));
        }
    }, [trackedKeywords]);

    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const keyword = event.target.name;
        setCheckedKeywords((prev) => (event.target.checked ? [...prev, keyword] : prev.filter((k) => k !== keyword)));
    };

    const handleKeywordToggle = (keyword: string) => {
        const event = {
            target: {
                name: keyword,
                checked: !checkedKeywords.includes(keyword),
            },
        } as React.ChangeEvent<HTMLInputElement>;

        handleKeywordChange(event);
    };

    const saveProfile = async () => {
        const userAttributes = {
            "custom:occupation": newOccupation || undefined,
        };
        await updateUserAttributes({ userAttributes });
        setOccupation(newOccupation);
        await updateBio();
    };

    const saveKeyword = async () => {
        const userAttributes = {
            "custom:trackedKeywords": checkedKeywords.join(", "),
        };
        await updateUserAttributes({ userAttributes });
        setIsKeywordEditing(!isKeywordEditing);
    };

    const cancelKeyword = async () => {
        setIsKeywordEditing(!isKeywordEditing);
        if (trackedKeywords) {
            setCheckedKeywords(trackedKeywords.split(",").map((keyword) => keyword.trim()));
        }
    };

    const filteredKeywords = KEYWORDS.filter((keyword) => keyword.toLowerCase().includes(keywordFilter.toLowerCase()));

    if (isLoading) {
        return (
            <Container maxWidth="sm">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="md">
            <Fade in={true} timeout={1000}>
                <CardContent>
                    <Box mt={4}>
                        <Typography
                            variant="h5"
                            gutterBottom
                            fontWeight="bold"
                            color="primary"
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            Tracked keywords
                            {isKeywordEditing ? (
                                <>
                                    <IconButton onClick={saveKeyword} sx={{ ml: 1, p: 0, height: "auto" }}>
                                        <CheckIcon />
                                    </IconButton>

                                    <IconButton onClick={cancelKeyword} sx={{ ml: 1, p: 0, height: "auto" }}>
                                        <CancelIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <IconButton
                                    onClick={() => setIsKeywordEditing(!isKeywordEditing)}
                                    sx={{ ml: 1, p: 0, height: "auto" }}
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </Typography>

                        {checkedKeywords.length > 0 ? (
                            <Paper
                                sx={{
                                    p: 2,
                                    mb: 3,
                                    borderRadius: 2,
                                    backgroundColor: theme.palette.background.default,
                                    boxShadow: "none", // 影を取り除く
                                    border: "none", // 境界線を取り除く
                                }}
                            >
                                <Box display="flex" flexWrap="wrap" gap={1}>
                                    {checkedKeywords.map((keyword, index) => (
                                        <Chip
                                            key={index}
                                            label={keyword}
                                            onDelete={isKeywordEditing ? () => handleKeywordToggle(keyword) : undefined}
                                            color="primary"
                                            icon={<TagIcon />}
                                        />
                                    ))}
                                </Box>
                            </Paper>
                        ) : (
                            <Typography variant="body1">Not registered</Typography>
                        )}
                        {isKeywordEditing && (
                            <>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="キーワードを検索..."
                                    value={keywordFilter}
                                    onChange={(e) => setKeywordFilter(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon color="action" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ mb: 2 }}
                                />

                                <Paper elevation={3} sx={{ height: 300, overflow: "hidden", borderRadius: 2 }}>
                                    <FixedSizeList
                                        height={300}
                                        itemCount={filteredKeywords.length}
                                        itemSize={46}
                                        width="100%"
                                        overscanCount={5}
                                    >
                                        {({ index, style }) => (
                                            <ListItem
                                                style={style}
                                                key={index}
                                                dense
                                                button
                                                onClick={() => handleKeywordToggle(filteredKeywords[index])}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checkedKeywords.includes(filteredKeywords[index])}
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={filteredKeywords[index]} />
                                            </ListItem>
                                        )}
                                    </FixedSizeList>
                                </Paper>
                            </>
                        )}
                    </Box>
                </CardContent>
            </Fade>
        </Container>
    );
};

export default withAuthenticator(MyDashboard);
