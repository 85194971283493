import { useState, useEffect, ChangeEvent, useMemo } from "react";

interface Paper {
    publisher: string;
    year: number;
    url: string;
    favicon: string;
    title: string;
    author: string[];
    abstract: string;
}

function usePublisherFilter(queryResults: Paper[]) {
    // 全ての出版社を取得します
    const allPublishers = queryResults.map((paper) => paper.publisher);

    // 出版社の出現回数を保持するオブジェクトを作成します
    const publisherCounts = useMemo(() => {
        return allPublishers.reduce((counts: Record<string, number>, publisher) => {
            if (!counts[publisher]) {
                counts[publisher] = 0;
            }
            counts[publisher]++;
            return counts;
        }, {});
    }, [queryResults]); // queryResults に依存

    // オブジェクトを配列に変換してソート
    const sortedEntries = useMemo(() => {
        return Object.entries(publisherCounts).sort((a, b) => b[1] - a[1]);
    }, [publisherCounts]);

    // ソートされた配列をオブジェクトに戻す
    const sortedPublisherCounts = useMemo(() => {
        return Object.fromEntries(sortedEntries);
    }, [sortedEntries]);

    // ソートされた出版社のリストを作成し、重複を削除
    const uniquePublishers = useMemo(
        () => Array.from(new Set(Object.keys(sortedPublisherCounts))),
        [sortedPublisherCounts]
    );

    // State variable to track selected publishers
    const [selectedPublishers, setSelectedPublishers] = useState<string[]>([]);

    const handlePublisherChange = (event: ChangeEvent<HTMLInputElement>) => {
        const publisher = event.target.name;
        if (event.target.checked) {
            // チェックボックスが選択された場合、出版社を追加します。
            const newSelectedPublishers = [...selectedPublishers, publisher];
            setSelectedPublishers(newSelectedPublishers);
        } else {
            // チェックボックスの選択が解除された場合、出版社を削除します。
            const newSelectedPublishers = selectedPublishers.filter((p) => p !== publisher);
            setSelectedPublishers(newSelectedPublishers);
        }
    };

    // queryResults が変更されたときに、出版社をリセット
    useEffect(() => {
        setSelectedPublishers(uniquePublishers);
    }, [uniquePublishers, queryResults]); // queryResults 変更時も依存

    return { uniquePublishers, selectedPublishers, handlePublisherChange, sortedPublisherCounts };
}

export default usePublisherFilter;
