import { useState, useEffect, ChangeEvent, useMemo } from "react";

interface Paper {
    publisher: string;
    year: number;
    url: string;
    favicon: string;
    title: string;
    author: string[];
    abstract: string;
}

function useDecadeFilter(queryResults: Paper[]) {
    // 全ての年代を取得します
    const allDecades = useMemo(() => {
        return queryResults.map((paper) => Math.floor(paper.year / 10) * 10);
    }, [queryResults]); // queryResultsに依存

    // 年代の出現回数を保持するオブジェクトを作成します
    const decadeCounts = useMemo(() => {
        return allDecades.reduce((counts: Record<string, number>, decade) => {
            if (!counts[decade]) {
                counts[decade] = 0;
            }
            counts[decade]++;
            return counts;
        }, {});
    }, [allDecades]);

    // オブジェクトを配列に変換しソート
    const sortedYearEntries = useMemo(() => {
        const yearEntries = Object.entries(decadeCounts);
        return yearEntries.sort((a, b) => b[1] - a[1]);
    }, [decadeCounts]);

    // ソートされた配列をオブジェクトに戻す
    const sortedDecadeCounts = useMemo(() => {
        return Object.fromEntries(sortedYearEntries);
    }, [sortedYearEntries]);

    // ソートされた年代のリストを作成し、重複を削除
    const uniqueDecades = useMemo(() => {
        return Array.from(new Set(Object.keys(sortedDecadeCounts)));
    }, [sortedDecadeCounts]);

    // State variable to track selected years
    const [selectedYears, setSelectedYears] = useState<string[]>([]);

    const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
        const year = event.target.name;
        if (event.target.checked) {
            // チェックボックスが選択された場合、年代を追加します。
            const newSelectedYears = [...selectedYears, year];
            setSelectedYears(newSelectedYears);
        } else {
            // チェックボックスの選択が解除された場合、年代を削除します。
            const newSelectedYears = selectedYears.filter((y) => y !== year);
            setSelectedYears(newSelectedYears);
        }
    };

    // queryResults または uniqueDecades が変更された際に選択された年代をリセット
    useEffect(() => {
        setSelectedYears(uniqueDecades);
    }, [uniqueDecades, queryResults]); // queryResults の変更時もリセット

    return { uniqueDecades, selectedYears, handleYearChange, sortedDecadeCounts };
}

export default useDecadeFilter;
