import React, { useEffect, useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Popover, List, Grid, Paper, useTheme } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import SearchIcon from "@mui/icons-material/Search";
import { SvgIconProps } from "@mui/material/SvgIcon";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import HandymanIcon from "@mui/icons-material/Handyman";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import FunctionsIcon from "@mui/icons-material/Functions";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import CoffeeIcon from "@mui/icons-material/Coffee";
import BookIcon from "@mui/icons-material/Book";
import InfoIcon from "@mui/icons-material/Info";
import { fetchUserAttributes, signOut } from "@aws-amplify/auth";
import UserAuthMenu from "../molecules/UserAuthMenu";

interface Service {
    name: string;
    icon: React.ComponentType<SvgIconProps>;
    url: string;
}

const pages = [
    { name: "Devices", url: "/primary-devices" },
    { name: "Journals", url: "/selected-journals" },
    { name: "Request", url: "https://forms.gle/bj32oa8HbeaqAUNL9" },
];

const pages_grid: Service[] = [
    { name: "Search", icon: SearchIcon, url: "/" },
    { name: "Journals", icon: LibraryBooksIcon, url: "/selected-journals" },
    { name: "Devices", icon: HandymanIcon, url: "/primary-devices" },
    { name: "Glossary", icon: LocalLibraryIcon, url: "/glossary" },
    { name: "Equation", icon: FunctionsIcon, url: "/tex-equation" },
    { name: "About (JA)", icon: InfoIcon, url: "/about" },
    { name: "Chat Bot(Beta)", icon: SmartToyIcon, url: "https://fusionpapers-chatbot.vercel.app" },
    { name: "Blog (JA)", icon: BookIcon, url: "https://scrapbox.io/fusionpapers/" },
    { name: "Buy me a coffee", icon: CoffeeIcon, url: "https://buymeacoffee.com/m.fukuyama/paperwithfusion" },
];

const darkTheme = createTheme({
    palette: {
        primary: {
            main: "#FFF",
        },
    },
});

const HeaderBar: React.FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [username, setUsername] = useState<string | null>("U");
    const theme = useTheme();
    const navigate = useNavigate();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            await signOut();
            setIsLoggedIn(false);
            navigate("/");
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        const authenticateAndGetSubId = async () => {
            setIsLoading(true);
            try {
                const currentUserAttributes = await fetchUserAttributes();
                const attributes = currentUserAttributes;
                const picture = attributes.picture;
                const username = attributes.name;
                setIsLoggedIn(true);
                setUsername(username ?? "U");
                setImageUrl(picture ?? null);
            } catch (error) {
                setIsLoggedIn(false);
            } finally {
                setIsLoading(false);
            }
        };
        authenticateAndGetSubId();
    }, []);

    return (
        <ThemeProvider theme={darkTheme}>
            <AppBar position="static" elevation={0}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: "none", md: "flex" },
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "#202124",
                                textDecoration: "none",
                                marginTop: "-5px",
                            }}
                        >
                            FusionPapers
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: "flex", md: "none" },
                                flexGrow: 1,
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".2rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            FusionPapers
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, justifyContent: "flex-end" }}>
                            {pages.map((page) => (
                                <a href={page.url} key={page.name}>
                                    <Button sx={{ my: 2, color: "#202124", display: "block" }}>{page.name}</Button>
                                </a>
                            ))}
                        </Box>
                        <Box sx={{ display: { xs: "flex", md: "flex" }, justifyContent: "flex-end" }}>
                            <IconButton onClick={handleClick} size="large" aria-label="show services" color="inherit">
                                <AppsIcon />
                            </IconButton>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <List sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 1, p: 2 }}>
                                    {pages_grid.map((service) => (
                                        <Grid item xs={4} key={service.name}>
                                            <Paper
                                                elevation={0}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    p: 1,
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                                                    },
                                                }}
                                                component="a"
                                                href={service.url}
                                            >
                                                <service.icon sx={{ fontSize: 40, mb: 1 }} />
                                                <Typography variant="caption" align="center">
                                                    {service.name}
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                    ))}
                                </List>
                            </Popover>
                        </Box>
                        <UserAuthMenu
                            isLoading={isLoading}
                            isLoggedIn={isLoggedIn}
                            imageUrl={imageUrl}
                            username={username}
                            theme={theme}
                            onLogout={handleLogout}
                        />
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
};

export default HeaderBar;
