// PublisherSelection.tsx
import React from "react";
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

interface PublisherSelectionProps {
    uniquePublishers: string[];
    selectedPublishers: string[];
    handlePublisherChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    sortedPublisherCounts: { [key: string]: number };
}

const PublisherSelection: React.FC<PublisherSelectionProps> = ({
    uniquePublishers,
    selectedPublishers,
    handlePublisherChange,
    sortedPublisherCounts,
}) => (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Selected Journals</FormLabel>
        <FormGroup>
            {uniquePublishers.map((publisher) => (
                <FormControlLabel
                    key={publisher}
                    control={
                        <Checkbox
                            name={publisher}
                            checked={selectedPublishers.includes(publisher)}
                            onChange={handlePublisherChange}
                        />
                    }
                    label={`${publisher} (${sortedPublisherCounts[publisher]})`}
                />
            ))}
        </FormGroup>
    </FormControl>
);

export default PublisherSelection;
