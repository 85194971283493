import regularPaper from "../../dataclass/regularPaper";
import LLMSummarizedPaperWithJournal from "../../dataclass/LLMSummarizedPaperWithJournal";
import FetchRegularPaperWithTrackedKeywords from "../gateways/FetchRegularPaperWithTrackedKeywords";
import FetchPaperByIds from "../gateways/FetchPaperByIds";
import FetchLLMSummarizedPaperWithJournal from "../gateways/FetchLLMSummarizedPaperWithJournal";
import { FetchCognitoInfo } from "../gateways/FetchCognitoInfo";

// ダッシュボードの情報を取得するためのユースケースクラス。
class dashboardManger {
    async getRegularPapersWithTrackedKeywords(): Promise<regularPaper[]> {
        const cognito_info = await FetchCognitoInfo();
        const regularPaper = await FetchRegularPaperWithTrackedKeywords(cognito_info);
        const filteredPapers = regularPaper.filter((paper) => paper.abstract && paper.abstract.trim() !== "");
        return filteredPapers;
    }

    async getFavoritePapers(): Promise<regularPaper[]> {
        const cognito_info = await FetchCognitoInfo();
        const regularPaper = await FetchPaperByIds(cognito_info.favoritePapers);
        const filteredPapers = regularPaper.filter((paper) => paper.abstract && paper.abstract.trim() !== "");
        return filteredPapers;
    }

    async getBookmarkPapers(): Promise<regularPaper[]> {
        const cognito_info = await FetchCognitoInfo();
        const regularPaper = await FetchPaperByIds(cognito_info.bookmarkPapers);
        const filteredPapers = regularPaper.filter((paper) => paper.abstract && paper.abstract.trim() !== "");
        return filteredPapers;
    }

    async getCheckedPapers(): Promise<regularPaper[]> {
        const cognito_info = await FetchCognitoInfo();
        const regularPaper = await FetchPaperByIds(cognito_info.checkedPapers);
        const filteredPapers = regularPaper.filter((paper) => paper.abstract && paper.abstract.trim() !== "");
        return filteredPapers;
    }

    async getLLMSummarizedPaperWithJournal(): Promise<LLMSummarizedPaperWithJournal[]> {
        const cognito_info = await FetchCognitoInfo();
        const LLMSummarizedPaperWithJournal = await FetchLLMSummarizedPaperWithJournal(cognito_info);
        return LLMSummarizedPaperWithJournal;
    }
}

export default dashboardManger;
