// KeywordSearchField.tsx
import React from "react";
import { FormControl, FormLabel, FormGroup, TextField } from "@mui/material";

interface KeywordSearchFieldProps {
    keyword: string;
    setKeyword: (value: string) => void;
}

const KeywordSearchField: React.FC<KeywordSearchFieldProps> = ({ keyword, setKeyword }) => (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Advanced keyword search</FormLabel>
        <FormGroup>
            <TextField
                id="outlined-basic"
                label="Keyword"
                variant="outlined"
                value={keyword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value)}
            />
        </FormGroup>
    </FormControl>
);

export default KeywordSearchField;
