// MainPageTemplate.tsx
import React from "react";
import SearchResultsBox from "../organisms/SearchResultBox";
import SearchResultsHeader from "../organisms/SearchResultHeader";

const MainPage: React.FC = () => {
    return (
        <>
            <div className="w-full">
                <SearchResultsHeader />
            </div>
            <div>
                <SearchResultsBox />
            </div>
        </>
    );
};

export default MainPage;
