import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import JournalPresenter from "../../backend/presenters/JournalPresenter";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import JournalDetailsBOXPresenter from "../../backend/presenters/JournalDetailsBOXPresenter";
import JournalProfile from "../molecules/JournalProfile";
import JournalKeywords from "../molecules/JournalKeywords";
import JournalRecentPapers from "../molecules/JournalRecentPapers";
import JournalPaperCountEvolution from "../molecules/JournalPaperCountEvolution";
import JournalPaperRatioDoughnut from "../molecules/JournalPaperRatioDoughnut";

interface JournalClass {
    name: string;
    abbreviation: string;
    desc: string;
    image: string;
    impact_factor: number;
    medianS2FD: number;
    medianS2FDA: number;
    Citescore: number;
}

interface Paper2 {
    title: string;
    author: string;
    abstract: string;
    publisher: string;
    url: string;
    favicon: string;
    year: number;
    cited: number;
    distance: number;
    order: number;
}

interface JournalData {
    journal: JournalClass;
    recent_paper: Paper2[];
    popular_paper: Paper2[];
    recent_topics: string[];
    common_topics: string[];
    paper_counts_by_device_all_year: Record<string, number>;
    paper_counts_by_device_per_year: Record<string, Record<string, number>>;
}

const JournalDetailsBOX: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [journalData, setJournalData] = useState<JournalData | null>(null);
    const [EvolutionOfDocumentCountData, setEvolutionOfDocumentCountData] = useState<any | null>({
        labels: [],
        datasets: [],
    });

    // idがundefinedの場合にデフォルト値（ここでは'0'）を使用します。
    const { id } = useParams<{ id: string }>();
    const journals = JournalPresenter();
    const journalId = parseInt(id || "0", 10);
    const Journal = journals.find((journal) => journal.id === journalId);

    useEffect(() => {
        const fetchData = async () => {
            if (!Journal) {
                navigate("/");
            } else {
                const journalDetails = await JournalDetailsBOXPresenter(Journal.name);
                setJournalData(journalDetails.journal_data);
                setEvolutionOfDocumentCountData(journalDetails.evolution_of_document_count_data);
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [Journal?.name]);

    if (!Journal) {
        return <div>Journal not found</div>;
    }

    return (
        <>
            <Container maxWidth="lg">
                <Box
                    sx={{
                        margin: "auto",
                        overflow: "auto",
                        backgroundColor: "white",
                        position: "relative",
                        borderRadius: "10px",
                        display: "flex", // Flexboxを使用
                        flexDirection: isMobile ? "column" : "row", // スマホサイズでは縦方向に要素を配置
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        {/* 左側のコンテンツをここに配置 */}
                        <Card style={{ margin: "3px" }}>
                            <Box sx={{ margin: "30px" }}>
                                <Box>
                                    <JournalProfile Journal={Journal} JournalData={journalData} />
                                </Box>
                                <Box>
                                    <JournalKeywords JournalData={journalData} />
                                </Box>
                                <Box>
                                    <JournalRecentPapers JournalData={journalData} />
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        {/* 右側のコンテンツをここに配置 */}
                        <Box sx={{ flex: 1 }}>
                            <Card style={{ margin: "3px" }}>
                                <Box sx={{ margin: "30px" }}>
                                    <JournalPaperRatioDoughnut JournalData={journalData} />
                                </Box>
                            </Card>
                            <Card style={{ margin: "3px" }}>
                                <Box sx={{ margin: "30px" }}>
                                    <JournalPaperCountEvolution
                                        evolution_of_document_count_data={EvolutionOfDocumentCountData}
                                    />
                                </Box>
                            </Card>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default JournalDetailsBOX;
