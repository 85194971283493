// AnalyzeResults.tsx
import React, { useState } from "react";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { Doughnut, Bar } from "react-chartjs-2";
import Rating from "@mui/material/Rating";
import NetworkGraph from "../atoms/NetworkGraph";
import { BarChart2 } from "lucide-react";

interface Paper {
    publisher: string;
    year: number;
    url: string;
    favicon: string;
    title: string;
    author: string[];
    abstract: string;
}

// 必要なプロパティを定義します。
interface AnalyzeResultsProps {
    query: string;
    queryResults: Paper[];
    sortedPublisherCounts: { [key: string]: number };
}

// 有名な色のリスト
const colors = [
    "rgb(54, 162, 235)", // Blue
    "rgb(255, 205, 86)", // Yellow
    "rgb(75, 192, 192)", // Green
    "rgb(153, 102, 255)", // Purple
    "rgb(255, 159, 64)", // Orange
    "rgb(255, 99, 255)", // Pink
    "rgb(255, 255, 99)", // Light Yellow
    "rgb(99, 255, 132)", // Light Green
    "rgb(132, 99, 255)", // Light Purple
];

// チャート描画のオプション
const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "80%",
    plugins: {
        legend: {
            display: true,
            position: "bottom" as const,
            reverse: true,
            labels: {
                font: {
                    size: 12,
                },
            },
        },
    },
};

const ratioText = {
    id: "ratio-text",
    beforeDraw(chart: any) {
        const {
            ctx,
            chartArea: { top, width, height },
        } = chart;
        ctx.save();
        //チャート描画部分の中央を指定
        ctx.fillRect(width / 2, top + height / 2, 0, 0);
        //フォントのスタイル指定
        ctx.font = "bold 20px Roboto";
        ctx.fillStyle = "#333333";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        //80%という文字列をドーナツチャートの中央部に描画します
        ctx.fillText("", width / 2, top + height / 2);
    },
};

function AnalyzeResults({ query, queryResults, sortedPublisherCounts }: AnalyzeResultsProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };
    /***********
     *グラフ機能(出版社比較)
     **********/

    // 出版社の名前と出現回数を取得します。
    const publishers = Object.keys(sortedPublisherCounts);
    const counts = Object.values(sortedPublisherCounts);

    // チャート用のデータ
    const data = {
        labels: publishers,
        datasets: [
            {
                data: counts,
                backgroundColor: publishers.map((_, i) => colors[i % colors.length]),
            },
        ],
    };
    /***********
     *グラフ機能(文献数の推移)
     **********/

    const evolution_of_document_count_options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                stacked: true,
                ticks: {
                    stepSize: 10,
                },
            },
            x: {
                stacked: true,
            },
        },
        plugins: {
            legend: {
                position: "bottom" as const, // 修正箇所
            },
        },
    };

    // 全ての出版社を取得します。
    const dataset = queryResults;

    const countPerYearPerPublisher: Record<string, any> = {};
    for (const data of dataset) {
        if (!countPerYearPerPublisher[data.publisher]) {
            countPerYearPerPublisher[data.publisher] = {};
        }
        if (!countPerYearPerPublisher[data.publisher][data.year]) {
            countPerYearPerPublisher[data.publisher][data.year] = 0;
        }
        countPerYearPerPublisher[data.publisher][data.year]++;
    }
    // 現在の年を取得
    const currentYear = new Date().getFullYear();

    // 1975年から現在の年までの配列を作成
    const years = Array.from({ length: currentYear - 1974 }, (_, i) => i + 1975);

    // 出版社ごとのデータを作成
    const dataPerPublisher = Object.entries(countPerYearPerPublisher).map(([publisher, yearsData], index) => {
        const data = years.map((year) => yearsData[year] || 0);
        return {
            label: publisher,
            data: data,
            backgroundColor: colors[index],
            fill: true,
        };
    });

    const evolution_of_document_count_data = {
        labels: years,
        datasets: dataPerPublisher,
    };
    /***********
     *ランキング機能(著者)
     **********/

    // 著者の名前と出現回数を取得します。
    const authors = queryResults.map((paper) => paper.author).flat();
    console.log();
    const authorCounts = authors.reduce((counts: Record<string, number>, author) => {
        if (!counts[author]) {
            counts[author] = 0;
        }
        counts[author]++;
        return counts;
    }, {});

    // 最大のカウントを見つける
    const maxCount = Math.max(...Object.values(authorCounts));
    // カウントを最大値で正規化
    const normalizedAuthorCounts = Object.entries(authorCounts).reduce(
        (counts: Record<string, number>, [author, count]) => {
            const normalizedCount = Math.round((Math.log(count + 1) / Math.log(maxCount + 1)) * 4 + 1);
            counts[author] = normalizedCount;
            if (normalizedCount > 2) {
                counts[author] = normalizedCount;
            }
            return counts;
        },
        {}
    );

    // オブジェクトを配列に変換
    const authorEntries = Object.entries(normalizedAuthorCounts);

    // 配列を値に基づいてソートし、最初の30エントリを取得
    const sortedAuthorEntries = authorEntries.sort((a, b) => b[1] - a[1]).slice(0, 30);

    const author_list = queryResults.slice(0, 10).map((paper) => paper.author);
    return (
        <>
            <div className="mt-4 flex items-center space-x-4">
                <BarChart2 className="h-8 w-8 text-blue-600" />
                <h1 className="text-2xl font-bold">Analyze Results({query})</h1>
            </div>
            <div className="mt-6 mb-12">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Card
                            sx={{
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                borderRadius: "16px",
                                transition: "transform 0.3s, box-shadow 0.3s",
                                "&:hover": {
                                    transform: "scale(1.03)",
                                    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // ホバー時の影を設定
                                },
                            }}
                        >
                            <CardHeader title="Journal Ratio" />
                            <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ height: "300px", width: "75%" }}>
                                    <Doughnut data={data} options={options} plugins={[ratioText]} />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card
                            sx={{
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                borderRadius: "16px",
                                transition: "transform 0.3s, box-shadow 0.3s",
                                "&:hover": {
                                    transform: "scale(1.03)",
                                    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // ホバー時の影を設定
                                },
                            }}
                        >
                            <CardHeader title="Evolution of Document Count" />
                            <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ height: "300px", width: "100%" }}>
                                    <Bar
                                        options={evolution_of_document_count_options}
                                        data={evolution_of_document_count_data}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card
                            sx={{
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                borderRadius: "16px",
                                transition: "transform 0.3s, box-shadow 0.3s",
                                "&:hover": {
                                    transform: "scale(1.03)",
                                    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // ホバー時の影を設定
                                },
                            }}
                        >
                            <CardHeader title="Author Ranking" />
                            <CardContent>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            maxHeight: isExpanded ? "none" : "762px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {sortedAuthorEntries.map(([author, count], index) => (
                                            <div key={index} style={{ textAlign: "left", display: "inline-block" }}>
                                                <Typography component="legend">{author}</Typography>
                                                <Rating name={`read-only-${index}`} value={count} readOnly />
                                            </div>
                                        ))}
                                    </div>
                                    <Button onClick={handleExpandClick}>
                                        {isExpanded ? "Show Less" : "Show More"}
                                    </Button>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Card
                            sx={{
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                borderRadius: "16px",
                                transition: "transform 0.3s, box-shadow 0.3s",
                                "&:hover": {
                                    transform: "scale(1.03)",
                                    boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // ホバー時の影を設定
                                },
                            }}
                        >
                            <CardHeader title="Author Network: Latest 10 Papers" />
                            <CardContent>
                                <NetworkGraph authorList={author_list} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default AnalyzeResults;
