import React from 'react';

interface RecentPapersProps {
  JournalData: any; // 適切な型に置き換えてください
}

const JournalRecentPapers: React.FC<RecentPapersProps> = ({ JournalData }) => {
  return (
    <>
      <h3 style={{ fontWeight: 'bold', marginTop: '30px' }}>Recent Papers</h3>
      { JournalData && JournalData.recent_paper.slice(0, 5).map((paper: any, index: number) => ( // paperの型を適切な型に置き換えてください
        <div key={index}>
          <p>
            <a
              href={paper.url} 
              style={{
                color: 'blue', 
                textDecoration: 'underline', 
                cursor: 'pointer'
              }}
              onMouseOver={(e) => e.currentTarget.style.color = 'darkblue'}
              onMouseOut={(e) => e.currentTarget.style.color = 'blue'}
            > 
              {paper.title.length > 50 ? `${paper.title.slice(0, 50)}...` : paper.title}
            </a>
          </p>
        </div>
      ))}
    </>
  );
}

export default JournalRecentPapers;