
export async function fetchJournalData(fileName: string) {

  if (fileName === 'Nuclear Fusion') {
    fileName = 'NF';
  } else if (fileName === 'Fusion Engineering and Design') {
    fileName = 'FED';
  } else if (fileName === 'Plasma Physics and Controlled Fusion') {
    fileName = 'PPCF';
  } else if (fileName === 'PHYSICAL REVIEW LETTERS') {
    fileName = 'PRL';
  } else if (fileName === 'Review of Scientific Instruments') {
    fileName = 'RSI';
  } else if (fileName === 'Physics of Plasmas') {
    fileName = 'POP';
  }

  const response = await fetch(`/cached_analyze_results/journal/${fileName}.json`);
  const device_data = await response.json();
  return device_data;
}

export default fetchJournalData;