import React, { useState, useMemo, useEffect } from "react";
import {
    Typography,
    Box,
    AppBar,
    Toolbar,
    SelectChangeEvent,
    Card,
    CardContent,
    Grid,
    Divider,
    Chip,
    Button,
    Menu,
    Container,
    ButtonGroup,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { MenuItem } from "@mui/material";
import LLMSummarizedPaperClass from "../../dataclass/LLMSummarizedPaperClass";
import MonthlyJournalReportClass from "../../dataclass/MonthlyJournalReportClass";
import { User } from "lucide-react";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import JournalClass from "../../dataclass/JournalClass";
import { useLocation } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { updateUserAttributes, fetchUserAttributes } from "@aws-amplify/auth";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
    "& .MuiButtonGroup-grouped": {
        margin: theme.spacing(0.5),
        border: 0,
        "&:not(:last-child)": {
            borderRadius: theme.shape.borderRadius,
        },
        "&:last-child": {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

interface Props {
    papers: LLMSummarizedPaperClass[];
    journals: MonthlyJournalReportClass[];
    journal: JournalClass;
}

const PaperJournalTables: React.FC<Props> = ({ papers, journals, journal }) => {
    const location = useLocation();
    const [language, setLanguage] = useState("EN");
    const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    // 3つの状態を管理するための状態変数
    const [favorites, setFavorites] = useState<number[]>([]);
    const [bookmarks, setBookmarks] = useState<number[]>([]);
    const [checkedPapers, setCheckedPapers] = useState<number[]>([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const authenticateAndGetSubId = async () => {
            try {
                await fetchUserAttributes();
                setIsLoggedIn(true);
                fetchUserPreferences();
            } catch (error) {
                setIsLoggedIn(false);
            }
        };
        authenticateAndGetSubId();
    }, []);

    // すべてのユーザー設定を取得する関数
    const fetchUserPreferences = async () => {
        try {
            const attributes = await fetchUserAttributes();
            const favoritePapers = attributes["custom:favorite_papers"];
            const bookmarkedPapers = attributes["custom:bookmark_papers"];
            const checkedPapers = attributes["custom:checked_papers"];
            const user_lang = attributes["custom:lang"];

            setLanguage(user_lang ? user_lang : "EN");
            setFavorites(favoritePapers ? favoritePapers.split(",").map(Number) : []);
            setBookmarks(bookmarkedPapers ? bookmarkedPapers.split(",").map(Number) : []);
            setCheckedPapers(checkedPapers ? checkedPapers.split(",").map(Number) : []);
        } catch (error) {
            console.error("Error fetching user preferences:", error);
        }
    };

    // 汎用的な状態更新関数
    const togglePaperState = async (
        paperID: number,
        currentState: number[],
        setState: React.Dispatch<React.SetStateAction<number[]>>,
        attributeName: string
    ) => {
        const updatedState = currentState.includes(paperID)
            ? currentState.filter((id) => id !== paperID)
            : [...currentState, paperID];

        setState(updatedState);

        try {
            const userAttributes = {
                [attributeName]: updatedState.join(","),
            };
            await updateUserAttributes({ userAttributes });
        } catch (error) {
            console.error(`Error updating ${attributeName}:`, error);
            setState(currentState);
        }
    };

    // 各アクションのトグル関数
    const toggleFavorite = (paperID: number) =>
        togglePaperState(paperID, favorites, setFavorites, "custom:favorite_papers");

    const toggleBookmark = (paperID: number) =>
        togglePaperState(paperID, bookmarks, setBookmarks, "custom:bookmark_papers");

    const toggleChecked = (paperID: number) =>
        togglePaperState(paperID, checkedPapers, setCheckedPapers, "custom:checked_papers");

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const lang = params.get("lang");
        if (lang === "JA" || lang === "EN") {
            setLanguage(lang);
        } else {
            setLanguage("EN");
        }
    }, [location.search]);

    const handleLanguageChange = (event: SelectChangeEvent<string>) => {
        setLanguage(event.target.value as string);
    };

    const handleMonthChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedMonth(newValue);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageMenuItemClick = (value: string) => {
        handleLanguageChange({ target: { value } } as SelectChangeEvent<string>);
        handleClose();
    };

    const filteredJournals = useMemo(() => {
        return journals.filter((journal) => journal.language === language);
    }, [journals, language]);

    const months = useMemo(() => {
        return Array.from(
            new Set(filteredJournals.map((journal) => `${journal.publish_year}-${journal.publish_month}`))
        ).sort((a, b) => {
            const [yearA, monthA] = a.split("-").map(Number);
            const [yearB, monthB] = b.split("-").map(Number);
            return yearB - yearA || monthB - monthA;
        });
    }, [filteredJournals]);

    useEffect(() => {
        if (selectedMonth === null && months.length > 0) {
            setSelectedMonth(months[0]);
        }
    }, [months, selectedMonth]);

    const currentMonthJournal = useMemo(() => {
        return filteredJournals.find((journal) => `${journal.publish_year}-${journal.publish_month}` === selectedMonth);
    }, [filteredJournals, selectedMonth]);

    const currentMonthPapers = useMemo(() => {
        return papers.filter(
            (paper) => paper.journal_monthly_report_id === currentMonthJournal?.id && paper.language === language
        );
    }, [papers, currentMonthJournal, language]);

    const open = Boolean(anchorEl);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="default" elevation={1}>
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, fontWeight: "bold", fontSize: "1.5rem" }}
                    >
                        AI Summary ({journal.name})
                    </Typography>
                    <Button
                        id="language-button"
                        aria-controls={open ? "language-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        sx={{ color: "inherit", fontSize: "1rem" }}
                    >
                        {language === "EN" ? "English" : "日本語"}
                    </Button>
                    <Menu id="language-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                        <MenuItem onClick={() => handleLanguageMenuItemClick("EN")}>English</MenuItem>
                        <MenuItem onClick={() => handleLanguageMenuItemClick("JA")}>日本語</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                    <StyledButtonGroup variant="outlined" aria-label="outlined button group">
                        {months.map((month) => (
                            <Button
                                key={month}
                                onClick={(event) => handleMonthChange(event, month)}
                                variant={selectedMonth === month ? "contained" : "outlined"}
                                sx={{
                                    transition: "background-color 0.3s, color 0.3s",
                                    "&:hover": {
                                        backgroundColor: "primary.main",
                                        color: "white",
                                    },
                                }}
                            >
                                {month}
                            </Button>
                        ))}
                    </StyledButtonGroup>
                </Box>

                {selectedMonth && (
                    <Box>
                        <Typography variant="h5" sx={{ mt: 2, mb: 3, fontWeight: "bold", fontSize: "1.75rem" }}>
                            Issue Summary
                        </Typography>
                        {currentMonthJournal && (
                            <Card sx={{ mb: 4, boxShadow: 2, p: 2 }}>
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        component="a"
                                        href={currentMonthJournal.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            color: "primary.main",
                                            textDecoration: "none",
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        }}
                                    >
                                        {currentMonthJournal.title}
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        {currentMonthJournal.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        )}
                        <Typography variant="h5" sx={{ mt: 4, mb: 3, fontWeight: "bold", fontSize: "1.75rem" }}>
                            Paper Summary
                        </Typography>

                        <Grid container spacing={3}>
                            {currentMonthPapers.map((paper) => (
                                <Grid item xs={12} sm={6} md={6} key={paper.id}>
                                    <Card
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            boxShadow: 2,
                                            p: 2,
                                        }}
                                    >
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography
                                                variant="h6"
                                                component="a"
                                                href={paper.paper_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    color: "primary.main",
                                                    textDecoration: "none",
                                                    "&:hover": {
                                                        textDecoration: "underline",
                                                    },
                                                }}
                                            >
                                                {paper.title}
                                            </Typography>
                                            <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
                                                {paper.summary}
                                            </Typography>
                                            <Divider sx={{ my: 2 }} />
                                            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                                <Box
                                                    sx={{
                                                        width: "40px",
                                                        height: "40px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        mr: 2,
                                                    }}
                                                >
                                                    <User size={32} />
                                                </Box>
                                                <Typography variant="body2" color="text.secondary">
                                                    Recommended for:
                                                    <br />
                                                    <strong>{paper.target}</strong>
                                                </Typography>
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                {paper.hashtag
                                                    .split("#")
                                                    .filter((tag) => tag.trim() !== "")
                                                    .map((tag, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={tag.trim()}
                                                            size="small"
                                                            sx={{
                                                                mr: 1,
                                                                mb: 1,
                                                                bgcolor: "primary.light",
                                                                color: "primary.contrastText",
                                                            }}
                                                        />
                                                    ))}
                                            </Box>
                                            {isLoggedIn && (
                                                <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
                                                    <IconButton onClick={() => toggleFavorite(paper.id)}>
                                                        {favorites.includes(paper.id) ? (
                                                            <StarIcon color="warning" />
                                                        ) : (
                                                            <StarBorderIcon />
                                                        )}
                                                    </IconButton>
                                                    <IconButton onClick={() => toggleBookmark(paper.id)}>
                                                        {bookmarks.includes(paper.id) ? (
                                                            <BookmarkIcon color="primary" />
                                                        ) : (
                                                            <BookmarkBorderIcon />
                                                        )}
                                                    </IconButton>
                                                    <IconButton onClick={() => toggleChecked(paper.id)}>
                                                        {checkedPapers.includes(paper.id) ? (
                                                            <CheckCircleIcon color="success" />
                                                        ) : (
                                                            <CheckCircleOutlineIcon />
                                                        )}
                                                    </IconButton>
                                                </Box>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </Container>
        </Box>
    );
};

export default PaperJournalTables;
