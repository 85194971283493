export const KEYWORDS: string[] = [
    "3d edge",
    "3d effects",
    "3d fields",
    "3d magnetic",
    "3d magnetic fields",
    "3d magnetic perturbations",
    "3he fusion",
    "absorption electron",
    "absorption electron cyclotron",
    "acceleration plasma",
    "acoustic mode",
    "acoustic modes",
    "acoustic wave",
    "activation ferritic",
    "activation ferritic martensitic",
    "activation phase",
    "active control",
    "active feedback",
    "actively cooled",
    "activity jet",
    "adiabatic compression",
    "aditya tokamak",
    "advanced divertor",
    "advanced fusion",
    "advanced scenarios",
    "advanced superconducting",
    "advanced superconducting tokamak",
    "advanced tokamak",
    "advanced tokamak operation",
    "advanced tokamak plasmas",
    "advanced tokamak scenario",
    "advanced tokamak scenarios",
    "advanced toroidal",
    "advanced toroidal facility",
    "advances stellarator",
    "advances understanding",
    "alcator mod",
    "alcator mod ohmic",
    "alcator mod plasmas",
    "alcator mod research",
    "alcator mod tokamak",
    "alcator tokamak",
    "alfven eigenmode",
    "alfven eigenmodes",
    "alfvén eigenmode",
    "alfvén eigenmode energetic",
    "alfvén eigenmode stability",
    "alfvén eigenmodes",
    "alfvén eigenmodes diii",
    "alfvén eigenmodes driven",
    "alfvén eigenmodes jet",
    "alfvén instabilities",
    "alfvén modes",
    "alfvén wave",
    "alfvén wave heating",
    "alfvénic modes",
    "alpha channelling",
    "alpha driven",
    "alpha heating",
    "alpha particle",
    "alpha particle confinement",
    "alpha particle driven",
    "alpha particle loss",
    "alpha particle transport",
    "alpha particles",
    "alpha particles fusion",
    "alpha particles tokamak",
    "alt ii",
    "alternative divertor",
    "ambipolar potential",
    "analysis disruptions",
    "analysis fusion",
    "analysis high",
    "analysis hl",
    "analysis icrf",
    "analysis impurity",
    "analysis ion",
    "analysis iter",
    "analysis jet",
    "analysis low",
    "analysis lower",
    "analysis lower hybrid",
    "analysis mhd",
    "analysis plasma",
    "analysis runaway",
    "analysis tokamak",
    "analysis tungsten",
    "analytic model",
    "angle slot",
    "angle slot divertor",
    "angular momentum",
    "anisotropic pressure",
    "anomalous diffusion",
    "anomalous ion",
    "anomalous transport",
    "anomalous transport processes",
    "antenna coupling",
    "application iter",
    "applied magnetic",
    "applied resonant",
    "applied resonant magnetic",
    "approach fusion",
    "approach ignition",
    "aptwg meeting",
    "articles based",
    "asdex divertor",
    "asdex tokamak",
    "asdex upgrade",
    "asdex upgrade diii",
    "asdex upgrade jet",
    "asdex upgrade mode",
    "asdex upgrade modes",
    "asdex upgrade ohmic",
    "asdex upgrade plasmas",
    "asdex upgrade tokamak",
    "asia pacific",
    "asia pacific transport",
    "aspect ratio",
    "aspect ratio tokamak",
    "aspects fusion",
    "atc tokamak",
    "atf torsatron",
    "atomistic simulations",
    "author index",
    "author index titles",
    "auxiliary heated",
    "auxiliary heating",
    "axial magnetic",
    "axial magnetic field",
    "axially symmetric",
    "axially symmetric plasma",
    "axis heating",
    "axis neutral",
    "axis neutral beam",
    "axis stellarator",
    "axisymmetric equilibria",
    "axisymmetric magnetic",
    "axisymmetric magnetic fields",
    "axisymmetric mhd",
    "axisymmetric mirror",
    "axisymmetric modes",
    "axisymmetric plasma",
    "axisymmetric stellarator",
    "axisymmetric tori",
    "axisymmetric toroidal",
    "ballooning instabilities",
    "ballooning mode",
    "ballooning modes",
    "ballooning stability",
    "banana drift",
    "barrier discharges",
    "barrier formation",
    "barrier plasmas",
    "barriers jet",
    "based fusion",
    "based neutral",
    "based neutral beam",
    "based transport",
    "baseline scenario",
    "bayesian inference",
    "beam current",
    "beam current drive",
    "beam driven",
    "beam driven current",
    "beam emission",
    "beam heated",
    "beam heated plasmas",
    "beam heating",
    "beam injected",
    "beam injection",
    "beam injection heating",
    "beam injection hl",
    "beam injection jt",
    "beam injector",
    "beam injectors",
    "beam ion",
    "beam ions",
    "beam plasma",
    "beam probe",
    "beam test",
    "beam test facility",
    "beam transport",
    "behaviour plasma",
    "behaviour tungsten",
    "belt pinch",
    "bernstein wave",
    "bernstein wave heating",
    "beryllium erosion",
    "beta effects",
    "beta induced",
    "beta induced alfvén",
    "beta limit",
    "beta limits",
    "beta low",
    "beta plasma",
    "beta plasmas",
    "beta stellarator",
    "beta tokamak",
    "beta tokamak equilibria",
    "biased electrode",
    "blanket module",
    "blanket modules",
    "blistering tungsten",
    "bootstrap current",
    "bootstrap current large",
    "bootstrap fraction",
    "bounce averaged",
    "boundary conditions",
    "boundary equilibrium",
    "boundary layer",
    "boundary mhd",
    "boundary plasma",
    "bounded plasma",
    "breeder blanket",
    "breeding blanket",
    "broadening lower",
    "broadening lower hybrid",
    "bubble formation",
    "bulk ion",
    "bumpy torus",
    "bumpy torus ebt",
    "bundle divertor",
    "burn control",
    "burning plasma",
    "burning plasmas",
    "caltech tokamak",
    "canonical profiles",
    "carrying plasma",
    "central cell",
    "central shear",
    "central solenoid",
    "chapter diagnostics",
    "chapter overview",
    "chapter overview summary",
    "chapter physics",
    "chapter physics energetic",
    "chapter plasma",
    "characteristics edge",
    "characteristics high",
    "characteristics high density",
    "characteristics mode",
    "charge exchange",
    "charge exchange recombination",
    "charge exchange spectroscopy",
    "charged fusion",
    "charged fusion products",
    "charged particles",
    "chemical erosion",
    "chemical sputtering",
    "chirping alfvén",
    "chirping mode",
    "circular cross",
    "circular cross section",
    "circular magnetic",
    "circular tokamak",
    "classical diffusion",
    "classical tearing",
    "classical tearing modes",
    "cleo tokamak",
    "closed divertor",
    "closed flux",
    "closed flux surface",
    "closed line",
    "closure model",
    "co2 laser",
    "coaxial helicity",
    "coaxial helicity injection",
    "code validation",
    "coherent mode",
    "coherent mode mode",
    "coil design",
    "cold plasma",
    "collective thomson",
    "collective thomson scattering",
    "collisional plasma",
    "collisional transport",
    "collisionless drift",
    "collisionless shock",
    "collisionless trapped",
    "collisionless trapped electron",
    "committee meeting",
    "committee meeting held",
    "compact helical",
    "compact toroid",
    "compact toroid injection",
    "compact torus",
    "comparison experimental",
    "comparison jet",
    "comparison measured",
    "comparison neoclassical",
    "comparison theory",
    "comparison tokamak",
    "compass tokamak",
    "component plasma",
    "component tokamak",
    "components iter",
    "compressed plasma",
    "compressional alfvén",
    "computational study",
    "conceptual design",
    "conference held",
    "conference summary",
    "conference summary sessions",
    "configuration plasma",
    "configuration plasmas",
    "confined fusion",
    "confined plasma",
    "confined plasmas",
    "confinement alpha",
    "confinement alpha particles",
    "confinement asdex",
    "confinement characteristics",
    "confinement data",
    "confinement database",
    "confinement degradation",
    "confinement devices",
    "confinement diii",
    "confinement experiments",
    "confinement fusion",
    "confinement fusion experiments",
    "confinement fusion target",
    "confinement fusion targets",
    "confinement high",
    "confinement high density",
    "confinement improvement",
    "confinement jet",
    "confinement jt",
    "confinement jt 60u",
    "confinement magnetic",
    "confinement mhd",
    "confinement mode",
    "confinement plasma",
    "confinement plasmas",
    "confinement properties",
    "confinement regime",
    "confinement regimes",
    "confinement scaling",
    "confinement stability",
    "confinement studies",
    "confinement systems",
    "confinement theory",
    "confinement theory modelling",
    "confinement time",
    "confinement time scaling",
    "confinement times",
    "confinement transition",
    "confinement transport",
    "consistent analysis",
    "contribution theory",
    "control advanced",
    "control current",
    "control diii",
    "control electron",
    "control experiments",
    "control iter",
    "control jet",
    "control kstar",
    "control long",
    "control long pulse",
    "control neoclassical",
    "control neoclassical tearing",
    "control plasma",
    "control resistive",
    "control resistive wall",
    "control reversed",
    "control tcv",
    "control techniques",
    "control tokamak",
    "control vertical",
    "controlled fusion",
    "controlled fusion devices",
    "controlled fusion plasma",
    "controlled nuclear",
    "controlled nuclear fusion",
    "core confinement",
    "core edge",
    "core electron",
    "core ion",
    "core pedestal",
    "core plasma",
    "core plasmas",
    "core tokamak",
    "core transport",
    "core turbulence",
    "correction diii",
    "coupling efficiency",
    "coupling fast",
    "coupling ion",
    "coupling lower",
    "coupling lower hybrid",
    "crashes asdex",
    "crashes asdex upgrade",
    "critical gradient",
    "cross field",
    "cross section",
    "cross sections",
    "ct 6b",
    "ct 6b tokamak",
    "current carrying",
    "current carrying plasma",
    "current density",
    "current density profile",
    "current diffusion",
    "current distribution",
    "current drive",
    "current drive diii",
    "current drive efficiency",
    "current drive electron",
    "current drive experiments",
    "current drive heating",
    "current drive ion",
    "current drive iter",
    "current drive jet",
    "current drive jt",
    "current drive lower",
    "current drive plasma",
    "current drive tokamak",
    "current driven",
    "current driven plasmas",
    "current fraction",
    "current free",
    "current generation",
    "current hole",
    "current large",
    "current large helical",
    "current penetration",
    "current plasma",
    "current profile",
    "current profile control",
    "current profiles",
    "current quench",
    "current ramp",
    "current ramps",
    "current rise",
    "current rotation",
    "current start",
    "current startup",
    "current tokamak",
    "current toroidal",
    "currentless heliotron",
    "currentless heliotron plasmas",
    "cycle oscillations",
    "cyclotron absorption",
    "cyclotron current",
    "cyclotron current drive",
    "cyclotron emission",
    "cyclotron frequency",
    "cyclotron frequency range",
    "cyclotron harmonic",
    "cyclotron harmonics",
    "cyclotron heated",
    "cyclotron heated plasmas",
    "cyclotron heating",
    "cyclotron heating assisted",
    "cyclotron heating current",
    "cyclotron instability",
    "cyclotron instability plasma",
    "cyclotron oscillations",
    "cyclotron plasma",
    "cyclotron power",
    "cyclotron radiation",
    "cyclotron range",
    "cyclotron range frequencies",
    "cyclotron range frequency",
    "cyclotron resonance",
    "cyclotron resonance heated",
    "cyclotron resonance heating",
    "cyclotron wave",
    "cylindrical geometry",
    "cylindrical plasma",
    "damaged tungsten",
    "damping rate",
    "data driven",
    "database iter",
    "deep learning",
    "demo design",
    "demo fns",
    "demo fusion",
    "demo fusion reactor",
    "demo reactor",
    "demo wall",
    "dense plasma",
    "dense plasmas",
    "density asdex",
    "density control",
    "density edge",
    "density fluctuation",
    "density fluctuation measurements",
    "density fluctuations",
    "density gradient",
    "density limit",
    "density limit disruptions",
    "density limits",
    "density measurements",
    "density mode",
    "density operation",
    "density peaking",
    "density pedestal",
    "density perturbations",
    "density plasma",
    "density plasmas",
    "density profile",
    "density profile peaking",
    "density profile tokamak",
    "density profiles",
    "density pump",
    "density rise",
    "density shoulder",
    "density temperature",
    "density tokamak",
    "dependence edge",
    "dependence mode",
    "dependence pedestal",
    "deposited layers",
    "deposition divertor",
    "deposition profile",
    "design high",
    "design integration",
    "design iter",
    "detached conditions",
    "detached divertor",
    "detached divertor plasmas",
    "detached plasmas",
    "determination plasma",
    "deuterium implantation",
    "deuterium inventory",
    "deuterium plasma",
    "deuterium plasmas",
    "deuterium retention",
    "deuterium retention tungsten",
    "deuterium tritium",
    "deuterium tritium experiments",
    "deuterium tritium plasmas",
    "development advanced",
    "development high",
    "development iter",
    "development plasma",
    "diagnostic mirrors",
    "diagnostic tool",
    "diagnostics large",
    "diagnostics plasma",
    "diamagnetic drift",
    "diffuse pinch",
    "diffusion tokamak",
    "diii advanced",
    "diii advanced tokamak",
    "diii discharges",
    "diii divertor",
    "diii high",
    "diii hybrid",
    "diii implications",
    "diii jet",
    "diii mode",
    "diii mode discharges",
    "diii mode plasmas",
    "diii plasmas",
    "diii research",
    "diii tokamak",
    "dimensional equilibria",
    "dimensional equilibrium",
    "dimensional mhd",
    "dimensional modelling",
    "dimensional tokamak",
    "direct drive",
    "direct drive inertial",
    "discharge east",
    "discharges asdex",
    "discharges asdex upgrade",
    "discharges diii",
    "discharges diii tokamak",
    "discharges doublet",
    "discharges doublet iii",
    "discharges east",
    "discharges jet",
    "discharges jt",
    "discharges jt 60u",
    "discharges tore",
    "discharges tore supra",
    "displacement events",
    "disruption avoidance",
    "disruption forces",
    "disruption generated",
    "disruption generated runaway",
    "disruption mitigation",
    "disruption prediction",
    "disruption predictor",
    "disruption prevention",
    "disruptions asdex",
    "disruptions asdex upgrade",
    "disruptions jet",
    "disruptions pbx",
    "disruptive instability",
    "dissipative trapped",
    "dissipative trapped electron",
    "dissipative trapped ion",
    "distribution function",
    "distribution functions",
    "dite experiment",
    "dite tokamak",
    "diverted plasmas",
    "diverted tokamak",
    "divertor asdex",
    "divertor asdex upgrade",
    "divertor biasing",
    "divertor conditions",
    "divertor configuration",
    "divertor configurations",
    "divertor design",
    "divertor detachment",
    "divertor diii",
    "divertor discharges",
    "divertor east",
    "divertor experiment",
    "divertor experiments",
    "divertor geometry",
    "divertor heat",
    "divertor heat flux",
    "divertor heat load",
    "divertor heat loads",
    "divertor lhd",
    "divertor magnetic",
    "divertor operation",
    "divertor particle",
    "divertor performance",
    "divertor plasma",
    "divertor plasma detachment",
    "divertor plasmas",
    "divertor plate",
    "divertor plates",
    "divertor power",
    "divertor pumping",
    "divertor studies",
    "divertor target",
    "divertor targets",
    "divertor textor",
    "divertor tiles",
    "divertor tokamak",
    "divertor transport",
    "divertor wendelstein",
    "doppler backscattering",
    "double null",
    "double null plasmas",
    "double shell",
    "double tearing",
    "double tearing mode",
    "double tearing modes",
    "doublet iii",
    "doublet iii tokamak",
    "drift alfvén",
    "drift cyclotron",
    "drift effects",
    "drift instabilities",
    "drift instability",
    "drift kinetic",
    "drift kinetic effects",
    "drift loss",
    "drift mode",
    "drift modes",
    "drift orbit",
    "drift tearing",
    "drift wave",
    "drift wave instability",
    "drift wave transport",
    "drift wave turbulence",
    "drive diii",
    "drive efficiency",
    "drive electron",
    "drive electron cyclotron",
    "drive experiments",
    "drive heating",
    "drive high density",
    "drive ignition",
    "drive inertial",
    "drive inertial confinement",
    "drive ion",
    "drive iter",
    "drive jet",
    "drive jt",
    "drive lower",
    "drive lower hybrid",
    "drive plasma",
    "drive tokamak",
    "driven alfvén",
    "driven alfvén eigenmodes",
    "driven alfvénic",
    "driven current",
    "driven currents",
    "driven edge",
    "driven electron",
    "driven energetic",
    "driven fusion",
    "driven geodesic",
    "driven geodesic acoustic",
    "driven instabilities",
    "driven instability",
    "driven ion",
    "driven ion temperature",
    "driven mhd",
    "driven mhd instabilities",
    "driven modes",
    "driven permeation",
    "driven plasma",
    "driven plasmas",
    "driven resistive",
    "driven tokamak",
    "driven toroidal",
    "driven turbulence",
    "dt experiments",
    "dt plasmas",
    "dust particles",
    "dynamic ergodic",
    "dynamic ergodic divertor",
    "dynamic transport",
    "dynamic trap",
    "dynamics tokamak",
    "east mode",
    "east superconducting",
    "east superconducting tokamak",
    "east tokamak",
    "eccd asdex",
    "eccd asdex upgrade",
    "ech eccd",
    "ech plasmas",
    "ecrh eccd",
    "ecrh plasma",
    "ecrh power",
    "eddy currents",
    "edge confinement",
    "edge density",
    "edge diii",
    "edge impurity",
    "edge ion",
    "edge localised",
    "edge localized",
    "edge localized mode",
    "edge localized modes",
    "edge magnetic",
    "edge pedestal",
    "edge plasma",
    "edge plasma parameters",
    "edge plasma properties",
    "edge plasma turbulence",
    "edge plasmas",
    "edge radial",
    "edge radial electric",
    "edge region",
    "edge stability",
    "edge tokamak",
    "edge transport",
    "edge transport barrier",
    "edge transport barriers",
    "edge turbulence",
    "effect divertor",
    "effect electron",
    "effect high",
    "effect ion",
    "effect magnetic",
    "effect magnetic field",
    "effect plasma",
    "effect radial",
    "effect resonant",
    "effect resonant magnetic",
    "effect toroidal",
    "effects alpha",
    "effects alpha particles",
    "effects current",
    "effects divertor",
    "effects edge",
    "effects energetic",
    "effects hydrogen",
    "effects impurity",
    "effects ion",
    "effects ion temperature",
    "effects jet",
    "effects low",
    "effects lower",
    "effects lower hybrid",
    "effects magnetic",
    "effects mhd",
    "effects mode",
    "effects neutral",
    "effects plasma",
    "effects radial",
    "effects resistive",
    "effects resonant",
    "effects resonant magnetic",
    "effects tearing",
    "effects tokamak",
    "effects toroidal",
    "effects tungsten",
    "eigenmode energetic",
    "eigenmode stability",
    "eigenmodes diii",
    "eigenmodes driven",
    "eigenmodes jet",
    "elastic scattering",
    "electric field",
    "electric field edge",
    "electric field effects",
    "electric fields",
    "electrical conductivity",
    "electrode biasing",
    "electromagnetic effects",
    "electromagnetic field",
    "electromagnetic instabilities",
    "electromagnetic wave",
    "electron acceleration",
    "electron beam",
    "electron beams",
    "electron bernstein",
    "electron bernstein wave",
    "electron confinement",
    "electron current",
    "electron cyclotron",
    "electron cyclotron current",
    "electron cyclotron emission",
    "electron cyclotron heating",
    "electron cyclotron power",
    "electron cyclotron resonance",
    "electron cyclotron wave",
    "electron density",
    "electron distribution",
    "electron driven",
    "electron dynamics",
    "electron energy",
    "electron generation",
    "electron heat",
    "electron heat transport",
    "electron heating",
    "electron internal",
    "electron internal transport",
    "electron ion",
    "electron landau",
    "electron landau damping",
    "electron mitigation",
    "electron mode",
    "electron mode turbulence",
    "electron scale",
    "electron scale turbulence",
    "electron temperature",
    "electron temperature fluctuations",
    "electron temperature gradient",
    "electron temperature measurements",
    "electron temperature profile",
    "electron thermal",
    "electron thermal conductivity",
    "electron thermal transport",
    "electron transport",
    "electron trapping",
    "electrons textor",
    "electrons tokamak",
    "electrons toroidal",
    "electrostatic drift",
    "electrostatic fluctuations",
    "electrostatic instabilities",
    "electrostatic turbulence",
    "elm characteristics",
    "elm control",
    "elm crash",
    "elm dynamics",
    "elm energy",
    "elm free",
    "elm free mode",
    "elm frequency",
    "elm like",
    "elm mitigation",
    "elm pacing",
    "elm precursors",
    "elm regime",
    "elm suppression",
    "elm suppression resonant",
    "elm triggering",
    "elmo bumpy",
    "elmo bumpy torus",
    "elms asdex",
    "elms asdex upgrade",
    "elmy mode",
    "elmy mode discharges",
    "elmy mode plasmas",
    "elmy modes",
    "elongated tokamak",
    "emc3 eirene",
    "emission electron",
    "emission electron cyclotron",
    "emission measurements",
    "emission spectroscopy",
    "empirical scaling",
    "energetic electrons",
    "energetic ion",
    "energetic ion driven",
    "energetic ions",
    "energetic particle",
    "energetic particle driven",
    "energetic particle effects",
    "energetic particle induced",
    "energetic particle modes",
    "energetic particle physics",
    "energetic particle transport",
    "energetic particles",
    "energetic particles magnetic",
    "energetic trapped",
    "energy balance",
    "energy conference",
    "energy conference summary",
    "energy confinement",
    "energy confinement scaling",
    "energy confinement time",
    "energy confinement times",
    "energy conversion",
    "energy density",
    "energy deposition",
    "energy distribution",
    "energy exchange",
    "energy flux",
    "energy gain",
    "energy high",
    "energy loss",
    "energy losses",
    "energy neutral",
    "energy particle",
    "energy plasma",
    "energy principle",
    "energy spectrum",
    "energy transfer",
    "energy transport",
    "engineering design",
    "engineering problems",
    "engineering problems fusion",
    "english french",
    "enhanced confinement",
    "enhanced particle",
    "enhancement mirror",
    "equilibrium configurations",
    "equilibrium magnetic",
    "equilibrium model",
    "equilibrium plasma",
    "equilibrium reconstruction",
    "equilibrium stability",
    "equilibrium tokamak",
    "equilibrium toroidal",
    "ergodic divertor",
    "ergodic divertor experiments",
    "ergodic divertor textor",
    "erosion redeposition",
    "error field",
    "error field correction",
    "error field penetration",
    "error fields",
    "eu demo",
    "eu transport",
    "eu transport task",
    "european conference",
    "european demo",
    "european demo design",
    "european torus",
    "evolution plasma",
    "exchange recombination",
    "exchange recombination spectroscopy",
    "exchange spectroscopy",
    "excitation beta",
    "excitation beta induced",
    "excited energetic",
    "executive summary",
    "executive summary iaea",
    "expanded boundary",
    "experiment nstx",
    "experiment simulation",
    "experiment theory",
    "experimental characterization",
    "experimental data",
    "experimental determination",
    "experimental evidence",
    "experimental investigation",
    "experimental measurements",
    "experimental observation",
    "experimental observations",
    "experimental progress",
    "experimental research",
    "experimental simulation",
    "experimental studies",
    "experimental study",
    "experimental test",
    "experimental tokamak",
    "experimental validation",
    "experiments alcator",
    "experiments diii",
    "experiments east",
    "experiments ion",
    "experiments ion cyclotron",
    "experiments iter",
    "experiments modelling",
    "experiments plasma",
    "experiments tcv",
    "experiments textor",
    "experiments theory",
    "experiments tore",
    "experiments tore supra",
    "experiments wendelstein",
    "exposed deuterium",
    "exposed deuterium plasma",
    "exposed high",
    "exposed high flux",
    "exposed tungsten",
    "extension operational",
    "external kink",
    "external kink modes",
    "external magnetic",
    "external magnetic field",
    "externally applied",
    "extrap t2r",
    "extrapolation iter",
    "facing component",
    "facing components",
    "facing material",
    "facing materials",
    "factor profile",
    "far scrape",
    "far scrape layer",
    "faraday screen",
    "faraday shield",
    "fast electron",
    "fast electrons",
    "fast ignition",
    "fast ion",
    "fast ion confinement",
    "fast ion distribution",
    "fast ion driven",
    "fast ion energy",
    "fast ion loss",
    "fast ion losses",
    "fast ion orbit",
    "fast ion profile",
    "fast ion redistribution",
    "fast ion transport",
    "fast ions",
    "fast magnetosonic",
    "fast particle",
    "fast particles",
    "fast plasma",
    "fast wave",
    "fast wave current",
    "fast wave heating",
    "federal republic",
    "federal republic germany",
    "feedback control",
    "feedback control plasma",
    "feedback stabilization",
    "feedback stabilization axisymmetric",
    "ferritic martensitic",
    "ferritic martensitic steels",
    "ffhr d1",
    "field amplification",
    "field asdex",
    "field coil",
    "field coils",
    "field configuration",
    "field correction",
    "field correction diii",
    "field current",
    "field current drive",
    "field edge",
    "field effects",
    "field errors",
    "field generation",
    "field induced",
    "field line",
    "field lines",
    "field penetration",
    "field perturbations",
    "field pinch",
    "field pinch discharges",
    "field pinch experiment",
    "field pinch plasma",
    "field pinch plasmas",
    "field pinch research",
    "field pinch rfx",
    "field pinches",
    "field plasma",
    "field reversal",
    "field reversed",
    "field reversed configuration",
    "field reversed configurations",
    "field reversed mirror",
    "field ripple",
    "field ripples",
    "field stellarator",
    "field structure",
    "field tokamak",
    "field toroidal",
    "fields diii",
    "fields jet",
    "fields plasma",
    "finite beta",
    "finite element",
    "finite ion",
    "finite ion larmor",
    "finite larmor",
    "finite larmor radius",
    "finite orbit",
    "finite pressure",
    "fishbone instabilities",
    "fishbone instability",
    "fishbones jet",
    "flexible heliac",
    "flexible heliac tj",
    "flow driven",
    "flow generation",
    "flow magnetic",
    "flow shear",
    "flow velocity",
    "fluctuation induced",
    "fluctuation measurements",
    "fluctuation spectra",
    "fluctuations associated",
    "fluctuations plasma",
    "fluctuations tokamak",
    "fluid model",
    "flute instability",
    "flute oscillations",
    "flux deuterium",
    "flux deuterium plasma",
    "flux distribution",
    "flux driven",
    "flux plasma",
    "flux surface",
    "flux surfaces",
    "flux width",
    "fokker planck",
    "force free",
    "force workshop",
    "formation sustainment",
    "frascati tokamak",
    "frascati tokamak upgrade",
    "free boundary",
    "free boundary equilibrium",
    "free mode",
    "free plasma",
    "free surface",
    "frequency alfvén",
    "frequency chirping",
    "frequency field",
    "frequency fluctuations",
    "frequency heating",
    "frequency instabilities",
    "frequency magnetic",
    "frequency modes",
    "frequency range",
    "frequency sweeping",
    "frequency turbulence",
    "frequency wave",
    "ft tokamak",
    "ftu tokamak",
    "fuel cycle",
    "fuel pellets",
    "fuel retention",
    "fully non inductive",
    "function analysis",
    "fusion alpha",
    "fusion alpha particles",
    "fusion articles",
    "fusion award",
    "fusion born",
    "fusion cross",
    "fusion demo",
    "fusion development",
    "fusion devices",
    "fusion energy",
    "fusion energy conference",
    "fusion engineering",
    "fusion environment",
    "fusion experiments",
    "fusion fission",
    "fusion ignition",
    "fusion neutron",
    "fusion neutron source",
    "fusion performance",
    "fusion pilot",
    "fusion plasma",
    "fusion plasma physics",
    "fusion plasmas",
    "fusion power",
    "fusion power gain",
    "fusion power operation",
    "fusion power plant",
    "fusion power plants",
    "fusion power reactors",
    "fusion prize",
    "fusion product",
    "fusion products",
    "fusion products tftr",
    "fusion reaction",
    "fusion reactions",
    "fusion reactivity",
    "fusion reactor",
    "fusion reactor based",
    "fusion reactor design",
    "fusion reactors",
    "fusion relevant",
    "fusion relevant plasma",
    "fusion research",
    "fusion research summaries",
    "fusion science",
    "fusion target",
    "fusion targets",
    "fusion technology",
    "fusion test",
    "fusion test reactor",
    "fusion triple",
    "fusion triple product",
    "future fusion",
    "fuzz formation",
    "gamma 10",
    "gamma 10 tandem",
    "gamma ray",
    "gas dynamic",
    "gas dynamic trap",
    "gas flow",
    "gas injection",
    "gas jet",
    "gas puff",
    "gas puff imaging",
    "gas puff modulation",
    "gas puffing",
    "gaussian process",
    "generated alpha",
    "generated runaway",
    "generation tokamak",
    "geodesic acoustic",
    "geodesic acoustic mode",
    "geodesic acoustic modes",
    "global alfvén",
    "global alfvén eigenmode",
    "global alfvén eigenmodes",
    "global confinement",
    "global confinement data",
    "global energy",
    "global energy confinement",
    "global gyrokinetic",
    "global gyrokinetic simulations",
    "global mhd",
    "globus m2",
    "globus spherical",
    "globus spherical tokamak",
    "globus tokamak",
    "grad shafranov",
    "grade plasmas",
    "gradient driven",
    "gradient driven turbulence",
    "gradient mode",
    "gradient modes",
    "gradient turbulence",
    "grain boundaries",
    "grassy elm",
    "grassy elm regime",
    "grassy elms",
    "group aptwg",
    "group aptwg meeting",
    "group meeting",
    "growth rate",
    "growth rates",
    "guiding centre",
    "gyro kinetic",
    "gyrokinetic analysis",
    "gyrokinetic modelling",
    "gyrokinetic simulation",
    "gyrokinetic simulations",
    "gyrokinetic turbulence",
    "gyrotrons fusion",
    "hall effect",
    "halo current",
    "halo currents",
    "hard ray",
    "harmonic ecrh",
    "harmonic electron",
    "harmonic electron cyclotron",
    "harmonic fast",
    "harmonic fast wave",
    "harmonic icrf",
    "harmonic icrf heating",
    "heat conductivity",
    "heat diffusion",
    "heat diffusivity",
    "heat flux",
    "heat flux width",
    "heat fluxes",
    "heat load",
    "heat loads",
    "heat particle",
    "heat particle flux",
    "heat pulse",
    "heat pulse propagation",
    "heat transfer",
    "heat transport",
    "heat transport asdex",
    "heat transport tftr",
    "heated asdex",
    "heated discharges",
    "heated mode",
    "heated mode plasmas",
    "heated plasma",
    "heated plasmas",
    "heated tokamak",
    "heated tokamak plasmas",
    "heating alcator",
    "heating asdex",
    "heating asdex upgrade",
    "heating assisted",
    "heating confinement",
    "heating current",
    "heating current drive",
    "heating diii",
    "heating east",
    "heating efficiency",
    "heating experiment",
    "heating experiments",
    "heating fusion",
    "heating high",
    "heating icrf",
    "heating inhomogeneous",
    "heating ion",
    "heating ion cyclotron",
    "heating jet",
    "heating jft",
    "heating jt",
    "heating large",
    "heating lhd",
    "heating lower",
    "heating lower hybrid",
    "heating magnetic",
    "heating neutral",
    "heating neutral beam",
    "heating non",
    "heating plasma",
    "heating plasmas",
    "heating power",
    "heating scenarios",
    "heating tfr",
    "heating tftr",
    "heating tmx",
    "heating tokamak",
    "heating tokamak plasmas",
    "heating toroidal",
    "heating toroidal plasmas",
    "heavy impurity",
    "heavy ion",
    "heavy ion beam",
    "heavy ion fusion",
    "heavy ion inertial",
    "heliac equilibria",
    "heliac tj",
    "heliac tj ii",
    "helical axis",
    "helical axis stellarator",
    "helical coils",
    "helical core",
    "helical device",
    "helical divertor",
    "helical fields",
    "helical fusion",
    "helical fusion reactor",
    "helical magnetic",
    "helical magnetic axis",
    "helical magnetic field",
    "helical plasma",
    "helical plasmas",
    "helical reactor",
    "helical systems",
    "helically symmetric",
    "helicity injection",
    "heliotron device",
    "heliotron dr",
    "heliotron plasmas",
    "heliotron torsatron",
    "helium exhaust",
    "helium hydrogen",
    "helium ion",
    "helium plasma",
    "helium plasmas",
    "helium transport",
    "helium transport exhaust",
    "high ballooning",
    "high ballooning modes",
    "high beta",
    "high beta plasma",
    "high beta plasmas",
    "high beta stellarator",
    "high beta tokamak",
    "high bootstrap",
    "high bootstrap fraction",
    "high confinement",
    "high confinement mode",
    "high current",
    "high density",
    "high density mode",
    "high density operation",
    "high density plasma",
    "high density plasmas",
    "high density tokamak",
    "high efficiency",
    "high energy",
    "high field",
    "high fluence",
    "high flux",
    "high flux deuterium",
    "high flux plasma",
    "high frequency",
    "high frequency heating",
    "high fusion",
    "high fusion power",
    "high gain",
    "high harmonic",
    "high harmonic fast",
    "high heat",
    "high heat flux",
    "high intensity",
    "high ion",
    "high ion temperature",
    "high magnetic",
    "high magnetic field",
    "high order",
    "high performance",
    "high performance discharges",
    "high performance jet",
    "high performance plasmas",
    "high performance steady",
    "high plasma",
    "high plasmas",
    "high poloidal",
    "high power",
    "high power long",
    "high power neutral",
    "high pressure",
    "high recycling",
    "high resolution",
    "high speed",
    "high temperature",
    "high temperature plasma",
    "high temperature plasmas",
    "high βn",
    "high βp",
    "highly elongated",
    "hl 1m",
    "hl 1m tokamak",
    "hl 2a",
    "hl 2a experiments",
    "hl 2a tokamak",
    "hl 2m",
    "hl 2m tokamak",
    "hollow current",
    "hot electron",
    "hot ion",
    "hot ion mode",
    "hot ions",
    "hot plasma",
    "hot spot",
    "hot spots",
    "hsx stellarator",
    "ht superconducting",
    "ht superconducting tokamak",
    "ht tokamak",
    "hybrid current",
    "hybrid current drive",
    "hybrid current driven",
    "hybrid discharges",
    "hybrid frequency",
    "hybrid frequency range",
    "hybrid heating",
    "hybrid heating alcator",
    "hybrid plasmas",
    "hybrid resonance",
    "hybrid scenario",
    "hybrid scenarios",
    "hybrid simulation",
    "hybrid wave",
    "hybrid wave driven",
    "hybrid wave propagation",
    "hydrogen atoms",
    "hydrogen deuterium",
    "hydrogen deuterium plasmas",
    "hydrogen helium",
    "hydrogen isotope",
    "hydrogen pellet",
    "hydrogen plasma",
    "hydrogen plasmas",
    "hydrogen retention",
    "hydrogen transport",
    "hydromagnetic stability",
    "iaea fusion",
    "iaea fusion energy",
    "iaea international",
    "iaea international conference",
    "iaea technical",
    "iaea technical committee",
    "iaea technical meeting",
    "iaea workshop",
    "icf targets",
    "icrf antenna",
    "icrf antennas",
    "icrf heated",
    "icrf heated plasmas",
    "icrf heating",
    "icrf heating experiments",
    "icrf heating jet",
    "icrf nbi",
    "icrf nbi heating",
    "icrf power",
    "icrf wave",
    "icrh antenna",
    "ideal ballooning",
    "ideal internal",
    "ideal internal kink",
    "ideal magnetohydrodynamic",
    "ideal mhd",
    "ideal mhd stability",
    "ideal resistive",
    "ifmif dones",
    "ifmif eveda",
    "ignited tokamak",
    "ignition conditions",
    "ignition experiment",
    "ignition facility",
    "ignition ignitor",
    "ignition targets",
    "ignition tokamak",
    "ignitor experiment",
    "ii experiments",
    "ii plasma",
    "ii plasmas",
    "ii tokamak",
    "iiu tokamak plasmas",
    "ilw mode",
    "ilw mode plasmas",
    "imaging neutral",
    "imaging neutral particle",
    "impact electron",
    "impact fusion",
    "impact magnetic",
    "impact plasma",
    "implications iter",
    "improved confinement",
    "improved mode",
    "improved performance",
    "improvement plasma",
    "impurities alcator",
    "impurity accumulation",
    "impurity behaviour",
    "impurity control",
    "impurity density",
    "impurity effects",
    "impurity emission",
    "impurity flow",
    "impurity injection",
    "impurity ion",
    "impurity ions",
    "impurity production",
    "impurity radiation",
    "impurity seeded",
    "impurity seeding",
    "impurity sources",
    "impurity transport",
    "impurity transport jet",
    "index titles",
    "indirect drive",
    "indirectly driven",
    "induced alfven",
    "induced alfven eigenmode",
    "induced alfvén",
    "induced alfvén eigenmode",
    "induced alfvén eigenmodes",
    "induced drift",
    "induced energetic",
    "induced fast",
    "induced fast ion",
    "induced geodesic",
    "induced geodesic acoustic",
    "induced magnetic",
    "induced neutral",
    "induced sawtooth",
    "inductive current",
    "inductive current drive",
    "inductive plasma",
    "inductive scenario",
    "inertial confinement",
    "inertial confinement fusion",
    "inertial electrostatic",
    "inertial fusion",
    "inertial fusion energy",
    "inertially confined",
    "influence hall",
    "influence plasma",
    "influence toroidal",
    "influence toroidal rotation",
    "inhomogeneous magnetic",
    "inhomogeneous magnetic field",
    "inhomogeneous plasma",
    "inhomogeneous plasmas",
    "injected impurities",
    "injection asdex",
    "injection asdex upgrade",
    "injection current",
    "injection diii",
    "injection east",
    "injection experiment",
    "injection experiments",
    "injection ftu",
    "injection heating",
    "injection hl",
    "injection jet",
    "injection jt",
    "injection jt 60u",
    "injection low",
    "injection tokamak",
    "instabilities diii",
    "instabilities driven",
    "instabilities high",
    "instabilities lower",
    "instabilities tokamak",
    "instabilities tokamak plasmas",
    "instability current",
    "instability current carrying",
    "instability driven",
    "instability inhomogeneous",
    "instability lower",
    "instability lower hybrid",
    "instability plasma",
    "instability tokamak",
    "integrated core",
    "integrated modeling",
    "integrated modelling",
    "integrated plasma",
    "integrated simulation",
    "integrated tokamak",
    "integrated tokamak modelling",
    "intense laser",
    "inter elm",
    "interaction energetic",
    "interaction energetic particles",
    "interaction tearing",
    "interaction tearing modes",
    "interactions controlled",
    "interactions controlled fusion",
    "interactions fields",
    "interchange instability",
    "interchange mode",
    "internal disruptions",
    "internal inductance",
    "internal kink",
    "internal kink mode",
    "internal kink modes",
    "internal reconnection",
    "internal transport",
    "internal transport barrier",
    "internal transport barriers",
    "international conference",
    "international fusion",
    "international symposium",
    "international tokamak",
    "international tokamak reactor",
    "international workshop",
    "intrinsic rotation",
    "intrinsic toroidal",
    "intrinsic torque",
    "investigation electron",
    "investigation fast",
    "investigation plasma",
    "inward particle",
    "ion acoustic",
    "ion based",
    "ion based neutral",
    "ion beam",
    "ion beam probe",
    "ion beams",
    "ion bernstein",
    "ion bernstein wave",
    "ion confinement",
    "ion cyclotron",
    "ion cyclotron emission",
    "ion cyclotron frequency",
    "ion cyclotron heating",
    "ion cyclotron range",
    "ion cyclotron resonance",
    "ion cyclotron wave",
    "ion diamagnetic",
    "ion diamagnetic drift",
    "ion distribution",
    "ion driven",
    "ion electron",
    "ion energy",
    "ion fusion",
    "ion heat",
    "ion heat transport",
    "ion heating",
    "ion inertial",
    "ion inertial fusion",
    "ion internal",
    "ion internal transport",
    "ion irradiation",
    "ion larmor",
    "ion larmor radius",
    "ion loss",
    "ion losses",
    "ion losses induced",
    "ion mode",
    "ion orbit",
    "ion orbit loss",
    "ion pressure",
    "ion profile",
    "ion redistribution",
    "ion sound",
    "ion source",
    "ion sources",
    "ion species",
    "ion temperature",
    "ion temperature gradient",
    "ion temperatures",
    "ion thermal",
    "ion thermal transport",
    "ion transport",
    "ion velocity",
    "ion velocity distribution",
    "ionizing shock",
    "ions plasma",
    "ions tokamak",
    "ions tokamak plasma",
    "ions tokamak plasmas",
    "iron core",
    "irradiated tungsten",
    "island divertor",
    "island formation",
    "isothermal plasma",
    "isotope effect",
    "isotope effects",
    "isotope mass",
    "issue overview",
    "isx tokamak",
    "itb plasmas",
    "iter baseline",
    "iter baseline scenario",
    "iter conditions",
    "iter demo",
    "iter demonstration",
    "iter discharges",
    "iter disruption",
    "iter divertor",
    "iter feat",
    "iter fusion",
    "iter hybrid",
    "iter ion",
    "iter ion cyclotron",
    "iter like",
    "iter like tungsten",
    "iter like wall",
    "iter mode",
    "iter mode confinement",
    "iter nbi",
    "iter neutral",
    "iter neutral beam",
    "iter operation",
    "iter overview",
    "iter physics",
    "iter physics basis",
    "iter plasma",
    "iter project",
    "iter relevant",
    "iter research",
    "iter scenarios",
    "iter simulations",
    "iter steady",
    "iter steady state",
    "iter wall",
    "itg turbulence",
    "jet advanced",
    "jet advanced scenarios",
    "jet advanced tokamak",
    "jet carbon",
    "jet compass",
    "jet deuterium",
    "jet deuterium tritium",
    "jet discharges",
    "jet disruption",
    "jet divertor",
    "jet dt",
    "jet dt plasmas",
    "jet elmy",
    "jet high",
    "jet hybrid",
    "jet hybrid plasmas",
    "jet ilw",
    "jet ilw mode",
    "jet implications",
    "jet implications iter",
    "jet iter",
    "jet iter like",
    "jet mode",
    "jet mode plasmas",
    "jet new",
    "jet plasma",
    "jet plasmas",
    "jet tokamak",
    "jft 2m",
    "jft 2m tokamak",
    "jft tokamak",
    "jipp ii",
    "jipp ii tokamak",
    "jipp iiu",
    "jipp iiu tokamak",
    "joint european",
    "joint european torus",
    "jt 60",
    "jt 60 tokamak",
    "jt 60sa",
    "jt 60sa project",
    "jt 60u",
    "jt 60u plasmas",
    "jt 60u reversed",
    "jt 60u tokamak",
    "kinetic analysis",
    "kinetic ballooning",
    "kinetic ballooning mode",
    "kinetic effects",
    "kinetic geodesic",
    "kinetic geodesic acoustic",
    "kinetic model",
    "kinetic modelling",
    "kinetic simulations",
    "kinetic theory",
    "kink instability",
    "kink mode",
    "kink modes",
    "kink tearing",
    "kstar mode",
    "kstar mode plasmas",
    "kstar plasmas",
    "kstar tokamak",
    "landau closure",
    "landau closure model",
    "landau damping",
    "langmuir probes",
    "large amplitude",
    "large aspect ratio",
    "large helical",
    "large helical device",
    "large scale",
    "large tokamak",
    "large tokamak experiments",
    "large torus",
    "larmor radius",
    "larmor radius effects",
    "laser blow",
    "laser driven",
    "laser fusion",
    "laser heated",
    "laser heated plasmas",
    "laser induced",
    "laser interaction",
    "laser plasma",
    "laser plasma interactions",
    "laser produced",
    "laser produced plasmas",
    "laser pulses",
    "laser radiation",
    "laser scattering",
    "launching lower",
    "launching lower hybrid",
    "laws plasma",
    "layer divertor",
    "layer large",
    "layer plasma",
    "layer plasmas",
    "layer tokamak",
    "layer transport",
    "layer turbulence",
    "lhd plasma",
    "lhd plasmas",
    "light impurities",
    "light impurity",
    "light impurity transport",
    "light ion",
    "like plasmas",
    "like tungsten",
    "like tungsten divertor",
    "like wall",
    "limit cycle",
    "limit cycle oscillations",
    "limit disruptions",
    "limit tokamak",
    "limiter discharges",
    "limiter experiment",
    "limiter experiments",
    "limiter plasmas",
    "limiter shadow",
    "limits disruptions",
    "line emission",
    "linear coupling",
    "linear gyrokinetic",
    "linear mhd",
    "linear mhd modelling",
    "linear model",
    "linear nonlinear",
    "linear pinch",
    "linear plasma",
    "linear saturation",
    "linear stability",
    "linear theory",
    "linked mirror",
    "liquid lithium",
    "liquid lithium divertor",
    "liquid lithium limiter",
    "liquid metal",
    "list referees",
    "lithium divertor",
    "lithium lead",
    "lithium limiter",
    "lithium tokamak",
    "lithium wall",
    "loc soc",
    "local current",
    "local electron",
    "local heat",
    "local transport",
    "localized electron",
    "localized mode",
    "localized mode suppression",
    "localized modes",
    "localized modes asdex",
    "locked mode",
    "locked modes",
    "long distance",
    "long lived",
    "long pulse",
    "long pulse high",
    "long pulse mode",
    "long pulse operation",
    "long range",
    "long sustainment",
    "long term",
    "los alamos",
    "loss alpha",
    "loss alpha particles",
    "loss cone",
    "loss edge",
    "loss energetic",
    "loss fast",
    "loss tftr",
    "loss tokamak",
    "losses induced",
    "losses tokamak",
    "low activation",
    "low aspect",
    "low aspect ratio",
    "low collisionality",
    "low density",
    "low discharges",
    "low energy",
    "low field",
    "low frequency",
    "low frequency fluctuations",
    "low frequency heating",
    "low high confinement",
    "low impurity",
    "low impurity transport",
    "low order",
    "low order rational",
    "low recycling",
    "low shear",
    "low temperature",
    "low torque",
    "low triangularity",
    "lower hybrid",
    "lower hybrid current",
    "lower hybrid frequency",
    "lower hybrid heating",
    "lower hybrid wave",
    "lt tokamak",
    "machine learning",
    "magnetic analysis",
    "magnetic axis",
    "magnetic braking",
    "magnetic configuration",
    "magnetic configurations",
    "magnetic confinement",
    "magnetic confinement devices",
    "magnetic confinement systems",
    "magnetic confinement theory",
    "magnetic diagnostics",
    "magnetic divertor",
    "magnetic field",
    "magnetic field configuration",
    "magnetic field current",
    "magnetic field generation",
    "magnetic field lines",
    "magnetic field perturbations",
    "magnetic field plasma",
    "magnetic field ripple",
    "magnetic field ripples",
    "magnetic field tokamak",
    "magnetic fields",
    "magnetic fluctuations",
    "magnetic flux",
    "magnetic footprints",
    "magnetic fusion",
    "magnetic fusion devices",
    "magnetic geometry",
    "magnetic island",
    "magnetic island formation",
    "magnetic islands",
    "magnetic limiter",
    "magnetic measurements",
    "magnetic mirror",
    "magnetic perturbation",
    "magnetic perturbation fields",
    "magnetic perturbations",
    "magnetic perturbations asdex",
    "magnetic perturbations diii",
    "magnetic perturbations east",
    "magnetic perturbations edge",
    "magnetic pumping",
    "magnetic reconnection",
    "magnetic shear",
    "magnetic shear tokamak",
    "magnetic surface",
    "magnetic surfaces",
    "magnetic topology",
    "magnetic turbulence",
    "magnetically confined",
    "magnetically insulated",
    "magnetised plasmas",
    "magnetized plasma",
    "magnetized plasmas",
    "magneto acoustic",
    "magnetohydrodynamic equilibria",
    "magnetohydrodynamic equilibrium",
    "magnetohydrodynamic instabilities",
    "magnetohydrodynamic simulations",
    "magnetohydrodynamic stability",
    "magnum psi",
    "main chamber",
    "main ion",
    "major disruptions",
    "marginal stability",
    "martensitic steels",
    "massive gas",
    "massive gas injection",
    "mast spherical",
    "mast spherical tokamak",
    "mast tokamak",
    "mast upgrade",
    "material interactions",
    "materials fusion",
    "materials fusion reactors",
    "measurement plasma",
    "measurements asdex",
    "measurements asdex upgrade",
    "measurements diii",
    "measurements electron",
    "measurements fast",
    "measurements ion",
    "measurements ion temperature",
    "measurements iter",
    "measurements jet",
    "measurements modelling",
    "measurements plasma",
    "measurements tritium",
    "meeting held",
    "melt layer",
    "mercier criterion",
    "mercier stability",
    "merging start",
    "message editor",
    "metal wall",
    "metallic wall",
    "mev ions",
    "mhd activity",
    "mhd analysis",
    "mhd ballooning",
    "mhd ballooning mode",
    "mhd equilibria",
    "mhd equilibrium",
    "mhd induced",
    "mhd instabilities",
    "mhd instability",
    "mhd mode",
    "mhd modeling",
    "mhd modelling",
    "mhd modes",
    "mhd phenomena",
    "mhd simulation",
    "mhd simulations",
    "mhd spectrum",
    "mhd stability",
    "mhd stability analysis",
    "mhd stability limits",
    "mhd stability properties",
    "microtearing modes",
    "microwave imaging",
    "minority heating",
    "minority ion",
    "minority ion cyclotron",
    "mirror confinement",
    "mirror devices",
    "mirror end",
    "mirror experiment",
    "mirror field",
    "mirror fusion",
    "mirror machine",
    "mirror machines",
    "mirror magnetic",
    "mirror plasma",
    "mirror plasmas",
    "mirror reactors",
    "mirror systems",
    "mirror trap",
    "mirrors iter",
    "mitigation disruptions",
    "mod diii",
    "mod experiment",
    "mod mode",
    "mod mode plasmas",
    "mod ohmic",
    "mod plasmas",
    "mod research",
    "mod reversed",
    "mod reversed field",
    "mod tokamak",
    "mode access",
    "mode activity",
    "mode asdex",
    "mode asdex upgrade",
    "mode confinement",
    "mode confinement database",
    "mode confinement jet",
    "mode control",
    "mode conversion",
    "mode coupling",
    "mode density",
    "mode density limit",
    "mode diii",
    "mode discharge",
    "mode discharges",
    "mode discharges diii",
    "mode driven",
    "mode east",
    "mode edge",
    "mode electron",
    "mode electron cyclotron",
    "mode energy",
    "mode energy confinement",
    "mode excitation",
    "mode experiments",
    "mode feedback",
    "mode growth",
    "mode high",
    "mode instability",
    "mode jet",
    "mode jft",
    "mode jft 2m",
    "mode jt",
    "mode locking",
    "mode mode",
    "mode mode plasmas",
    "mode operation",
    "mode pedestal",
    "mode pedestal structure",
    "mode physics",
    "mode plasma",
    "mode plasmas",
    "mode plasmas asdex",
    "mode plasmas diii",
    "mode plasmas east",
    "mode plasmas jet",
    "mode plasmas jt",
    "mode power",
    "mode power threshold",
    "mode regime",
    "mode regimes",
    "mode reversed",
    "mode reversed field",
    "mode stability",
    "mode stabilization",
    "mode stabilization electron",
    "mode structure",
    "mode suppression",
    "mode tokamak",
    "mode toroidal",
    "mode transition",
    "mode turbulence",
    "model based",
    "model electron",
    "model fast",
    "model impurity",
    "model mode",
    "model plasma",
    "model tokamak",
    "model validation",
    "modeling analysis",
    "modeling high",
    "modeling plasma",
    "modeling tokamak",
    "modelling edge",
    "modelling electron",
    "modelling impurity",
    "modelling iter",
    "modelling jet",
    "modelling lower",
    "modelling lower hybrid",
    "modelling neoclassical",
    "modelling plasma",
    "modelling tungsten",
    "modes advanced",
    "modes asdex",
    "modes asdex upgrade",
    "modes diii",
    "modes driven",
    "modes east",
    "modes edge",
    "modes electron",
    "modes energetic",
    "modes high",
    "modes iter",
    "modes jet",
    "modes large",
    "modes low",
    "modes lower",
    "modes lower hybrid",
    "modes magnetic",
    "modes plasma",
    "modes reversed",
    "modes reversed field",
    "modes rotating",
    "modes tcv",
    "modes tokamak",
    "modes tokamak plasmas",
    "modes toroidal",
    "modular stellarator",
    "modulated electron",
    "modulation experiments",
    "modulation jet",
    "molecular beam",
    "molecular beam injection",
    "molecular dynamics",
    "momentum pinch",
    "momentum transport",
    "monte carlo",
    "monte carlo simulation",
    "motional stark",
    "motional stark effect",
    "mst reversed",
    "mst reversed field",
    "multi channel",
    "multi machine",
    "multi mode",
    "multi scale",
    "multi scale interactions",
    "multiple mirror",
    "national ignition",
    "national ignition facility",
    "national spherical",
    "national spherical torus",
    "nbi driven",
    "nbi fast",
    "nbi fast ion",
    "nbi heated",
    "nbi heated plasmas",
    "nbi heating",
    "nbi icrf",
    "near ion",
    "near separatrix",
    "negative central",
    "negative central shear",
    "negative hydrogen",
    "negative ion",
    "negative ion based",
    "negative ion beam",
    "negative ion source",
    "negative ion sources",
    "negative triangularity",
    "neo classical",
    "neoclassical diffusion",
    "neoclassical impurity",
    "neoclassical tearing",
    "neoclassical tearing mode",
    "neoclassical tearing modes",
    "neoclassical theory",
    "neoclassical toroidal",
    "neoclassical toroidal plasma",
    "neoclassical toroidal viscosity",
    "neoclassical transport",
    "neon seeding",
    "net current",
    "net current free",
    "net electric",
    "net erosion",
    "net toroidal",
    "neural network",
    "neural networks",
    "neutral atom",
    "neutral atoms",
    "neutral beam",
    "neutral beam current",
    "neutral beam driven",
    "neutral beam heated",
    "neutral beam heating",
    "neutral beam injected",
    "neutral beam injection",
    "neutral beam injector",
    "neutral beam injectors",
    "neutral beam test",
    "neutral beams",
    "neutral density",
    "neutral gas",
    "neutral hydrogen",
    "neutral injection",
    "neutral injection heating",
    "neutral particle",
    "neutral particle analyzer",
    "neutral particles",
    "neutral transport",
    "neutron emission",
    "neutron emission spectroscopy",
    "neutron irradiation",
    "neutron production",
    "neutron source",
    "neutron sources",
    "neutron yield",
    "new approach",
    "new editor",
    "new method",
    "new paradigm",
    "nitrogen seeding",
    "noble gas",
    "non ambipolar",
    "non axisymmetric",
    "non axisymmetric magnetic",
    "non circular",
    "non circular cross",
    "non circular tokamak",
    "non diffusive",
    "non equilibrium",
    "non inductive",
    "non inductive current",
    "non inductive plasma",
    "non linear",
    "non linear coupling",
    "non linear mhd",
    "non linear saturation",
    "non linear theory",
    "non local",
    "non maxwellian",
    "non perturbative",
    "non resonant",
    "non resonant magnetic",
    "non stationary",
    "non thermal",
    "non uniform",
    "non uniform magnetic",
    "non uniform plasma",
    "nonlinear coupling",
    "nonlinear dynamics",
    "nonlinear evolution",
    "nonlinear excitation",
    "nonlinear gyrokinetic",
    "nonlinear magnetohydrodynamics",
    "nonlinear mhd",
    "nonlinear simulation",
    "note contributors",
    "nstx plasmas",
    "nstx spherical",
    "nstx spherical torus",
    "nstx upgrade",
    "nucl fusion",
    "nuclear elastic",
    "nuclear elastic scattering",
    "nuclear fusion",
    "nuclear fusion articles",
    "nuclear fusion award",
    "nuclear fusion prize",
    "nuclear fusion research",
    "nuclear technology",
    "null plasmas",
    "numerical analysis",
    "numerical computation",
    "numerical investigation",
    "numerical modeling",
    "numerical modelling",
    "numerical simulation",
    "numerical simulations",
    "numerical studies",
    "numerical study",
    "observation mhd",
    "octopole tokamak",
    "ohm law",
    "ohmic confinement",
    "ohmic discharges",
    "ohmic heating",
    "ohmic mode",
    "ohmic mode plasmas",
    "ohmic plasmas",
    "ohmically heated",
    "ohmically heated plasmas",
    "onset neoclassical",
    "onset neoclassical tearing",
    "onset type",
    "open field",
    "open traps",
    "operating scenarios",
    "operating space",
    "operation control",
    "operation diii",
    "operation ht",
    "operation iter",
    "operation jet",
    "operation jt",
    "operation jt 60u",
    "operation scenarios",
    "operation tore",
    "operation tore supra",
    "operational limits",
    "operational space",
    "optimized shear",
    "optimized shear discharges",
    "orbit loss",
    "orbit width",
    "order rational",
    "outer midplane",
    "overdense plasmas",
    "overview alcator",
    "overview alcator mod",
    "overview asdex",
    "overview asdex upgrade",
    "overview experimental",
    "overview ftu",
    "overview jet",
    "overview jt",
    "overview jt 60u",
    "overview kstar",
    "overview mast",
    "overview physics",
    "overview recent",
    "overview recent experimental",
    "overview recent hl",
    "overview recent physics",
    "overview rfx",
    "overview rfx mod",
    "overview summary",
    "overview tcv",
    "overview tj",
    "overview tj ii",
    "pacific transport",
    "pacific transport working",
    "papers presented",
    "parallel flow",
    "parallel momentum",
    "parameters tokamak",
    "parametric decay",
    "parametric dependence",
    "parametric excitation",
    "parametric instabilities",
    "parametric instability",
    "parametric study",
    "partially ionized",
    "particle analyzer",
    "particle balance",
    "particle cell",
    "particle confinement",
    "particle control",
    "particle diagnostics",
    "particle distribution",
    "particle driven",
    "particle driven alfvén",
    "particle driven geodesic",
    "particle driven instabilities",
    "particle driven modes",
    "particle effects",
    "particle energy",
    "particle exhaust",
    "particle flux",
    "particle heat",
    "particle induced",
    "particle induced geodesic",
    "particle instabilities",
    "particle loss",
    "particle losses",
    "particle modes",
    "particle orbit",
    "particle orbits",
    "particle physics",
    "particle power",
    "particle simulation",
    "particle transport",
    "particle transport density",
    "particles fusion",
    "particles fusion research",
    "particles iter",
    "particles magnetic",
    "particles magnetic confinement",
    "particles resistive",
    "particles resistive wall",
    "particles tokamak",
    "particles tokamak reactor",
    "particles tokamak reactors",
    "pbx tokamak",
    "pdx tokamak",
    "peaked density",
    "peaked density profiles",
    "peaking jet",
    "pedestal characteristics",
    "pedestal collapse",
    "pedestal confinement",
    "pedestal performance",
    "pedestal region",
    "pedestal stability",
    "pedestal structure",
    "pedestal transport",
    "pedestal turbulence",
    "peeling ballooning",
    "pegasus toroidal",
    "pegasus toroidal experiment",
    "pellet ablation",
    "pellet fuelled",
    "pellet fuelling",
    "pellet injection",
    "pellet injection experiments",
    "pellet injections",
    "pellet penetration",
    "penetration plasma",
    "penetration resonant",
    "penetration resonant magnetic",
    "penetration threshold",
    "performance analysis",
    "performance discharges",
    "performance iter",
    "performance jet",
    "performance plasma",
    "performance plasmas",
    "performance steady",
    "performance steady state",
    "permanent magnet",
    "permanent magnets",
    "perpendicular neutral",
    "perturbation field",
    "perturbation fields",
    "perturbations asdex",
    "perturbations asdex upgrade",
    "perturbations diii",
    "perturbations east",
    "perturbations edge",
    "perturbative transport",
    "perturbed magnetic",
    "pfirsch schluter",
    "pfirsch schlüter",
    "phase iter",
    "phase space",
    "phased waveguide",
    "phenomena plasma",
    "physics basis",
    "physics controlled",
    "physics controlled nuclear",
    "physics design",
    "physics energetic",
    "physics energetic ions",
    "physics engineering",
    "physics issues",
    "physics mode",
    "physics studies",
    "physics technology",
    "pilot plants",
    "pinch configuration",
    "pinch configurations",
    "pinch discharges",
    "pinch experiment",
    "pinch experiments",
    "pinch plasma",
    "pinch plasmas",
    "pinch research",
    "pinch rfx",
    "pitch angle",
    "plasma anisotropic",
    "plasma anisotropic pressure",
    "plasma behaviour",
    "plasma beta",
    "plasma boundary",
    "plasma breakdown",
    "plasma burn",
    "plasma cleaning",
    "plasma column",
    "plasma conditions",
    "plasma configurations",
    "plasma confinement",
    "plasma confinement studies",
    "plasma confinement transport",
    "plasma contained",
    "plasma control",
    "plasma convection",
    "plasma coupling",
    "plasma current",
    "plasma current profile",
    "plasma current quench",
    "plasma current ramp",
    "plasma current start",
    "plasma current startup",
    "plasma currents",
    "plasma cylinder",
    "plasma density",
    "plasma detachment",
    "plasma devices",
    "plasma diagnostics",
    "plasma diffusion",
    "plasma discharge",
    "plasma discharges",
    "plasma disruptions",
    "plasma driven",
    "plasma dynamics",
    "plasma east",
    "plasma east tokamak",
    "plasma edge",
    "plasma edge transport",
    "plasma electron",
    "plasma energy",
    "plasma equilibrium",
    "plasma equilibrium toroidal",
    "plasma experiment",
    "plasma experiments",
    "plasma exposure",
    "plasma facing",
    "plasma facing component",
    "plasma facing components",
    "plasma facing material",
    "plasma facing materials",
    "plasma finite",
    "plasma flow",
    "plasma fluctuations",
    "plasma focus",
    "plasma formation",
    "plasma heating",
    "plasma helical",
    "plasma heliotron",
    "plasma impurities",
    "plasma initiation",
    "plasma instabilities",
    "plasma interaction",
    "plasma interactions",
    "plasma ion",
    "plasma jet",
    "plasma losses",
    "plasma magnetic",
    "plasma magnetic field",
    "plasma material",
    "plasma material interactions",
    "plasma non",
    "plasma operation",
    "plasma oscillations",
    "plasma parameters",
    "plasma performance",
    "plasma phenomena",
    "plasma physics",
    "plasma physics controlled",
    "plasma position",
    "plasma potential",
    "plasma pressure",
    "plasma production",
    "plasma profile",
    "plasma profiles",
    "plasma properties",
    "plasma regimes",
    "plasma relaxation",
    "plasma response",
    "plasma response resonant",
    "plasma rotation",
    "plasma self",
    "plasma shape",
    "plasma shaping",
    "plasma shielding",
    "plasma simulations",
    "plasma stability",
    "plasma start",
    "plasma startup",
    "plasma strong",
    "plasma surface",
    "plasma surface interactions",
    "plasma termination",
    "plasma theory",
    "plasma tokamak",
    "plasma toroidal",
    "plasma transport",
    "plasma tungsten",
    "plasma turbulence",
    "plasma viscosity",
    "plasma wall",
    "plasma wall interaction",
    "plasma wall interactions",
    "plasma wave",
    "plasmas alcator",
    "plasmas alcator mod",
    "plasmas asdex",
    "plasmas asdex upgrade",
    "plasmas diii",
    "plasmas diii tokamak",
    "plasmas doublet",
    "plasmas doublet iii",
    "plasmas east",
    "plasmas east tokamak",
    "plasmas fusion",
    "plasmas heated",
    "plasmas heliotron",
    "plasmas high",
    "plasmas internal",
    "plasmas iter",
    "plasmas jet",
    "plasmas jet ilw",
    "plasmas jt",
    "plasmas jt 60",
    "plasmas jt 60u",
    "plasmas large",
    "plasmas large helical",
    "plasmas lhd",
    "plasmas low",
    "plasmas magnetic",
    "plasmas tcv",
    "plasmas tftr",
    "plasmas toroidal",
    "plate biasing",
    "plt tokamak",
    "point geometry",
    "poloidal asymmetries",
    "poloidal beta",
    "poloidal distribution",
    "poloidal divertor",
    "poloidal divertor experiment",
    "poloidal field",
    "poloidal flow",
    "poloidal magnetic",
    "poloidal magnetic field",
    "poloidal rotation",
    "position control",
    "positive negative",
    "post disruption",
    "post mortem",
    "potential formation",
    "power balance",
    "power density",
    "power deposition",
    "power exhaust",
    "power gain",
    "power handling",
    "power load",
    "power loads",
    "power long",
    "power long pulse",
    "power modulation",
    "power neutral",
    "power operation",
    "power particle",
    "power particle control",
    "power plant",
    "power plants",
    "power plasma",
    "power reactors",
    "power requirements",
    "power supply",
    "power threshold",
    "power threshold studies",
    "power tokamak",
    "power width",
    "pre fusion",
    "pre fusion power",
    "pre ionization",
    "predictive model",
    "predictive transport",
    "preliminary design",
    "presence magnetic",
    "presence resonant",
    "presence resonant magnetic",
    "present status",
    "pressure driven",
    "pressure gradient",
    "pressure plasma",
    "pressure profile",
    "pressure profiles",
    "princeton large",
    "princeton large torus",
    "principles based",
    "principles study",
    "probe measurements",
    "problems fusion",
    "problems fusion research",
    "produced plasmas",
    "production high",
    "products tftr",
    "profile analysis",
    "profile consistency",
    "profile control",
    "profile evolution",
    "profile invariance",
    "profile measurements",
    "profile modification",
    "profile peaking",
    "profile stiffness",
    "profile tokamak",
    "profiles mode",
    "progress development",
    "progress high",
    "progress iter",
    "progress jt",
    "progress jt 60sa",
    "progress long",
    "progress steady",
    "progress steady state",
    "progress understanding",
    "propagation absorption",
    "properties mode",
    "proto cleo",
    "puff imaging",
    "puff modulation",
    "pulsator tokamak",
    "pulse high",
    "pulse mode",
    "pulse operation",
    "pulse operation tore",
    "pulse plasma",
    "pulse propagation",
    "pump limiter",
    "qh mode",
    "quasi axisymmetric",
    "quasi axisymmetric stellarator",
    "quasi coherent",
    "quasi linear",
    "quasi linear theory",
    "quasi optical",
    "quasi single",
    "quasi stationary",
    "quasi steady",
    "quasi steady state",
    "quiescent mode",
    "quiescent mode plasmas",
    "radial diffusion",
    "radial electric",
    "radial electric field",
    "radial electric fields",
    "radial profile",
    "radial transport",
    "radiated power",
    "radiating divertor",
    "radiation damage",
    "radiation loss",
    "radiation losses",
    "radiation plasma",
    "radiative cooling",
    "radiative divertor",
    "radiative power",
    "radio frequency",
    "radiofrequency heating",
    "radius effects",
    "raman scattering",
    "ramp phase",
    "range frequencies",
    "range frequency",
    "rapid shutdown",
    "rate measurements",
    "ratio tokamak",
    "rational surfaces",
    "ray diagnostics",
    "ray emission",
    "ray measurements",
    "ray tomography",
    "ray tracing",
    "rayleigh taylor",
    "rayleigh taylor instability",
    "reaction rate",
    "reactor based",
    "reactor design",
    "reactor ffhr",
    "reactor plasma",
    "reactor relevant",
    "reactor scale",
    "real time",
    "real time control",
    "recent advances",
    "recent experimental",
    "recent experiments",
    "recent hl",
    "recent hl 2a",
    "recent physics",
    "recent progress",
    "recombination spectroscopy",
    "reconnection events",
    "reconstruction tokamak",
    "reduced activation",
    "reduced activation ferritic",
    "reflectometry wendelstein",
    "refrigeration w7",
    "regime asdex",
    "regime asdex upgrade",
    "regime diii",
    "regime east",
    "regime high",
    "regimes alcator",
    "regimes alcator mod",
    "region rfx",
    "reinforcement learning",
    "related plasma",
    "related plasma phenomena",
    "relativistic electron",
    "relativistic electron beams",
    "relativistic plasma",
    "relaxation oscillations",
    "relevant conditions",
    "relevant plasma",
    "reply comment",
    "research development",
    "research programme",
    "research summaries",
    "research support",
    "research support iter",
    "research text",
    "research text tokamak",
    "residual zonal",
    "resistive ballooning",
    "resistive ballooning modes",
    "resistive interchange",
    "resistive internal",
    "resistive internal kink",
    "resistive mhd",
    "resistive modes",
    "resistive shell",
    "resistive tearing",
    "resistive wall",
    "resistive wall mode",
    "resistive wall modes",
    "resistive walls",
    "resolved measurements",
    "resonance heated",
    "resonance heating",
    "resonant field",
    "resonant interaction",
    "resonant magnetic",
    "resonant magnetic field",
    "resonant magnetic perturbation",
    "resonant magnetic perturbations",
    "response resonant",
    "response resonant magnetic",
    "retention tungsten",
    "reverse shear",
    "reversed configuration",
    "reversed configuration plasma",
    "reversed configuration plasmas",
    "reversed configurations",
    "reversed field",
    "reversed field pinch",
    "reversed field pinches",
    "reversed magnetic",
    "reversed magnetic shear",
    "reversed mirror",
    "reversed shear",
    "reversed shear alfvén",
    "reversed shear discharges",
    "reversed shear plasmas",
    "rf current",
    "rf current drive",
    "rf heating",
    "rf sheath",
    "rfc xx",
    "rfx mod",
    "rfx mod experiment",
    "rfx mod reversed",
    "rfx mod tokamak",
    "rfx reversed",
    "rfx reversed field",
    "ripple diffusion",
    "ripple induced",
    "ripple loss",
    "ripple losses",
    "ripple transport",
    "ripple trapped",
    "rmp elm",
    "rmp elm suppression",
    "road fusion",
    "road fusion energy",
    "role current",
    "role ion",
    "role neutral",
    "rotating helical",
    "rotating magnetic",
    "rotating magnetic field",
    "rotating plasma",
    "rotating plasmas",
    "rotating resonant",
    "rotating resonant magnetic",
    "rotating tokamak",
    "rotating tokamak plasma",
    "rotating tokamak plasmas",
    "rotation diii",
    "rotation driven",
    "rotation ion",
    "rotation jet",
    "rotation momentum",
    "rotation profile",
    "rotation reversals",
    "rotation shear",
    "rotation tokamak",
    "rotation toroidal",
    "rotation transport",
    "rtp tokamak",
    "runaway current",
    "runaway discharges",
    "runaway electron",
    "runaway electron beam",
    "runaway electron generation",
    "runaway electron mitigation",
    "runaway electrons",
    "runaway electrons textor",
    "runaway electrons tokamak",
    "runaway electrons toroidal",
    "russian spanish",
    "safety aspects",
    "safety aspects fusion",
    "safety factor",
    "safety factor profile",
    "sawtooth activity",
    "sawtooth collapse",
    "sawtooth control",
    "sawtooth crash",
    "sawtooth crashes",
    "sawtooth induced",
    "sawtooth instability",
    "sawtooth oscillations",
    "sawtooth period",
    "sawtooth reconnection",
    "scale interactions",
    "scale turbulence",
    "scaling confinement",
    "scaling energy",
    "scaling energy confinement",
    "scaling experiments",
    "scaling law",
    "scaling laws",
    "scaling mode",
    "scaling studies",
    "scaling transport",
    "scenario development",
    "scenario iter",
    "scenarios asdex",
    "scenarios asdex upgrade",
    "scenarios diii",
    "scenarios iter",
    "scenarios jet",
    "scrape layer",
    "scrape layer divertor",
    "scrape layer plasma",
    "scrape layer plasmas",
    "scrape layer transport",
    "scrape layer turbulence",
    "scrape layers",
    "screw pinch",
    "second harmonic",
    "second harmonic electron",
    "second international",
    "second stability",
    "second stable",
    "seed island",
    "seeding east",
    "self consistent",
    "self consistent analysis",
    "self organization",
    "self organized",
    "self similar",
    "self sustained",
    "semi empirical",
    "sessions ex",
    "shape control",
    "shaped plasmas",
    "shattered pellet",
    "shattered pellet injection",
    "shear alfvén",
    "shear alfvén eigenmodes",
    "shear discharges",
    "shear flow",
    "shear layer",
    "shear plasmas",
    "shear tokamak",
    "shear tokamak plasmas",
    "sheared flow",
    "sheared magnetic",
    "sheared magnetic field",
    "shell targets",
    "shock ignition",
    "shock wave",
    "short pulse",
    "simple model",
    "simulation elm",
    "simulation energetic",
    "simulation experiments",
    "simulation fast",
    "simulation ion",
    "simulation iter",
    "simulation neutral",
    "simulation plasma",
    "simulation studies",
    "simulation study",
    "simulation tokamak",
    "simulations diii",
    "simulations edge",
    "simulations fast",
    "simulations high",
    "simulations iter",
    "simulations jet",
    "simulations plasma",
    "simulations reversed",
    "simulations tokamak",
    "simulations toroidal",
    "simulations turbulent",
    "simulations turbulent transport",
    "single null",
    "sinp tokamak",
    "skin effect",
    "slot divertor",
    "slot divertor diii",
    "slow wave",
    "small angle",
    "small angle slot",
    "small edge localized",
    "small elm",
    "small fusion",
    "small fusion targets",
    "small scale",
    "snowflake divertor",
    "soft ray",
    "soft ray measurements",
    "soft ray tomography",
    "sol divertor",
    "solenoid free",
    "solid breeder",
    "solid deuterium",
    "solid hydrogen",
    "solps iter",
    "solps iter simulations",
    "space charge",
    "special issue",
    "special issue overview",
    "special section",
    "spectral broadening",
    "spectroscopy jet",
    "spectroscopy measurements",
    "spherical shell",
    "spherical tokamak",
    "spherical tokamak fusion",
    "spherical tokamak globus",
    "spherical tokamak plasma",
    "spherical tori",
    "spherical torus",
    "spherical torus experiment",
    "spherical torus plasmas",
    "spin polarized",
    "st tokamak",
    "stability alfvén",
    "stability alfvén eigenmodes",
    "stability analysis",
    "stability beta",
    "stability boundaries",
    "stability closed",
    "stability diii",
    "stability drift",
    "stability elongated",
    "stability field",
    "stability high",
    "stability high beta",
    "stability inhomogeneous",
    "stability internal",
    "stability iter",
    "stability jet",
    "stability limits",
    "stability non",
    "stability pinch",
    "stability plasma",
    "stability properties",
    "stability rotating",
    "stability rotating plasma",
    "stability spheromak",
    "stability studies",
    "stability study",
    "stability tokamak",
    "stability tokamak plasmas",
    "stability toroidal",
    "stability transport",
    "stabilization axisymmetric",
    "stabilization axisymmetric modes",
    "stabilization ballooning",
    "stabilization ballooning modes",
    "stabilization drift",
    "stabilization electron",
    "stabilization electron cyclotron",
    "stabilization high",
    "stabilization high beta",
    "stabilization ideal",
    "stabilization interchange",
    "stabilization iter",
    "stabilization mhd",
    "stabilization neoclassical",
    "stabilization neoclassical tearing",
    "stabilization resistive",
    "stabilization resistive wall",
    "stabilization tearing",
    "stabilization tearing modes",
    "stabilizing effect",
    "stainless steel",
    "stark effect",
    "start experiments",
    "start phase",
    "start spherical",
    "start spherical tokamak",
    "start tokamak",
    "state advanced",
    "state high",
    "state operation",
    "state operation iter",
    "state plasma",
    "state scenario",
    "state scenarios",
    "state tokamak",
    "state tokamak reactor",
    "states america",
    "static magnetic",
    "stationary high",
    "statistical analysis",
    "steady state",
    "steady state advanced",
    "steady state high",
    "steady state operation",
    "steady state plasma",
    "steady state scenario",
    "steady state scenarios",
    "steady state tokamak",
    "stellarator coil",
    "stellarator coil design",
    "stellarator coils",
    "stellarator configurations",
    "stellarator equilibria",
    "stellarator fields",
    "stellarator heliotron",
    "stellarator magnetic",
    "stellarator plasma",
    "stellarator plasmas",
    "stellarator reactors",
    "stellarator tj",
    "stellarator tj ii",
    "step fusion",
    "stimulated raman",
    "stimulated raman scattering",
    "stochastic layer",
    "stochastic magnetic",
    "stochastic magnetic field",
    "stochasticity fusion",
    "stochasticity fusion plasmas",
    "stor tokamak",
    "stored energy",
    "stream instability",
    "strike point",
    "strong magnetic",
    "strong magnetic field",
    "structural materials",
    "structural materials fusion",
    "structure edge",
    "structure magnetic",
    "studies alcator",
    "studies asdex",
    "studies asdex upgrade",
    "studies diii",
    "studies iter",
    "studies jet",
    "studies nstx",
    "studies plasma",
    "studies tj",
    "studies tj ii",
    "study divertor",
    "study fast",
    "study high",
    "study icrf",
    "study impurity",
    "study ion",
    "study magnetic",
    "study mhd",
    "study particle",
    "study plasma",
    "study radiation",
    "study tokamak",
    "summary iaea",
    "summary iaea workshop",
    "summary sessions",
    "summary sessions ex",
    "super divertor",
    "super mode",
    "superconducting magnet",
    "superconducting tokamak",
    "supersonic molecular",
    "supersonic molecular beam",
    "support iter",
    "suppression magnetic",
    "suppression resonant",
    "suppression resonant magnetic",
    "supra tokamak",
    "suprathermal electron",
    "surface evolution",
    "surface interactions",
    "surface interactions controlled",
    "surface modification",
    "surface morphology",
    "surface temperature",
    "surfaces tokamak",
    "symmetric plasma",
    "symmetry breaking",
    "synchrotron radiation",
    "systems iter",
    "tae instability",
    "tandem mirror",
    "tandem mirror confinement",
    "tandem mirror end",
    "tandem mirror experiment",
    "tandem mirror plasma",
    "tandem mirrors",
    "target design",
    "target plasma",
    "target plate",
    "targets inertial",
    "targets inertial confinement",
    "task force",
    "task force workshop",
    "taylor instability",
    "tca tokamak",
    "tcabr tokamak",
    "tcv plasmas",
    "tcv tokamak",
    "te profile",
    "te profile invariance",
    "te ti",
    "tearing mode",
    "tearing mode stability",
    "tearing mode stabilization",
    "tearing modes",
    "tearing modes asdex",
    "tearing modes tokamak",
    "technical committee",
    "technical committee meeting",
    "technical meeting",
    "technology development",
    "temperature density",
    "temperature dependence",
    "temperature fluctuations",
    "temperature gradient",
    "temperature gradient driven",
    "temperature gradient mode",
    "temperature gradient modes",
    "temperature gradient turbulence",
    "temperature gradients",
    "temperature measurements",
    "temperature plasma",
    "temperature plasmas",
    "temperature profile",
    "temperature profiles",
    "temporal evolution",
    "test blanket",
    "test blanket module",
    "test facility",
    "test reactor",
    "texas experimental",
    "texas experimental tokamak",
    "textor 94",
    "textor ded",
    "textor tokamak",
    "tf ripple",
    "tfr tokamak",
    "tftr dt",
    "tftr experiments",
    "tftr plasmas",
    "tftr supershots",
    "tftr tokamak",
    "theoretical analysis",
    "theoretical model",
    "theoretical study",
    "theory based",
    "theory electromagnetic",
    "theory fast",
    "theory modelling",
    "theory plasma",
    "thermal barrier",
    "thermal barriers",
    "thermal conduction",
    "thermal conductivity",
    "thermal confinement",
    "thermal diffusivity",
    "thermal electron",
    "thermal energy",
    "thermal energy confinement",
    "thermal instability",
    "thermal ion",
    "thermal ions",
    "thermal quench",
    "thermal stability",
    "thermal transport",
    "thermonuclear fusion",
    "thermonuclear plasma",
    "thermonuclear reactor",
    "thermonuclear reactors",
    "theta pinch",
    "theta pinches",
    "thomson scattering",
    "threshold confinement",
    "threshold studies",
    "threshold studies nstx",
    "time control",
    "time dependent",
    "time disruption",
    "time magnetic",
    "time magnetic pumping",
    "time resolved",
    "time scaling",
    "tj ii",
    "tj ii experiments",
    "tj ii plasmas",
    "tj ii stellarator",
    "tj tokamak",
    "tokamak boundary",
    "tokamak confinement",
    "tokamak density",
    "tokamak density limit",
    "tokamak devices",
    "tokamak discharge",
    "tokamak discharges",
    "tokamak disruptions",
    "tokamak divertor",
    "tokamak edge",
    "tokamak edge plasma",
    "tokamak edge plasmas",
    "tokamak energy",
    "tokamak energy confinement",
    "tokamak equilibria",
    "tokamak equilibrium",
    "tokamak experiment",
    "tokamak experimental",
    "tokamak experiments",
    "tokamak fusion",
    "tokamak fusion power",
    "tokamak fusion test",
    "tokamak geometry",
    "tokamak globus",
    "tokamak ignition",
    "tokamak magnetic",
    "tokamak magnetic field",
    "tokamak mode",
    "tokamak modelling",
    "tokamak neutral",
    "tokamak new",
    "tokamak non",
    "tokamak non circular",
    "tokamak operation",
    "tokamak plasma",
    "tokamak plasma edge",
    "tokamak plasmas",
    "tokamak poloidal",
    "tokamak poloidal divertor",
    "tokamak power",
    "tokamak power reactors",
    "tokamak programme",
    "tokamak reactor",
    "tokamak reactors",
    "tokamak research",
    "tokamak scenario",
    "tokamak scenarios",
    "tokamak scrape",
    "tokamak scrape layer",
    "tokamak scrape layers",
    "tokamak stability",
    "tokamak startup",
    "tokamak test",
    "tokamak transport",
    "tokamak turbulence",
    "tokamak upgrade",
    "tokamak varennes",
    "tokamak wall",
    "tomographic reconstruction",
    "tore supra",
    "tore supra tokamak",
    "toroid injection",
    "toroidal alfven",
    "toroidal alfven eigenmodes",
    "toroidal alfvén",
    "toroidal alfvén eigenmode",
    "toroidal alfvén eigenmodes",
    "toroidal current",
    "toroidal devices",
    "toroidal discharges",
    "toroidal effects",
    "toroidal equilibria",
    "toroidal equilibrium",
    "toroidal experiment",
    "toroidal facility",
    "toroidal field",
    "toroidal field coils",
    "toroidal field ripple",
    "toroidal flow",
    "toroidal geometry",
    "toroidal helical",
    "toroidal ion",
    "toroidal ion temperature",
    "toroidal magnetic",
    "toroidal magnetic field",
    "toroidal mode",
    "toroidal modeling",
    "toroidal momentum",
    "toroidal momentum transport",
    "toroidal plasma",
    "toroidal plasma rotation",
    "toroidal plasma viscosity",
    "toroidal plasmas",
    "toroidal poloidal",
    "toroidal rotation",
    "toroidal stellarator",
    "toroidal systems",
    "toroidal velocity",
    "toroidal viscosity",
    "toroidally rotating",
    "toroidicity induced",
    "toroidicity induced alfven",
    "toroidicity induced alfvén",
    "torus ebt",
    "torus experiment",
    "torus experiment nstx",
    "torus plasmas",
    "trace tritium",
    "transient chi",
    "transient heat",
    "transit time",
    "transit time magnetic",
    "transition diii",
    "transition high",
    "transition mode",
    "transition studies",
    "transition threshold",
    "transport alcator",
    "transport alcator mod",
    "transport analysis",
    "transport asdex",
    "transport asdex upgrade",
    "transport barrier",
    "transport barrier formation",
    "transport barrier plasmas",
    "transport barriers",
    "transport calculations",
    "transport caused",
    "transport characteristics",
    "transport code",
    "transport coefficients",
    "transport density",
    "transport diii",
    "transport divertor",
    "transport driven",
    "transport edge",
    "transport effects",
    "transport electron",
    "transport elm",
    "transport energetic",
    "transport equations",
    "transport exhaust",
    "transport fast",
    "transport fusion",
    "transport fusion plasmas",
    "transport high",
    "transport hl",
    "transport hl 2a",
    "transport impurities",
    "transport induced",
    "transport internal",
    "transport internal transport",
    "transport iter",
    "transport jet",
    "transport jt",
    "transport jt 60u",
    "transport large",
    "transport lhd",
    "transport low",
    "transport mast",
    "transport mode",
    "transport mode plasmas",
    "transport model",
    "transport modeling",
    "transport modelling",
    "transport models",
    "transport neutral",
    "transport non",
    "transport ohmically",
    "transport phenomena",
    "transport plasma",
    "transport presence",
    "transport processes",
    "transport properties",
    "transport resonant",
    "transport resonant magnetic",
    "transport reversed",
    "transport reversed field",
    "transport sawtooth",
    "transport scaling",
    "transport scrape",
    "transport scrape layer",
    "transport simulation",
    "transport simulations",
    "transport strong",
    "transport studies",
    "transport study",
    "transport task",
    "transport task force",
    "transport tcv",
    "transport tftr",
    "transport theory",
    "transport tokamak",
    "transport tokamak plasmas",
    "transport toroidal",
    "transport tungsten",
    "transport turbulence",
    "transport working",
    "transport working group",
    "trapped electron",
    "trapped electron mode",
    "trapped electrons",
    "trapped energetic",
    "trapped ion",
    "trapped ion mode",
    "trapped particle",
    "trapped particles",
    "travelling wave",
    "triam 1m",
    "triangularity jet",
    "triangularity plasma",
    "triple product",
    "tritium breeding",
    "tritium experiments",
    "tritium fuel",
    "tritium plasma",
    "tritium plasmas",
    "tritium production",
    "tritium transport",
    "triton burnup",
    "tst spherical",
    "tst spherical tokamak",
    "tuman 3m",
    "tungsten asdex",
    "tungsten asdex upgrade",
    "tungsten deposition",
    "tungsten divertor",
    "tungsten divertor east",
    "tungsten erosion",
    "tungsten exposed",
    "tungsten exposed deuterium",
    "tungsten exposed high",
    "tungsten fusion",
    "tungsten fuzz",
    "tungsten impurity",
    "tungsten irradiated",
    "tungsten plasma",
    "tungsten plasma facing",
    "tungsten surface",
    "tungsten surfaces",
    "tungsten transport",
    "tungsten wall",
    "turbulence caltech",
    "turbulence caltech tokamak",
    "turbulence characteristics",
    "turbulence driven",
    "turbulence edge",
    "turbulence magnetic",
    "turbulence simulations",
    "turbulence spreading",
    "turbulence studies",
    "turbulence suppression",
    "turbulence tokamak",
    "turbulence transport",
    "turbulent fluctuations",
    "turbulent heating",
    "turbulent particle",
    "turbulent particle transport",
    "turbulent plasma",
    "turbulent plasmas",
    "turbulent transport",
    "type edge",
    "type edge localized",
    "type elm",
    "type elms",
    "type elmy",
    "type elmy mode",
    "type ii",
    "type iii",
    "ultra low",
    "uniform magnetic",
    "uniform magnetic field",
    "uniform plasma",
    "united kingdom",
    "united states",
    "united states america",
    "upgrade jet",
    "upgrade mode",
    "upgrade mode discharges",
    "upgrade mode plasmas",
    "upgrade modes",
    "upgrade ohmic",
    "upgrade plasmas",
    "upgrade tokamak",
    "vacuum magnetic",
    "vacuum vessel",
    "velocity distribution",
    "velocity distributions",
    "velocity shear",
    "velocity space",
    "vertical displacement",
    "vertical displacement events",
    "vertical instability",
    "vertical plasma",
    "vertical plasma position",
    "vertical position",
    "vertical stability",
    "view iter",
    "vii stellarator",
    "viscosity torque",
    "volt second",
    "volume recombination",
    "w7 divertor",
    "w7 island",
    "w7 island divertor",
    "w7 stellarator",
    "wall conditioning",
    "wall conditions",
    "wall interaction",
    "wall interactions",
    "wall jet",
    "wall mode",
    "wall mode feedback",
    "wall mode stability",
    "wall modes",
    "wall modes iter",
    "wall plasma",
    "wall stabilization",
    "wall stabilized",
    "wall tokamak",
    "water cooled",
    "wave absorption",
    "wave antenna",
    "wave coupling",
    "wave current",
    "wave current drive",
    "wave driven",
    "wave heating",
    "wave instability",
    "wave particle",
    "wave plasma",
    "wave propagation",
    "wave transport",
    "wave turbulence",
    "waveguide antennas",
    "waveguide array",
    "waves jet",
    "waves lower",
    "waves lower hybrid",
    "waves plasma",
    "waves presence",
    "waves presence magnetic",
    "waves tokamak",
    "waves tokamak plasmas",
    "weak magnetic",
    "weak magnetic shear",
    "weakly coherent",
    "weakly coherent mode",
    "weakly collisional",
    "wendelstein stellarator",
    "wendelstein vii",
    "west tokamak",
    "wide pedestal",
    "working group",
    "working group aptwg",
    "wt tokamak",
    "years fusion",
    "years fusion research",
    "zero dimensional",
    "zonal flow",
    "zonal flow generation",
    "ηi mode",
];
