// fetchUserAttributes.ts

import { fetchUserAttributes as amplifyFetchUserAttributes } from "@aws-amplify/auth";
import { CognitoUser } from "../../dataclass/CognitoUser";

async function FetchCognitoInfo(): Promise<CognitoUser> {
    // Cognitoからユーザー属性を取得し、それをCognitoUserオブジェクトに変換して返す
    try {
        const currentUserAttributes = await amplifyFetchUserAttributes();
        const user = new CognitoUser({
            sub: currentUserAttributes.sub,
            bio: currentUserAttributes["custom:bio"],
            occupation: currentUserAttributes["custom:occupation"],
            trackedKeywords: currentUserAttributes["custom:trackedKeywords"]
                ? currentUserAttributes["custom:trackedKeywords"].split(",")
                : [],
            favoritePapers: currentUserAttributes["custom:favorite_papers"]
                ? currentUserAttributes["custom:favorite_papers"].split(",")
                : [],
            bookmarkPapers: currentUserAttributes["custom:bookmark_papers"]
                ? currentUserAttributes["custom:bookmark_papers"].split(",")
                : [],
            checkedPapers: currentUserAttributes["custom:checked_papers"]
                ? currentUserAttributes["custom:checked_papers"].split(",")
                : [],
        });

        return user;
    } catch (error) {
        console.error("Error fetching user attributes:", error);
        throw error;
    }
}

export { FetchCognitoInfo };
