import DeviceDetailsTemplate from "../templates/DeviceDetailsTemplate";
import React from "react";

const DeviceDetailsPage: React.FC = () => {
    return (
        <>
            <DeviceDetailsTemplate />
        </>
    );
};

export default DeviceDetailsPage;
