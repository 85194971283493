class MonthlyJournalReportClass {
    id: number;
    title: string;
    description: string;
    publish_year: number;
    publish_month: number;
    journal_id: number;
    language: string;
    total_papers: number;
    url: string;

    constructor(
        id: number,
        title: string,
        description: string,
        publish_year: number,
        publish_month: number,
        journal_id: number,
        language: string,
        total_papers: number,
        url: string
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.publish_year = publish_year;
        this.publish_month = publish_month;
        this.journal_id = journal_id;
        this.language = language;
        this.total_papers = total_papers;
        this.url = url;
    }
}

export default MonthlyJournalReportClass;
