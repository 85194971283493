import { useState, useEffect } from "react";
import regularPaper from "../../../dataclass/regularPaper";

const useKeywordFilter = (originalResults: regularPaper[]) => {
    // キーワードを保持するためのstateを作成
    const [keyword, setKeyword] = useState("");

    // 入力されたキーワードに基づいて結果をフィルタリングする関数を作成
    const filterResults = (keyword: string) => {
        let filteredResults = [...originalResults].filter((result) => result.title.includes(keyword));
        return filteredResults;
    };

    // useEffectを使用して、キーワードが変更されたときにフィルタリングを行うようにする
    useEffect(() => {
        if (keyword) {
            filterResults(keyword);
        }
        // eslint-disable-next-line
    }, [keyword]);

    return { keyword, setKeyword, filterResults };
};

export default useKeywordFilter;
