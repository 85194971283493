import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// プロジェクト内のファイルからのインポート: バックエンド
import SearchResultPresenter from "../../backend/presenters/SearchResultPresenter";
import regularPaper from "../../dataclass/regularPaper";

// プロジェクト内のファイルからのインポート: コンポーネント
import LoadingScreen from "../molecules/LoadingScreen";
import NoResults from "../molecules/NoResults";
import AnalyzeResults from "../molecules/AnalyzeResults";

// プロジェクト内のファイルからのインポート: フック
import usePublisherFilter from "../hooks/search_filter/usePublisherFilter";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SearchResultsBox: React.FC = () => {
    const query = useQuery().get("query") || "";
    // 状態変数を追加
    const [loading, setLoading] = useState(false);
    const [fetchInProgress, setFetchInProgress] = useState(false);

    const [queryResults, setQueryResults] = useState<regularPaper[]>([]);
    // フィルタリング前のデータを保持するためのstateを作成
    const [originalResults, setOriginalResults] = useState<regularPaper[]>([]);

    useEffect(() => {
        let isMounted = true; // マウントされているかどうかのフラグ
        if (query !== null && query !== undefined) {
            setLoading(true); // 読み込み開始

            const fetchData = async (limit: number | null, offset: number | null) => {
                const results = await SearchResultPresenter(query, limit, offset);
                if (offset === null) {
                    // 初回取得時はデータをセット
                    if (isMounted) {
                        setQueryResults(results);
                        setOriginalResults(results);
                        setLoading(false);
                    }
                } else {
                    // 追加データ取得時は既存データに追加
                    if (isMounted) {
                        setQueryResults((prevResults) => [...prevResults, ...results]);
                        setOriginalResults((prevResults) => [...prevResults, ...results]);
                    }
                }
            };

            const fetchInSteps = async () => {
                setFetchInProgress(true);
                await fetchData(50, null);
                await fetchData(-1, 50);
                setFetchInProgress(false);
            };

            fetchInSteps();

            return () => {
                isMounted = false; // クリーンアップでフラグを更新
                setLoading(false); // ローディング状態をクリア
            };
        }
    }, [query]);

    /***********
     *フィルター機能(出版社)
     **********/
    const { uniquePublishers, selectedPublishers, handlePublisherChange, sortedPublisherCounts } =
        usePublisherFilter(queryResults);

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : originalResults.length === 0 ? (
                <NoResults query={query} />
            ) : (
                <div className="bg-gray-100 flex justify-center">
                    <div className="w-full sm:w-11/12">
                        {fetchInProgress && (
                            <div className="flex items-center justify-center my-4">
                                <CircularProgress size={20} className="mr-2" />
                                <Typography variant="body1">Loading more data...</Typography>
                            </div>
                        )}
                        <AnalyzeResults
                            query={query}
                            queryResults={queryResults}
                            sortedPublisherCounts={sortedPublisherCounts}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default SearchResultsBox;
