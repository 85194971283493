import { styled } from "@mui/system";
import { Card, Avatar } from "@mui/material";
import { Theme } from "@mui/material/styles";

export const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.2)",
    transition: "0.3s",
    "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
}));

export const LargeAvatar = styled(Avatar)(({ theme }: { theme: Theme }) => ({
    width: theme.spacing(20),
    height: theme.spacing(20),
    marginBottom: theme.spacing(3),
    border: `4px solid ${theme.palette.background.paper}`,
    boxShadow: theme.shadows[3],
}));

export const SmallAvatar = styled(Avatar)(({ theme }: { theme: Theme }) => ({
    width: theme.spacing(5),
    height: theme.spacing(5),
    border: `4px solid ${theme.palette.background.paper}`,
    boxShadow: theme.shadows[3],
}));
