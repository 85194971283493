import React, { useState } from "react";
import ProfileBox from "../organisms/ProfileBox";
import TrackedKeywordsBox from "../organisms/TrackedKeywordsBox";
import MyDashboardBox from "../organisms/MyDashboardBox";
import HeaderBar from "../organisms/HeaderBar";
import Footer from "../organisms/Footer";
import { MainPageProps } from "../organisms/types";
import { PlusIcon, MinusIcon, XIcon } from "lucide-react";

const MyPageTemplate: React.FC<MainPageProps> = ({ signOut, user }) => {
    const [isProfileBoxVisible, setIsProfileBoxVisible] = useState(false);

    const toggleProfileBox = () => {
        setIsProfileBoxVisible(!isProfileBoxVisible);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <HeaderBar />
            <main className="flex-grow container mx-auto">
                <div className="flex flex-col lg:flex-row">
                    {/* MyDashboardBox */}
                    <div className="lg:w-2/3 w-full mx-auto">
                        <MyDashboardBox user={user} signOut={signOut} />
                    </div>
                </div>

                {/* ProfileBox Toggle Button */}
                <button
                    className="fixed bottom-4 right-4 bg-blue-500 text-white p-3 rounded-full shadow-lg z-50 hover:bg-blue-600 transition-colors"
                    onClick={toggleProfileBox}
                    aria-label={isProfileBoxVisible ? "Hide Profile" : "Show Profile"}
                >
                    {isProfileBoxVisible ? <MinusIcon size={24} /> : <PlusIcon size={24} />}
                </button>

                {/* ProfileBox */}
                <div
                    className={`fixed inset-0 bg-white z-40 overflow-auto transition-transform duration-300 ease-in-out ${
                        isProfileBoxVisible ? "translate-x-0" : "translate-x-full"
                    }`}
                >
                    <div className="p-6">
                        <button
                            onClick={toggleProfileBox}
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                            aria-label="Close Profile"
                        >
                            <XIcon size={24} />
                        </button>
                        <TrackedKeywordsBox user={user} signOut={signOut} />
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default MyPageTemplate;
