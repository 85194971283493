import MyJournal from "../gateways/MyJournal";
import JournalClass from "../../dataclass/JournalClass";
import FetchJournalData from "../gateways/FetchJournalData";
import processJournalData from "../entity/processJournalData";
import mapJournalNameToAbbreviation from "../entity/mapJournalNameToAbbreviation";

class JournalManeger {
    getJournal(): JournalClass[] {
        return MyJournal();
    }

    async getJournalDetailsInfo(fileName: string) {
        // 装置詳細画面に表示する情報を返します
        const JournalName = mapJournalNameToAbbreviation(fileName);
        const journal_data = await FetchJournalData(JournalName);
        const evolution_of_document_count_data = processJournalData(journal_data);

        return {
            journal_data: journal_data,
            evolution_of_document_count_data: evolution_of_document_count_data,
        };
    }
}

export default JournalManeger;
