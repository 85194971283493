import LLMSummarizedPaperTemplate from "../templates/LLMSummarizedPaperTemplate";
import React from "react";

const LLMSummarizedPaperPage: React.FC = () => {
    return (
        <>
            <LLMSummarizedPaperTemplate />
        </>
    );
};

export default LLMSummarizedPaperPage;
