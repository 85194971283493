import DeviceManeger from "../usecase/DeviceManeger";
import DeviceClass from "../../dataclass/DeviceClass";

class DevicePresenter {
    private manager: DeviceManeger;

    constructor() {
        this.manager = new DeviceManeger();
    }

    public getDevices(): DeviceClass[] {
        return this.manager.getDevice();
    }
}

export default DevicePresenter;
