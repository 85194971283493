import DeviceManeger from "../usecase/DeviceManeger";
import DeviceClass from "../../dataclass/DeviceClass";

interface DeviceDetailsBOXPresenterResult {
    device: DeviceClass[];
    device_data: any;
    evolution_of_document_count_data: any;
}

/**
 * `DeviceDetailsBOXPresenter`は、指定されたファイル名に対応するデバイスの詳細情報を取得します。
 *
 * @param fileName - デバイスデータを取得するためのファイル名
 * @returns Promise<DeviceDetailsBOXPresenterResult> - デバイスとその詳細情報、および文書数の進化データを含むオブジェクトを返します
 *
 * @example
 *
 * const result = await DeviceDetailsBOXPresenter('device1.json');
 * console.log(result.device); // デバイス情報を表示
 * console.log(result.device_data); // デバイスの詳細情報を表示
 * console.log(result.evolution_of_document_count_data); // 文書数の進化データを表示
 */
async function DeviceDetailsBOXPresenter(fileName: string): Promise<DeviceDetailsBOXPresenterResult> {
    const deviceManager = new DeviceManeger();
    const DeviceDetailsInfo = await deviceManager.getDeviceDetailsInfo(fileName);

    return {
        device: deviceManager.getDevice(),
        device_data: DeviceDetailsInfo.device_data,
        evolution_of_document_count_data: DeviceDetailsInfo.evolution_of_document_count_data,
    };
}

export default DeviceDetailsBOXPresenter;
