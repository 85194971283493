import { CognitoUser } from "../../dataclass/CognitoUser";
import LLMSummarizedPaperWithJournal from "../../dataclass/LLMSummarizedPaperWithJournal";

async function FetchLLMSummarizedPaperWithJournal(CognitoUser: CognitoUser): Promise<LLMSummarizedPaperWithJournal[]> {
    // CognitoからTracked keywordsを受け取り、LLM_summarized_paper_with_journalを検索する

    const trackedKeywords: string[] = CognitoUser.trackedKeywords;

    const fetchResults = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/LLM_summarized_paper_with_journal`, {
            method: "POST",
            headers: {
                "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                "Content-Type": "application/json",
                origin: window.location.origin,
            },
            body: JSON.stringify({ trackedKeywords }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        const data = JSON.parse(text);

        return data;
    };

    const response = await fetchResults().catch((e) => {
        console.error("Error:", e);
        return [];
    });

    const papers = response.papers;

    return papers.map((item: LLMSummarizedPaperWithJournal) => {
        return new LLMSummarizedPaperWithJournal(
            item.id,
            item.name,
            item.desc,
            item.image,
            item.impact_factor,
            item.citescore,
            item.official_site,
            item.title,
            item.summary,
            item.target,
            item.hashtag,
            item.language,
            item.journal_monthly_report_id,
            item.paper_url
        );
    });
}

export default FetchLLMSummarizedPaperWithJournal;
