export const OCCUPATIONS: string[] = [
    "Undergraduate Student",
    "Master's Student",
    "Ph.D. Student",
    "Postdoctoral Researcher",
    "Assistant Professor",
    "Associate Professor",
    "Professor",
    "Industry Professional (Fusion)",
    "Industry Professional (Non-Fusion)",
];
