import React from "react";
import { Authenticator, useAuthenticator, CheckboxField } from "@aws-amplify/ui-react";
import "./withCustomAuthenticator.css";

// Custom HOC
function withCustomAuthenticator(WrappedComponent: any) {
    return function AuthenticatedComponent(props: any) {
        return (
            <Authenticator
                // Default to Sign Up screen
                initialState="signUp"
                // Customize `Authenticator.SignUp.FormFields`
                components={{
                    SignUp: {
                        FormFields() {
                            const { validationErrors } = useAuthenticator();

                            return (
                                <>
                                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                                    <Authenticator.SignUp.FormFields />

                                    {/* Append & require Terms and Conditions field to sign up  */}
                                    <CheckboxField
                                        errorMessage={validationErrors.acknowledgement as string}
                                        hasError={!!validationErrors.acknowledgement}
                                        name="acknowledgement"
                                        value="yes"
                                        label={
                                            <span>
                                                I agree with{" "}
                                                <a
                                                    href="/legal"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="link"
                                                >
                                                    the Terms of Service and the Privacy Policy.
                                                </a>
                                            </span>
                                        }
                                    />
                                </>
                            );
                        },
                    },
                }}
                services={{
                    async validateCustomSignUp(formData) {
                        if (!formData.acknowledgement) {
                            return {
                                acknowledgement: "You must agree to the Terms and Conditions",
                            };
                        }
                    },
                }}
            >
                {({ signOut, user }) => <WrappedComponent {...props} user={user} signOut={signOut} />}
            </Authenticator>
        );
    };
}

export default withCustomAuthenticator;
