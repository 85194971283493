// 有名な色のリスト
const colors = [
    "rgb(54, 162, 235)", // Blue
    "rgb(255, 205, 86)", // Yellow
    "rgb(75, 192, 192)", // Green
    "rgb(153, 102, 255)", // Purple
    "rgb(255, 159, 64)", // Orange
    "rgb(255, 99, 255)", // Pink
    "rgb(255, 255, 99)", // Light Yellow
    "rgb(99, 255, 132)", // Light Green
    "rgb(132, 99, 255)", // Light Purple
    "rgb(255, 0, 0)", // Red
    "rgb(0, 255, 0)", // Lime
    "rgb(0, 0, 255)", // Dark Blue
    "rgb(0, 255, 255)", // Cyan
    "rgb(255, 0, 255)", // Magenta
    "rgb(192, 192, 192)", // Silver
    "rgb(128, 128, 128)", // Gray
    "rgb(128, 0, 0)", // Maroon
    "rgb(128, 128, 0)", // Olive
    "rgb(0, 128, 0)", // Dark Green
];

type CountPerPublisherPerYearType = {
    [publisher: string]: {
        [year: string]: number;
    };
};

type EvolutionOfDocumentCountDataType = {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string;
        fill: boolean;
    }[];
};

function processDeviceData(device_data: any): EvolutionOfDocumentCountDataType {
    let countPerPublisherPerYear: CountPerPublisherPerYearType = {};
    let evolution_of_document_count_data: EvolutionOfDocumentCountDataType = {
        labels: [],
        datasets: [],
    };

    let years: string[] = [];

    if (device_data && device_data.paper_counts_on_all_journal_per_year) {
        years = Object.keys(device_data.paper_counts_on_all_journal_per_year).sort((a, b) => Number(a) - Number(b));

        Object.entries(device_data.paper_counts_on_all_journal_per_year).forEach(([year, publishersData]) => {
            Object.entries(publishersData as { [key: string]: any }).forEach(([publisher, count]) => {
                if (!countPerPublisherPerYear[publisher]) {
                    countPerPublisherPerYear[publisher] = {};
                }
                countPerPublisherPerYear[publisher][year] = count;
            });
        });

        const dataPerPublisher = Object.entries(countPerPublisherPerYear).map(([publisher, yearsData], index) => {
            const data = years.map((year) => yearsData[year] || 0);
            return {
                label: publisher,
                data: data,
                backgroundColor: colors[index],
                fill: true,
            };
        });

        evolution_of_document_count_data = {
            labels: years,
            datasets: dataPerPublisher,
        };
    }

    return evolution_of_document_count_data;
}

export default processDeviceData;
