import MySettingsTemplate from "../templates/MySettingsTemplate";
import withCustomAuthenticator from "./withCustomAuthenticator";
import React from "react";
import { MainPageProps } from "../organisms/types";

const MySettingsPage: React.FC<MainPageProps> = ({ signOut, user }) => {
    return (
        <>
            <MySettingsTemplate user={user} signOut={signOut} />
        </>
    );
};

export default withCustomAuthenticator(MySettingsPage);
