import JournalClass from "../../dataclass/JournalClass";

function MyJournal(): JournalClass[] {
    let temp = [
        {
            id: 1,
            name: "Nuclear Fusion",
            desc: "Nuclear Fusion is the acknowledged world-leading journal specializing in fusion. The journal covers all aspects of research, theoretical and practical, relevant to controlled thermonuclear fusion.",
            image: "https://cms.iopscience.org/fc6ddb86-ec1e-11e5-b0b6-759f86a2008e/journal_cover?guest=true",
            impact_factor: 3.0,
            Citescore: 3.2,
            official_site: "https://iopscience.iop.org/journal/0029-5515",
        },
        {
            id: 2,
            name: "Plasma Physics and Controlled Fusion",
            desc: "Plasma Physics and Controlled Fusion is a monthly publication dedicated to the dissemination of original results on all aspects of plasma physics and associated science and technology.",
            image: "https://cms.iopscience.org/04d2e770-ec1f-11e5-b0b6-759f86a2008e/journal_cover?guest=true",
            impact_factor: 2.2,
            Citescore: 4.5,
            official_site: "https://iopscience.iop.org/journal/0741-3335",
        },
        {
            id: 3,
            name: "Fusion Engineering and Design",
            desc: "An International Journal for Fusion Energy and Technology devoted to Experiments, Theory, Methods and Design",
            image: "https://ars.els-cdn.com/content/image/X09203796.jpg",
            impact_factor: 1.7,
            Citescore: 3.7,
            official_site: "https://www.sciencedirect.com/journal/fusion-engineering-and-design",
        },
        {
            id: 4,
            name: "PHYSICAL REVIEW LETTERS",
            desc: "Physical Review Letters (PRL) is the world’s premier physics letter journal and the American Physical Society’s flagship publication.",
            image: "https://research.unl.edu/wp-content/uploads/2016/03/PRL-Cover-9-11-15-950x1147.jpg",
            impact_factor: 8.6,
            Citescore: 16.8,
            official_site: "https://journals.aps.org/prl/",
        },
        {
            id: 5,
            name: "Review of Scientific Instruments",
            desc: "Review of Scientific Instruments publishes novel advancements in scientific instrumentation, apparatuses, techniques of experimental measurement, and related mathematical analysis. Its content includes publication of regular and in-depth review articles on instruments covering all areas of science including physics, chemistry, and biology.",
            image: "https://aipp.silverchair-cdn.com/aipp/content_public/journal/rsi/issue/95/4/1/m_rsi.2024.95.issue-4.largecover.jpeg?Expires=1717906750&Signature=lGAQXxatPE4dpEmLbAqmEpFie3EcGiffqa53hAppUiCg17E4ezy5MHIXXIM2KMdNaQbrcXxVzlWMOm-yYW~kvBgc2ZHkPov93FBBhxZeQAnI8ZkY2aL-LxYXCSC8j~Ls8xUw7KF6T3fCCAaTzv6dJF3Xxm1moSlgH2ypuIzN2kVT7dYarhGCo1eaWs~1EaPZyUlf63ZEWX~wlsSVCuM-jHnDO34EZ5JAjdUbnkY5M7oiHwDbujy~jJ6xDa9v~CEQyPlhUQeDSrACM0xskYRtu1v0igbf2fCtYA9b7vmYgPSRaQL8ALUGCqCjPJUGrTBtZyeXhTfaUQEdH6njfGF6aw__&Key-Pair-Id=APKAIE5G5CRDK6RD3PGA",
            impact_factor: 1.6,
            Citescore: 3.1,
            official_site: "https://pubs.aip.org/aip/rsi",
        },
        {
            id: 6,
            name: "Physics of Plasmas",
            desc: "Physics of Plasmas, published by AIP Publishing in cooperation with the APS Division of Plasma Physics, is committed to the publication of original research in all areas of experimental, computational, and theoretical plasma physics. Physics of Plasmas publishes in-depth review manuscripts, forward-looking perspectives, Tutorials on active topics, and Special Topics highlighting new developments. Every year a special issue publishes the invited and review papers from the most recent meeting of the APS Division of Plasma Physics.",
            image: "https://aipp.silverchair-cdn.com/aipp/content_public/journal/pop/issue/31/4/1/m_php.2024.31.issue-4.largecover.jpeg?Expires=1717907354&Signature=Zbfz7D5Iz3NU8VcpthIqyMyWiaIMyiahX2DMA~AsQJltUPoIDCQfHW6PZqBO6UNtrsA3NJFLgvAE4IvBu~~KVGnRPoPCEc84Yks8XOROit77au9FasdxPEjDpg~ddwumH-Olf8WlRi9jUgj~zaf4GJrgH~FqJAIGu9ygj6OylLZbAzo2jOhGHQ2LgfyM4YSHKGkPQOJ8w5YMhBgDPk1aCpwHz9xpsF-KV74Z8YKw3Hi-WX7WippavoYrzbQKHRwCde0opflmLgTV36WdkRPgHPYu4iYS6mCiI9BVdIufkDeQuGtaXNy0ObDdRMiE4UOmjUnXqJBFnbj1Bx7pQ9w3QQ__&Key-Pair-Id=APKAIE5G5CRDK6RD3PGA",
            impact_factor: 2.2,
            Citescore: 4.1,
            official_site: "https://pubs.aip.org/aip/pop",
        },
    ];

    return temp.map(
        (item) =>
            new JournalClass(
                item.id,
                item.name,
                item.desc,
                item.image,
                item.impact_factor,
                item.Citescore,
                item.official_site
            )
    );
}

export default MyJournal;
