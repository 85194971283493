class regularPaper {
    id: number;
    title: string;
    author: string[];
    abstract: string;
    url: string;
    favicon: string;
    year: number;
    publisher: string;
    distance: number;
    order: number;
    cited: number;

    constructor(
        id: number,
        title: string,
        author: string[],
        abstract: string,
        url: string,
        favicon: string,
        year: number,
        publisher: string,
        distance: number,
        order: number,
        cited: number
    ) {
        this.id = id;
        this.title = title;
        this.author = author;
        this.abstract = abstract;
        this.url = url;
        this.favicon = favicon;
        this.year = year;
        this.publisher = publisher;
        this.distance = distance;
        this.order = order;
        this.cited = cited;
    }
}

export default regularPaper;
