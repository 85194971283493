import React from "react";
import HeaderBar from "../organisms/HeaderBar";
import LLMSummarizedPaperBOX from "../organisms/LLMSummarizedPaperBOX";

const LLMSummarizedPaperTemplate: React.FC = () => {
    return (
        <div>
            <div>
                <HeaderBar />
            </div>
            <div>
                <LLMSummarizedPaperBOX />
            </div>
        </div>
    );
};

export default LLMSummarizedPaperTemplate;
