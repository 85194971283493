import React, { useState } from "react";
import { styled } from "@mui/system";
import Cropper, { Area, MediaSize } from "react-easy-crop";
import { ASPECT_RATIO, CROP_WIDTH } from "./ProfileBox";
import { Container, CircularProgress, Button, Modal, Slider, Box } from "@mui/material";

type Props = {
    crop: {
        x: number;
        y: number;
    };
    setCrop: (crop: { x: number; y: number }) => void;
    zoom: number;
    setZoom: (zoom: number) => void;
    onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void;
    open: boolean;
    onClose: () => void;
    imgSrc: string;
    showCroppedImage: () => void;
    onMediaLoaded: (mediaSize: MediaSize) => void;
    minZoom: number;
};

const ModalContainer = styled(Box)({
    width: 420,
    height: 500,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "0px 0px 10px 10px",
});

const CropContainer = styled(Box)({
    height: 400,
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#f4f7fb",
    position: "relative",
    border: "3px solid #00A0FF",
});

const Controls = styled(Box)({
    height: 40,
    marginLeft: 50,
    marginRight: 50,
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    color: "#00A0FF",
});

const Buttons = styled(Box)({
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: 90,
    marginLeft: 90,
    marginBottom: 10,
});

const CropperModal: React.FC<Props> = ({
    crop,
    setCrop,
    onCropComplete,
    setZoom,
    zoom,
    open,
    onClose,
    imgSrc,
    showCroppedImage,
    onMediaLoaded,
    minZoom,
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleButtonClick = () => {
        setIsLoading(true);
        showCroppedImage();
        // クロップ処理が完了したら、setIsLoading(false)を呼び出してLoading画面を非表示にする
    };
    return (
        <Modal open={open} onClose={onClose}>
            <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
                <ModalContainer>
                    {isLoading ? (
                        <Container maxWidth="sm">
                            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                                <CircularProgress />
                            </Box>
                        </Container>
                    ) : (
                        <>
                            <CropContainer>
                                <Cropper
                                    image={imgSrc}
                                    crop={crop}
                                    zoom={zoom}
                                    minZoom={minZoom}
                                    maxZoom={minZoom + 3}
                                    aspect={ASPECT_RATIO}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                    cropSize={{
                                        width: CROP_WIDTH,
                                        height: CROP_WIDTH / ASPECT_RATIO,
                                    }}
                                    classes={{
                                        containerClassName: "container",
                                        cropAreaClassName: "crop-area",
                                        mediaClassName: "media",
                                    }}
                                    onMediaLoaded={onMediaLoaded}
                                    showGrid={false}
                                />
                            </CropContainer>
                            <Controls>
                                <Slider
                                    value={zoom}
                                    min={minZoom}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => setZoom(zoom as number)}
                                />
                            </Controls>
                            <Buttons>
                                <Button onClick={onClose} style={{ backgroundColor: "gray", color: "#fff" }}>
                                    Close
                                </Button>
                                <Button
                                    onClick={handleButtonClick}
                                    style={{ backgroundColor: "#00A0FF", color: "#fff" }}
                                >
                                    OK
                                </Button>
                            </Buttons>
                        </>
                    )}
                </ModalContainer>
            </Box>
        </Modal>
    );
};
export default CropperModal;
