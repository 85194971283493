import LLMSummarizedPaperWithJournal from "../../dataclass/LLMSummarizedPaperWithJournal";
import dashboardManger from "../usecase/dashboardManger";

/**
 * ダッシュボードの通常の論文を表示するためのプレゼンター関数。
 * @returns {Promise<TechnicalTermClass[]>} 検索結果のTechnicalTermClassオブジェクトの配列を含むPromise。
 */
function dashBoardLLMSummarizedPaperPresenter(): Promise<LLMSummarizedPaperWithJournal[]> {
    const manager = new dashboardManger();
    return manager.getLLMSummarizedPaperWithJournal();
}

export default dashBoardLLMSummarizedPaperPresenter;
