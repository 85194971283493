import { useEffect, useRef } from "react";
import { Network } from "vis-network";
import { DataSet } from "vis-data";

interface Props {
    authorList: string[][];
}

const NetworkGraph: React.FC<Props> = ({ authorList }) => {
    // ノードとエッジを格納するためのセットを作成
    const coAuthorNodesSet = new Set<string>();
    const coAuthorEdgesSet = new Set<string>();

    authorList.forEach((coAuthors, paperIndex) => {
        coAuthors.forEach((author, index) => {
            coAuthorNodesSet.add(author);
            for (let j = index + 1; j < coAuthors.length; j++) {
                const edgeId = [author, coAuthors[j]].sort().join("_");
                coAuthorEdgesSet.add(edgeId);
            }
        });
    });

    // ノードとエッジを配列に変換
    const coAuthorNodes = Array.from(coAuthorNodesSet).map((author, index) => ({
        id: `coAuthor_${index}`,
        label: author,
        shape: "ellipse",
        url: `https://scholar.google.co.jp/citations?hl=en&view_op=search_authors&mauthors=${author}`,
    }));

    const coAuthorEdges = Array.from(coAuthorEdgesSet).map((edgeId, index) => {
        const [from, to] = edgeId.split("_");
        return {
            id: `edge_${index}`,
            from: coAuthorNodes.find((node) => node.label === from)?.id ?? "",
            to: coAuthorNodes.find((node) => node.label === to)?.id ?? "",
        };
    });

    // ノードとエッジをデータセットにまとめる
    const initialData = {
        nodes: coAuthorNodes,
        edges: coAuthorEdges,
    };

    let network: any;
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const nodes = new DataSet(initialData.nodes);
        const edges = new DataSet(initialData.edges);

        const options = {
            physics: {
                barnesHut: {
                    gravitationalConstant: -1000,
                    springLength: 50,
                    springConstant: 0.02,
                },
            },
            interaction: {
                multiselect: false,
            },
        };

        // Network が存在しない場合の処理
        if (!network && ref.current) {
            network = new Network(
                ref.current,
                {
                    nodes: nodes,
                    edges: edges,
                },
                options
            );
        }

        network.on("click", (params: { nodes: number[] }) => {
            if (params.nodes.length > 0) {
                const nodeId = params.nodes[0];
                const node = nodes.get(nodeId);
                if (node?.url) {
                    window.open(node.url, "_blank");
                }
            }
        });
    }, []);

    return (
        <div>
            <div style={{ height: 800, width: "100%" }} ref={ref} />
        </div>
    );
};

export default NetworkGraph;
