import React from "react";

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-gray-100 py-4">
            <div className="container mx-auto text-center text-gray-600 flex justify-center items-center space-x-4">
                <p>&copy; {currentYear} FusionPapers. All rights reserved.</p>
                <p>
                    <a href="/legal" className="text-blue-500 hover:underline">
                        Terms of use
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
