import { useState, useEffect } from "react";
import { updateUserAttributes, fetchUserAttributes } from "@aws-amplify/auth";

export const useProfile = (user: any) => {
    const [subid, setSubid] = useState<string | null>(null);
    const [bio, setBio] = useState<string>("");
    const [newBio, setNewBio] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [trackedKeywords, setTrackedKeywords] = useState<string | null>(null);
    const [occupation, setOccupation] = useState<string | null>(null);
    const [lang, setLang] = useState<string | null>(null);
    const [newOccupation, setNewOccupation] = useState<string | null>(null);

    useEffect(() => {
        const authenticateAndGetSubId = async () => {
            try {
                setIsLoading(true);
                const currentUserAttributes = await fetchUserAttributes();
                const attributes = currentUserAttributes;
                const picture = attributes.picture;
                setOccupation(attributes["custom:occupation"] || "");
                setNewOccupation(attributes["custom:occupation"] || "");
                setTrackedKeywords(attributes["custom:trackedKeywords"] || "");
                setLang(attributes["custom:lang"] || "");
                setImageUrl(picture ?? null);
                setSubid(attributes.sub ?? null);
                setBio(attributes["custom:bio"] || "");
                setNewBio(attributes["custom:bio"] || "");
            } catch (error) {
                console.error("Error fetching session:", error);
            } finally {
                setIsLoading(false);
            }
        };

        authenticateAndGetSubId();
    }, []);

    const updateBio = async () => {
        try {
            setIsLoading(true);
            const userAttributes = {
                "custom:bio": newBio,
            };
            await updateUserAttributes({ userAttributes });
            setBio(newBio);
            setNewBio("");
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating bio:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        subid,
        bio,
        newBio,
        isLoading,
        isEditing,
        imageUrl,
        setNewBio,
        setIsEditing,
        updateBio,
        trackedKeywords,
        setTrackedKeywords,
        occupation,
        setOccupation,
        newOccupation,
        setNewOccupation,
        lang,
        setLang,
    };
};
