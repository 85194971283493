import React, { useState, MouseEvent } from "react";
import { Link } from "react-router-dom";
import { Button, CircularProgress, Menu, MenuItem, ListItemIcon, ListItemText, Theme } from "@mui/material";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { SmallAvatar } from "../organisms/StyledComponents";
import { useNavigate } from "react-router-dom";

interface UserAuthMenuProps {
    isLoading: boolean;
    isLoggedIn: boolean;
    imageUrl: string | null;
    username: string | null;
    theme: Theme;
    onLogout: () => void;
}

const UserAuthMenu: React.FC<UserAuthMenuProps> = ({ isLoading, isLoggedIn, imageUrl, username, theme, onLogout }) => {
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLElement | null>(null);
    const userMenuOpen = Boolean(userMenuAnchorEl);
    const navigate = useNavigate();

    const handleUserMenuClick = (event: MouseEvent<HTMLDivElement>) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    const handleMyPageClick = () => {
        navigate("/mydashboard");
        handleUserMenuClose();
    };

    const handleMySettingsClick = () => {
        navigate("/my_settings");
        handleUserMenuClose();
    };

    const handleLogout = () => {
        onLogout();
        handleUserMenuClose();
    };

    if (isLoading) {
        return <CircularProgress size={24} sx={{ marginLeft: 2 }} />;
    }

    if (isLoggedIn) {
        return (
            <div>
                <div onClick={handleUserMenuClick} style={{ cursor: "pointer" }}>
                    <SmallAvatar theme={theme}>
                        {imageUrl ? (
                            <StorageImage alt="profile_image" path={imageUrl} />
                        ) : (
                            (username?.charAt(0) ?? "U").toUpperCase()
                        )}
                    </SmallAvatar>
                </div>
                <Menu
                    anchorEl={userMenuAnchorEl}
                    open={userMenuOpen}
                    onClose={handleUserMenuClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem onClick={handleMyPageClick}>
                        <ListItemIcon>
                            <DashboardIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>My Dashboard</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleMySettingsClick}>
                        <ListItemIcon>
                            <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Settings</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <ExitToAppIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Sign Out</ListItemText>
                    </MenuItem>
                </Menu>
            </div>
        );
    }

    return (
        <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/my_settings"
            sx={{
                backgroundColor: "#1976d2",
                color: "white",
                borderRadius: "20px",
                padding: "6px 16px",
                "&:hover": {
                    backgroundColor: "#1565c0",
                },
            }}
        >
            Sign In
        </Button>
    );
};

export default UserAuthMenu;
