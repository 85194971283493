import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Box } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/joy/Typography';

interface PaperCountEvolutionProps {
  evolution_of_document_count_data: any;
}

const DevicePaperCountEvolution: React.FC<PaperCountEvolutionProps> = ({ evolution_of_document_count_data }) => {
  const evolution_of_document_count_options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: true,
        ticks: {
          stepSize: 10
        }
      },
      x: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  return (
    <>
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Typography level="title-lg">Evolution of Paper Count</Typography>
      </Box>
      <CardContent style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        margin: 0,
        padding: 0,
      }}>
        <div style={{ height: '300px', width: '100%' }}>
          <Bar options={evolution_of_document_count_options} data={evolution_of_document_count_data} />
        </div>
      </CardContent>
    </>
  );
}

export default DevicePaperCountEvolution;