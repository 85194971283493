import React from "react";
import HeaderBar from "../organisms/HeaderBar";
import DeviceDetailsBOX from "../organisms/DeviceDetailsBOX";

const DeviceDetailsTemplate: React.FC = () => {
    return (
        <>
            <div>
                <HeaderBar />
            </div>
            <div>
                <DeviceDetailsBOX />
            </div>
        </>
    );
};

export default DeviceDetailsTemplate;
