import regularPaper from "../../dataclass/regularPaper";
import { CognitoUser } from "../../dataclass/CognitoUser";

// async function FetchCognitoInfo(): Promise<CognitoUser> {

async function FetchRegularPaperWithTrackedKeywords(CognitoUser: CognitoUser): Promise<regularPaper[]> {
    // CognitoからTracked keywordsを受け取り、それを元に論文検索をOR機能で検索する

    interface SearchResultItem {
        id: number;
        title: string;
        author: string;
        abstract: string | null;
        url: string;
        favicon: string;
        year: number;
        publisher: string;
        distance: number;
        order: number;
        cited: number;
    }
    const trackedKeywords: string[] = CognitoUser.trackedKeywords;

    const fetchResults = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/regular_paper_with_tracked_keywords`,
            // "http://localhost:3000/staging/regular_paper_with_tracked_keywords",
            {
                method: "POST",
                headers: {
                    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
                    "Content-Type": "application/json",
                    origin: window.location.origin,
                },
                body: JSON.stringify({ trackedKeywords }),
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        const data = JSON.parse(text);
        return data;
    };

    const response = await fetchResults().catch((e) => {
        console.error("Error:", e);
        return [];
    });

    const papers = response.papers;

    return papers.map((item: SearchResultItem) => {
        let replacedAuthor = item.author.replace(/(?<=\w)'(?=\w)/g, "\\'").replace(/'/g, '"');
        let parsedAuthor;
        try {
            parsedAuthor = JSON.parse(replacedAuthor);
        } catch (error) {
            try {
                // TODO: 正規表現
                replacedAuthor = replacedAuthor.replace(/De" Be/g, "De' Be");
                const jsonString = JSON.stringify(replacedAuthor);
                parsedAuthor = JSON.parse(jsonString);
            } catch (error) {
                // console.error("Failed to parse replacedAuthor:", error);
                parsedAuthor = JSON.parse(JSON.stringify(["unkown", "author"]));
            }
        }

        return new regularPaper(
            item.id,
            item.title ? String(item.title) : "",
            parsedAuthor,
            item.abstract ? String(item.abstract) : "",
            item.url ? String(item.url) : "",
            item.favicon ? String(item.favicon) : "",
            item.year ? item.year : 0,
            item.publisher ? String(item.publisher) : "",
            0.9,
            1,
            25
        );
    });
}

export default FetchRegularPaperWithTrackedKeywords;
