import AnalyzeResultTemplate from "../templates/AnalyzeResultTemplate";
import React from "react";

const AnalyzeResultPage: React.FC = () => {
    return (
        <>
            <AnalyzeResultTemplate />
        </>
    );
};

export default AnalyzeResultPage;
