import { useState, useCallback } from "react";
import { uploadData } from "aws-amplify/storage";
import { updateUserAttributes } from "@aws-amplify/auth";
import { Area, MediaSize } from "react-easy-crop";
import getCroppedImg from "../pages/getCroppedImg";
import { ASPECT_RATIO, CROP_WIDTH } from "../pages/constants";

export const useImageUpload = () => {
    const [file, setFile] = useState<File | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [zoom, setZoom] = useState(1);
    const [minZoom, setMinZoom] = useState(1);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

    const onFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            setFile(selectedFile);

            const reader = new FileReader();
            reader.addEventListener("load", () => {
                if (reader.result) {
                    setImgSrc(reader.result.toString() || "");
                    setIsOpen(true);
                }
            });
            reader.readAsDataURL(selectedFile);
        }
    }, []);

    const onMediaLoaded = useCallback((mediaSize: MediaSize) => {
        const { width, height } = mediaSize;
        const mediaAspectRatio = width / height;
        if (mediaAspectRatio > ASPECT_RATIO) {
            const result = CROP_WIDTH / ASPECT_RATIO / height;
            setZoom(result);
            setMinZoom(result);
        } else {
            const result = CROP_WIDTH / width;
            setZoom(result);
            setMinZoom(result);
        }
    }, []);

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        if (!croppedAreaPixels) return;
        try {
            const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels);
            if (file && croppedImage) {
                const filePath = `public/${file.name}`;
                const operation = uploadData({
                    path: filePath,
                    data: croppedImage,
                });

                const result = await operation.result;
                const fileUrl = result.path;

                const userAttributes = {
                    picture: fileUrl,
                };
                await updateUserAttributes({ userAttributes });
                console.log("File uploaded successfully:", result);
                window.location.reload();
            }
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, imgSrc, file]);

    return {
        file,
        isOpen,
        imgSrc,
        zoom,
        minZoom,
        crop,
        croppedAreaPixels,
        onFileChange,
        setIsOpen,
        setCrop,
        setZoom,
        onCropComplete,
        showCroppedImage,
        onMediaLoaded,
    };
};
