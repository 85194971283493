import React from "react";
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@mui/material";

interface DecadeSelectorProps {
    uniqueDecades: string[];
    selectedYears: string[];
    handleYearChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    sortedDecadeCounts: { [key: string]: number };
}

const DecadeSelector: React.FC<DecadeSelectorProps> = ({
    uniqueDecades,
    selectedYears,
    handleYearChange,
    sortedDecadeCounts,
}) => (
    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Selected Decades</FormLabel>
        <FormGroup>
            {uniqueDecades.map((decade) => (
                <FormControlLabel
                    key={decade}
                    control={
                        <Checkbox name={decade} checked={selectedYears.includes(decade)} onChange={handleYearChange} />
                    }
                    label={`${decade}~${Number(decade) + 9} (${sortedDecadeCounts[decade]})`}
                />
            ))}
        </FormGroup>
    </FormControl>
);

export default DecadeSelector;
