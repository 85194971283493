class LLMSummarizedPaperWithJournal {
    // LLM要約とジャーナルを結合したクラス
    id: number;
    name: string;
    desc: string;
    image: string;
    impact_factor: number;
    citescore: number;
    official_site: string;
    title: string;
    summary: string;
    target: string;
    hashtag: string;
    language: string;
    journal_monthly_report_id: number;
    paper_url: string;

    constructor(
        id: number,
        name: string,
        desc: string,
        image: string,
        impact_factor: number,
        citescore: number,
        official_site: string,
        title: string,
        summary: string,
        target: string,
        hashtag: string,
        language: string,
        journal_monthly_report_id: number,
        paper_url: string
    ) {
        // Initialize JournalClass properties
        this.id = id;
        this.name = name;
        this.desc = desc;
        this.image = image;
        this.impact_factor = impact_factor;
        this.citescore = citescore;
        this.official_site = official_site;

        // Initialize LLMSummarizedPaperClass properties
        this.title = title;
        this.summary = summary;
        this.target = target;
        this.hashtag = hashtag;
        this.language = language;
        this.journal_monthly_report_id = journal_monthly_report_id;
        this.paper_url = paper_url;
    }
}

export default LLMSummarizedPaperWithJournal;
