import DeviceClass from "../../dataclass/DeviceClass";

function MyDevice(): DeviceClass[] {
    let temp = [
        {
            id: 1,
            name: "JET",
            official_site: "https://ccfe.ukaea.uk/programmes/joint-european-torus/",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/JET-external.jpg",
            desc: "Operated by Culham Centre for Fusion Energy, the Joint European Torus (JET) is the focal point of the European fusion research programme.JET was designed to study fusion in conditions approaching those needed for a power plant. It is the only experiment that can operate with the deuterium-tritium fuel mix that will be used for commercial fusion power.Since it began operating in 1983, JET has made major advances in the science and engineering of fusion. Its success has led to the construction of the first commercial-scale fusion machine, ITER, and has increased confidence in the tokamak as a design for future fusion power plants.",
            location: "Oxfordshire, UK",
        },
        {
            id: 3,
            name: "ITER",
            official_site: "https://www.iter.org/",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/images.jpeg",
            desc: 'ITER (""The Way"" in Latin) is one of the most ambitious energy projects in the world today.',
            location: "Cadarash, France",
        },
        {
            id: 7,
            name: "Wendelstein 7-X",
            official_site: "https://www.ipp.mpg.de/w7x",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/250px-Wendelstein7-X_Torushall-2011.jpg",
            desc: "Wendelstein 7-X is the world’s largest fusion device of the stellarator type. Its objective is to investigate the suitability of this type for a power plant. It is testing an optimised magnetic field for confining the plasma. A system of 50 non-planar and superconducting magnet coils - the technical core piece of the device -  produces this field. It is expected that plasma equilibrium and confinement will be of a quality comparable to that of a tokamak of the same size.",
            location: "Greifswald, Germany",
        },
        {
            id: 4,
            name: "JT-60SA",
            official_site: "https://www.jt60sa.org/wp/",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/jt-60sa-most-advanced-tokamak-fusion-reactor.jpg",
            desc: "JT-60SA is a joint international fusion experiment being built and operated by Japan and Europe, in Naka, Japan, using infrastructure of the previous JT-60 Upgrade experiment alongside new hardware. SA stands for “super, advanced”, since the experiment will have superconducting coils and study advanced modes of plasma operation.",
            location: "Naka, Japan",
        },
        {
            id: 5,
            name: "ASDEX Upgrade",
            official_site: "https://www.ipp.mpg.de/16195/asdex",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/OIP.jpeg",
            desc: 'The aim of ASDEX Upgrade, the ""Axially Symmetric Divertor Experiment"", is to prepare the physics base for ITER and DEMO. For this purpose essential plasma properties, primarily plasma density, plasma pressure and the wall load, are matched to the conditions in a future fusion power plant.',
            location: "Garching, Germany",
        },
        {
            id: 2,
            name: "DIII-D",
            official_site: "https://www.ga.com/magnetic-fusion/diii-d",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/250px-2017_TOCAMAC_Fusion_Chamber_N0689.jpg",
            desc: "The DIII-D National Fusion Facility is an Office of Science scientific user facility, operated by General Atomics for the U.S. Department of Energy. It is pioneering the science and innovative technology that will enable the development of nuclear fusion as an energy source for the next generation.",
            location: "San Diego, USA",
        },
        {
            id: 6,
            name: "EAST",
            official_site: "http://east.ipp.ac.cn/",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/W020110706329530007377.jpg",
            desc: "ASIPP is one of the most important laboratories on magnetically confined fusion in China, has built HT-6B, HT-6M, HT-7, the first superconducting tokamak in China, and EAST, the world’s first non-circle cross-section full superconducting tokamak. On EAST, ASIPP has achieved many advanced and unique physical experiment results, and has won two National Science and Technology Progress Awards and other state honors.",
            location: "Hefei, China",
        },
        {
            id: 8,
            name: "LHD",
            official_site: "https://www-lhd.nifs.ac.jp/pub/index_e.html",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/LHD_VV_2.jpg",
            desc: "The Large Helical Device (LHD) is the world’s largest superconducting plasma confinement device, and employs a heliotron magnetic configuration. The objectives are to conduct academic research on confinement of steady-state/high-temperature plasma from the viewpoint of developing the helical-type fusion reactor.",
            location: "Toki, Japan",
        },
        {
            id: 9,
            name: "KSTAR",
            official_site: "https://www.kfe.re.kr/menu.es?mid=a20202050000",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/a10202040000_01.jpg",
            desc: "KSTAR project develops core technologies by operating KSTAR - the superconducting fusion research device built in 2007 - to run for 300 seconds at 100 million degrees and solves challenging problems in fusion physics with high-temperature plasma experiments",
            location: "Daejeon, South Korea",
        },
        {
            id: 10,
            name: "TEXTOR",
            official_site: "https://cer.ucsd.edu/research/fusion-energy/_pages/TEXTOR.html",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/TEXTOR_tokamak_narrow.jpg",
            desc: "For thirty years, the Tokamak Experiment for Technology Oriented Research (TEXTOR) was the site of advanced international fusion research. Its results have been integral to our current scientific understanding of how fusion works. An Institute of Plasma Physics facility that operated from 1963 to 2013, TEXTOR was the central test facility of Forschungszentrum Jülich’s program on Nuclear Fusion and Plasma Research.",
            location: "Jülich, Germany",
        },
        {
            id: 11,
            name: "TFTR",
            official_site: "https://www.pppl.gov/tokamak-fusion-test-reactor",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/TFTR_1989.jpeg",
            desc: "The Tokamak Fusion Test Reactor (TFTR) operated at the Princeton Plasma Physics Laboratory (PPPL) from 1982 to 1997. TFTR set a number of world records, including a plasma temperature of 510 million degrees centigrade -- the highest ever produced in a laboratory, and well beyond the 100 million degrees required for commercial fusion. In addition to meeting its physics objectives, TFTR achieved all of its hardware design goals, thus making substantial contributions in many areas of fusion technology development.",
            location: "Princeton, USA",
        },
        {
            id: 12,
            name: "TJ-II stellarator",
            official_site: "https://www.fusion.ciemat.es/home/tj-ii-2/",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/Nave3NBI-copia.jpg",
            desc: "The TJ-II project is the flagship project of the National Fusion Laboratory of Spain. TJ-II is a flexible, medium-size stellarator of the heliac type, the second largest operational stellarator in Europe (after W7-X).",
            location: "Madrid, Spain",
        },
        {
            id: 13,
            name: "Alcator C-Mod",
            official_site: "https://www.psfc.mit.edu/research/topics/alcator-c-mod-tokamak",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/alcator_c-mod_1000x450.jpg",
            desc: "Alcator C-Mod is an experimental device called a tokamak: a configuration considered for future fusion reactors. C-Mod is the world’s only compact, high-magnetic field, diverted tokamak, allowing it to access unique experimental regimes and influence the direction of the world fusion program. ",
            location: "Cambridge, USA",
        },
        {
            id: 14,
            name: "NSTX-U",
            official_site: "https://www.pppl.gov/research/projects/nstx-u",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/NSTXU_ext_web_copy_0.jpg",
            desc: "The National Spherical Torus Experiment-Upgrade (NSTX-U) is the primary fusion experiment at PPPL. The spherical device is shaped more like a cored apple than the doughnut-like shape of conventional tokamaks and can produce high-pressure plasmas — essential ingredients for fusion reactions — with relatively low and cost-effective magnetic fields.",
            location: "Princeton, USA",
        },
        {
            id: 15,
            name: "MAST",
            official_site: "https://ccfe.ukaea.uk/programmes/mast-upgrade/",
            image: "https://djangoimage2.s3.amazonaws.com/media/devices/MAST_plasma_image.jpg",
            desc: "The device is based on the original MAST (Mega Amp Spherical Tokamak) machine, which ran from 2000 to 2013. It has been rebuilt to enable higher performance – longer pulses, increased heating power and a stronger magnetic field – and an innovative new plasma exhaust system.",
            location: "Oxfordshire, UK",
        },
    ];

    return temp.map(
        (item) => new DeviceClass(item.id, item.name, item.desc, item.image, item.official_site, item.location)
    );
}

export default MyDevice;
