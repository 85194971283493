// LoadingScreen.tsx

import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/**
 * LoadingScreen コンポーネントは、アプリケーションがデータをロードしている間に表示される画面です。
 */
const LoadingScreen: React.FC = () => (
    <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        style={{ minHeight: "50vh" }}
    >
        <Grid item>
            <CircularProgress />
        </Grid>
        <Grid item>
            <Typography variant="h6" color="text.secondary">
                The initial loading may take some time.
            </Typography>
        </Grid>
    </Grid>
);

export default LoadingScreen;
