import MyPageTemplate from "../templates/MyDashboradTemplate";
import React from "react";
import { MainPageProps } from "../organisms/types";
import withCustomAuthenticator from "./withCustomAuthenticator";

const MyDashborad: React.FC<MainPageProps> = ({ signOut, user }) => {
    return (
        <>
            <MyPageTemplate user={user} signOut={signOut} />
        </>
    );
};

export default withCustomAuthenticator(MyDashborad);
