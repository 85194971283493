import FetchLLMSummarizedPaperData from "../gateways/FetchLLMSummarizedPaperData";
import LLMSummarizedPaperClass from "../../dataclass/LLMSummarizedPaperClass";

class LLMSummarizedPaperManeger {
    async getLLMSummarizedPaperInfo(journalId: Number): Promise<LLMSummarizedPaperClass[]> {
        // 装置詳細画面に表示する情報を返します
        const LLM_summarized_papers = await FetchLLMSummarizedPaperData(journalId);
        return LLM_summarized_papers;
    }
}

export default LLMSummarizedPaperManeger;
