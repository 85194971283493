import React from "react";

interface DeviceKeywordsProps {
    DeviceData: any;
}

const DeviceKeywords: React.FC<DeviceKeywordsProps> = ({ DeviceData }) => {
    return (
        <>
            <h3 style={{ fontWeight: "bold", marginTop: "30px" }}>Common Keywords</h3>
            {DeviceData &&
                DeviceData.common_topics.slice(0, 10).map((topic: string, index: number) => (
                    <span
                        key={index}
                        style={{
                            padding: "5px",
                            margin: "1px",
                            display: "inline-block",
                        }}
                    >
                        #{topic}
                    </span>
                ))}
            <h3 style={{ fontWeight: "bold", marginTop: "9px" }}>Recent Keywords</h3>
            {DeviceData &&
                DeviceData.recent_topics.slice(0, 10).map((topic: string, index: number) => (
                    <span
                        key={index}
                        style={{
                            padding: "5px",
                            margin: "1px",
                            display: "inline-block",
                        }}
                    >
                        #{topic}
                    </span>
                ))}
        </>
    );
};

export default DeviceKeywords;
