import JournalManeger from "../usecase/JournalManeger";
import JournalClass from "../../dataclass/JournalClass";

interface JournalDetailsBOXPresenterResult {
    journal: JournalClass[];
    journal_data: any;
    evolution_of_document_count_data: any;
}

/**
 * `JournalDetailsBOXPresenter`は、指定されたファイル名に対応するデバイスの詳細情報を取得します。
 *
 * @param fileName - デバイスデータを取得するためのファイル名
 * @returns Promise<JournalDetailsBOXPresenterResult> - デバイスとその詳細情報、および文書数の進化データを含むオブジェクトを返します
 *
 * @example
 *
 * const result = await JournalDetailsBOXPresenter('journal1.json');
 * console.log(result.journal); // デバイス情報を表示
 * console.log(result.journal_data); // デバイスの詳細情報を表示
 * console.log(result.evolution_of_document_count_data); // 文書数の進化データを表示
 */
async function JournalDetailsBOXPresenter(fileName: string): Promise<JournalDetailsBOXPresenterResult> {
    const journalManager = new JournalManeger();
    const JournalDetailsInfo = await journalManager.getJournalDetailsInfo(fileName);

    return {
        journal: journalManager.getJournal(),
        journal_data: JournalDetailsInfo.journal_data,
        evolution_of_document_count_data: JournalDetailsInfo.evolution_of_document_count_data,
    };
}

export default JournalDetailsBOXPresenter;
