import React from 'react';

interface JournalKeywordsProps {
  JournalData: any;
}

const JournalKeywords: React.FC<JournalKeywordsProps> = ({ JournalData }) => {
  return (
    <>
      <h3 style={{ fontWeight: 'bold', marginTop: '30px' }}>Common Keywords</h3>
      { JournalData && JournalData.common_topics.slice(0, 10).map((topic: string, index: number) => (
          <span key={index} style={{ padding: '5px', margin: '1px', display: 'inline-block'}}>
            #{topic}
          </span>
        ))
      }
      <h3 style={{ fontWeight: 'bold', marginTop: '9px' }}>Recent Keywords</h3>
      { JournalData && JournalData.recent_topics.slice(0, 10).map((topic: string, index: number) => (
          <span key={index} style={{padding: '5px', margin: '1px', display: 'inline-block'}}>
            #{topic}
          </span>
        ))
      }
    </>
  );
}

export default JournalKeywords;