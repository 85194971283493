import React from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AlertCircle, Loader } from "lucide-react";
import LLMSummarizedPaperPresenter from "../../backend/presenters/LLMSummarizedPaperPresenter";
import MonthlyJournalReportPresenter from "../../backend/presenters/MonthlyJournalReportPresenter";
import JournalPresenter from "../../backend/presenters/JournalPresenter";
import PaperJournalTables from "../molecules/PaperJournalTables";
import LLMSummarizedPaperClass from "../../dataclass/LLMSummarizedPaperClass";
import MonthlyJournalReportClass from "../../dataclass/MonthlyJournalReportClass";
import TargetJournalCard3 from "../molecules/TargetJournalCard3";

const LLMSummarizedPaperBOX: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const journals = JournalPresenter();
    const journalId = parseInt(id || "0", 10);
    const Journal = journals.find((journal) => journal.id === journalId);

    const [LLMSummarizedPapers, setLLMSummarizedPapers] = useState<LLMSummarizedPaperClass[]>([]);
    const [monthlyJournalReports, setMonthlyJournalReports] = useState<MonthlyJournalReportClass[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        Promise.all([LLMSummarizedPaperPresenter(journalId), MonthlyJournalReportPresenter(journalId)])
            .then(([papers, reports]) => {
                setLLMSummarizedPapers(papers);
                setMonthlyJournalReports(reports);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    }, [journalId]);

    if (!Journal) {
        return <div>Journal not found</div>;
    }

    return (
        <Container maxWidth="lg">
            {isLoading ? (
                <Box className="flex justify-center items-center h-64">
                    <Loader className="animate-spin text-blue-500" size={48} />
                </Box>
            ) : LLMSummarizedPapers.length > 0 && monthlyJournalReports.length > 0 ? (
                <Box>
                    <PaperJournalTables
                        papers={LLMSummarizedPapers}
                        journals={monthlyJournalReports}
                        journal={Journal}
                    />
                </Box>
            ) : (
                <Box className="flex justify-center items-center h-64">
                    <Paper elevation={3} className="p-8 text-center">
                        <AlertCircle className="mx-auto mb-4 text-yellow-500" size={48} />
                        <Typography variant="h5" className="mb-2 font-bold text-gray-700">
                            No Data Available
                        </Typography>
                        <Typography variant="body1" className="text-gray-500">
                            There is currently no data available to display.
                        </Typography>
                    </Paper>
                </Box>
            )}
        </Container>
    );
};

export default LLMSummarizedPaperBOX;
