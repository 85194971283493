import dashboardManger from "../usecase/dashboardManger";
import regularPaper from "../../dataclass/regularPaper";

/**
 * ダッシュボードのお気に入り論文を表示するためのプレゼンター関数。
 * @returns {Promise<TechnicalTermClass[]>} 検索結果のTechnicalTermClassオブジェクトの配列を含むPromise。
 */
function dashBoardUserPapersPresenter(paper_type: string): Promise<regularPaper[]> {
    const manager = new dashboardManger();
    if (paper_type === "favorite") {
        return manager.getFavoritePapers();
    } else if (paper_type === "bookmark") {
        return manager.getBookmarkPapers();
    } else if (paper_type === "checked") {
        return manager.getCheckedPapers();
    } else {
        return Promise.resolve([]);
    }
}

export default dashBoardUserPapersPresenter;
