import React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// sortOrderとsetSortOrderは親コンポーネントからpropsとして渡すべきです
interface SortSelectProps {
    sortOrder: "popularity" | "asc" | "desc" | "default";
    setSortOrder: (value: "popularity" | "asc" | "desc" | "default") => void;
}

const SortSelect: React.FC<SortSelectProps> = ({ sortOrder, setSortOrder }) => (
    <FormControl fullWidth style={{ margin: "20px 0", maxWidth: "60%", marginLeft: "22px" }}>
        <FormLabel component="legend">Sort</FormLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value as "popularity" | "asc" | "desc" | "default")}
        >
            <MenuItem value="default">Default</MenuItem>
            <MenuItem value="desc">Newest</MenuItem>
            <MenuItem value="asc">Oldest</MenuItem>
            <MenuItem value="popularity">Popularity (beta)</MenuItem>
        </Select>
    </FormControl>
);

export default SortSelect;
